import { GUser as User} from "@gear/business";
import { useGStores } from "@gear/business";
import { useGetRolesRouter } from "@/hooks";
import { debounce } from "@/utils";
import { observer } from "mobx-react";
import { FC, Fragment, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import ReactGA from "react-ga";
import storage from "storejs";

export const RouterContext: FC = observer((props) => {
  const history = useHistory();
  const location = useLocation();
  const { userStore, configStore } = useGStores();

  /**
   * 路由发生改变
   */
  const routerChange = debounce(() => {
    // 非模拟登录才有统计
    if (!storage.get("simulated_token")) {
      const user: User = userStore?.user;
      if (user) {
        // google 只能设置 userId
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_G_ID!, {
          gaOptions: {
            userId: String(user.id)
          }
        });
      }
    }
  }, 300);

  useEffect(() => {
    history.listen((router: any) => {
      routerChange();

      const pathname = window.location.pathname;
      configStore.updatePathname(pathname);

      // @ts-ignore
      window._mfq?.push(["newPageView", `${pathname}${window.location.search}`]);

      // @ts-ignore
      window.Intercom("update");
    });
  }, []);

  /** 当用户信息和团队信息更新时，判断当前页面有没有权限 */
  const { routerLink } = useGetRolesRouter();
  useEffect(() => {
    const isEnterprise = process.env.REACT_APP_APP_ID === "venus_enterprise";
    if (routerLink && isEnterprise) history.push(routerLink);
  }, [routerLink]);

  return <Fragment>{props.children}</Fragment>;
});
