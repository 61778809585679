import { UseStores } from "@/stores";
import { getLanguage } from "@/utils";
import { AccountAPI, useGStores, useIsAutoTranslation } from "@gear/business";
import { GDrawer, GDrawerProps, GRadio, GRadioGroup, GSwitch, switchWithLanguage, useGTranslation } from "@gear/ui";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import "./index.scss";

export const LanguageSwitchDrawer: FC<GDrawerProps> = observer(({ visible, onClose, ...props }) => {
  const { translate } = useGTranslation();
  const { userStore, teamGanttStore } = useGStores<UseStores>();

  const oldLanguage = userStore.user?.user_info?.language || getLanguage("abridge");

  const { isAutoTranslation } = useIsAutoTranslation();
  const [language, setLanguage] = useState(oldLanguage);
  const [autoTranslation, setAutoTranslation] = useState<boolean>(isAutoTranslation);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (userStore.user?.user_info && userStore.user?.user_info?.auto_translation !== undefined) {
      setAutoTranslation(userStore.user?.user_info?.auto_translation);
    }
  }, [userStore.user?.user_info]);

  const save = () => {
    let promise;
    let is_login = localStorage.getItem("token");
    if (is_login) {
      if (userStore.user?.user_info?.id) {
        // edit
        promise = AccountAPI.patchUserInfo(userStore.user?.user_info?.id, { language, auto_translation: autoTranslation });
      } else {
        // create
        promise = AccountAPI.postUserInfo({ language, auto_translation: autoTranslation });
      }
      setLoading(true);
      promise
        .then((response) => {
          teamGanttStore.setIsAutoTranslation(autoTranslation);
          localStorage.setItem("language", language);
          localStorage.setItem("auto_translation", `${autoTranslation}`);
          // 语言环境更新了，需要重新加载页面
          window.location.reload();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      teamGanttStore.setIsAutoTranslation(autoTranslation);
      localStorage.setItem("language", language);
      localStorage.setItem("auto_translation", `${autoTranslation}`);
      // 语言环境更新了，需要重新加载页面
      window.location.reload();
    }
  };

  return (
    <GDrawer
      className="language-switch-drawer"
      visible={visible}
      useNewStyle
      width={556}
      title={translate("Language")}
      destroyOnClose={true}
      onClose={(e) => {
        if (language !== oldLanguage || autoTranslation !== isAutoTranslation) {
          // 有改动
          save();
        }
        onClose?.(e);
      }}
      {...props}
    >
      <div>
        <p className="language-switch_setting-title">{translate("Set the system language into")}</p>
        <GRadioGroup
          value={language}
          onChange={(e) => {
            setLanguage(e.target.value);
          }}
        >
          <GRadio value="en">English</GRadio>
          <GRadio value="zh">简体中文</GRadio>
          <GRadio value="zh-tw">繁體中文</GRadio>
          <GRadio value="es">Español</GRadio>
        </GRadioGroup>
        <div className="language-switch_auto-tranlate-container">
          <div className="row-space-between-ver-center">
            <p className="language-switch_auto-tranlate-title">{translate("Auto translate")}</p>
            <GSwitch
              checked={autoTranslation}
              onChange={(checked) => {
                setAutoTranslation(checked);
              }}
            />
          </div>
          <p className="language-switch_auto-tranlate-tips">
            {/*{autoTranslationTips}*/}
            {language === "en" && "Translate user content (eg: daily check-ins, tasks, etc.) into "}
            {language === "es" && "Traducir el contenido generado por el usuario (como informes diarios, tareas, etc.) al "}
            {language === "zh" && "将用户产出的内容（如日报，任务等）翻译成"}
            {language === "zh-tw" && "將用戶產出的內容（如日報，任務等）翻譯成"}
            <span style={{ fontWeight: 700 }}>
              {language === "en" && "English"}
              {language === "es" && "Español"}
              {language === "zh" && "简体中文"}
              {language === "zh-tw" && "繁體中文"}
            </span>
          </p>
        </div>
      </div>
    </GDrawer>
  );
});
