import { MenuMode, PlanTypeStyle } from "@/apis/types";
import { useGetRolesRouter } from "@/hooks";
import { getSSOHost } from "@/utils";
import { GHeader, GHeaderProps, useGStores } from "@gear/business";
import { observer } from "mobx-react";
import { FC, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { svgs } from "./assets/svgs";
import { ReactComponent as IconProductMetawork } from "./assets/iocn_product_metawork.svg";
import "./index.scss";
import { UseStores } from "@/stores";
import { translate } from "@gear/ui";
import { useProduct } from "@/hooks/useProduct";

interface HeaderProps extends Omit<GHeaderProps, "ssoHost"> {}

export const Header: FC<HeaderProps> = observer(({ customRight, ...props }) => {
  const history = useHistory();
  const { getFirstLink } = useGetRolesRouter();
  const { configStore, teamStore, enterpriseStore } = useGStores<UseStores>();
  const { openedMetawork, isFreeTrialType } = useProduct();

  useEffect(() => {
    switch (configStore.menuMode) {
      case MenuMode.unexpand:
        document.body.classList.remove("menu-expand");
        document.body.classList.add("menu-unexpand");
        break;
      default:
        document.body.classList.remove("menu-unexpand");
        document.body.classList.add("menu-expand");
        break;
    }
  }, [configStore.menuMode]);

  const showMenuMode = useMemo(() => {
    return !teamStore.showCreateProject;
  }, [teamStore.showCreateProject]);

  const menuModeChangeView = () => {
    return (
      <span
        id="icon-menu-mode-change"
        className="icon-menu-mode-change"
        onClick={() => {
          configStore.changeMenuMode();
        }}
      >
        {svgs.iconMenuMode}
      </span>
    );
  };

  const subscriptionStyle = useMemo(() => {
    if (!openedMetawork && isFreeTrialType) {
      return PlanTypeStyle["Expired"];
    } else {
      return PlanTypeStyle[enterpriseStore?.enterprise?.subscription_type!];
    }
  }, [enterpriseStore?.enterprise?.subscription_type, openedMetawork, isFreeTrialType]);
  return (
    <GHeader
      isHideSileMenu={false}
      ssoHost={getSSOHost() || ""}
      onClickLogo={() => {
        history.push(getFirstLink() || "/");
      }}
      customRight={() => <>{customRight?.()}</>}
      customLogo={
        <div
          className="custom-logo-box"
          onClick={() => {
            props.onClickLogo?.();
          }}
        >
          <IconProductMetawork style={{ marginLeft: "23px" }} />
          <span
            className={"plan-type-tag"}
            style={{
              color: subscriptionStyle?.color,
              backgroundColor: subscriptionStyle?.bgColor
            }}
          >
            {translate(subscriptionStyle?.label!)}
          </span>
        </div>
      }
      {...props}
    />
  );
});
