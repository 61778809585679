import moment, { MomentInput } from "moment-timezone";
import { dateUIFormatEn, dateUIFormat, momentTypeHM, momentTypeHMEn } from ".";
import { LanguageAbridgeType, UTCString, switchWithLanguage, translate } from "@gear/ui";

// 时间的对象interface
export interface TimeInstance {
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
  second: string;
  week: string;
  weekNum: number;
  isWeekend: boolean;
  yearMonth: string;
  yearMonthDay: string;
  monthDay: string;
  yearMonthDayHour: string;
  HMS: string;
  stamp: number;
  stampMill: number;
  format: string;
  formatHMS: string;
  YM: string;
  YMS: string;
  default: Date;
}
/**
 * 对小于10的数字进行补零
 * @param num 需要补零的数
 * @return Object
 */
export const makeUpZero = (num: number | string): string => {
  const result = num < 10 ? "0" + num : "" + num + "";
  return result;
};

/**
 * 获取当前时间 或 指定时间的结果 的时分秒
 * @param date '需要转换的时间' 默认为当前时间
 * @param interval 间隔符号类型 '- | /' 日期间拼接符号类型
 * @return Object
 */
export const timeObject = (date: number | string | Date = new Date(), interval: "-" | "/" = "-"): TimeInstance => {
  const time = new Date(date);
  const year = "" + time.getFullYear() + "";
  const month = makeUpZero(time.getMonth() + 1);
  const day = makeUpZero(time.getDate());
  const hour = makeUpZero(time.getHours());
  const minute = makeUpZero(time.getMinutes());
  const second = makeUpZero(time.getSeconds());
  const weekArray = ["MID_SUN", "MID_MON", "MID_TUE", "MID_WED", "MID_THU", "MID_FRI", "MID_SAT"];
  const weekNum = time.getDay();
  const week = weekArray[weekNum];
  const yearMonth = year + interval + month;
  const monthDay = month + interval + day;
  const yearMonthDay = yearMonth + interval + day;
  const yearMonthDayHour = yearMonthDay + " " + hour + ":" + minute + ":" + second;
  const HMS = hour + ":" + minute + ":" + second;
  const stampMill = time.getTime();
  const stamp = Math.floor(stampMill / 1000);
  const result: TimeInstance = {
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    second: second,
    week: week,
    weekNum: weekNum,
    isWeekend: weekNum === 0 || weekNum === 6,
    yearMonth: yearMonth,
    monthDay: monthDay,
    yearMonthDay: yearMonthDay,
    yearMonthDayHour: yearMonthDayHour,
    HMS: HMS,
    stamp: stamp,
    stampMill: stampMill,
    format: yearMonthDay,
    formatHMS: yearMonthDayHour,
    YM: yearMonth,
    YMS: yearMonth + interval + "01",
    default: time
  };

  return result;
};

export type timeType = "stamp" | "stampMill" | "format" | "default" | "formatHMS" | "HMS" | "year" | "month" | "day" | "YM" | "YMS";

/**
 * 获取当前时间 或 指定时间的结果
 * @param type 类型 '时间戳(秒) | 时间戳(毫秒) | 格式化时间(如2020-01-01)不带分秒时 | 默认时间 | 格式化时间带小时 | 仅仅分秒时 | 年 | 月 | 日 | 年月 | 年月日（当月第一天）
 * @param date '需要转换的时间' 默认为当前时间
 * @param interval 间隔符号类型 '- | /' 当类型为格式化时间时 拼接符号类型
 * @return result
 */
export function formatTime(): string;
export function formatTime<T extends keyof TimeInstance>(type: T, date?: number | string | Date): TimeInstance[T];
export function formatTime<T extends keyof TimeInstance>(type: T, date?: number | string | Date, interval?: "-" | "/"): TimeInstance[T];
export function formatTime<T extends keyof TimeInstance>(
  type?: T,
  date: number | string | Date = new Date(),
  interval: "-" | "/" = "-"
): TimeInstance[T] | string {
  const time = new Date(date);
  const timeResult = timeObject(time, interval);
  return timeResult[type || "format"];
}

/**
 * 生成任意一天到指定一天
 * @param endDate 结束的时间
 * @param space 距离开始时间的间隔，默认为0天
 * @param type 时间格式
 * @param HMS 分秒时，默认为['00:00:00', '23:59:59']
 * @return result
 */
export function everyDays(): Array<string>;
export function everyDays(endDate: number | string | Date): Array<string>;
export function everyDays(endDate: number | string | Date, space: number): Array<string>;
export function everyDays<T extends keyof TimeInstance>(endDate: number | string | Date, space: number, type: T, HMS?: Array<string>): Array<TimeInstance[T]>;
export function everyDays<T extends keyof TimeInstance>(
  endDate: number | string | Date = new Date(),
  space = 0,
  type?: T,
  HMS = ["00:00:00", "23:59:59"]
): Array<TimeInstance[T] | string> {
  const endTime = formatTime("format", endDate) + " " + HMS[1];
  const time = formatTime("stampMill", endTime);
  const reduce = 24 * 60 * 60 * 1000 * space;
  const starTimeNumber = time - reduce;
  const starTime = formatTime("format", starTimeNumber) + " " + HMS[0];
  return type ? [formatTime(type, starTime), formatTime(type, endTime)] : [formatTime("format", starTime), formatTime("format", endTime)];
}

/**
 * 生成指定一天
 * @param type 时间格式
 * @param space 距离开始时间的间隔，默认为1天，即生成昨天
 */
export function everyDay(): string;
export function everyDay(space: number): string;
export function everyDay<T extends keyof TimeInstance>(space: number, type: T): TimeInstance[T];
export function everyDay<T extends keyof TimeInstance>(space = 1, type?: T): TimeInstance[T] | string {
  const timeNumber = formatTime("stampMill");
  const reduce = 24 * 60 * 60 * 1000 * space;
  return formatTime(type || "format", timeNumber - reduce);
}

/**
 * 生成任意一天到指定一天返回所有的数据Array
 * 与everyDays的区别为everyDays只返回起始，而该方法返回所有
 * @param endDate 结束的时间
 * @param space 距离开始时间的间隔，默认为0天
 * @param type 时间格式
 * @param HMS 分秒时，默认为['00:00:00', '23:59:59']
 * @return result
 */
export function everyDayToDays(): Array<string>;
export function everyDayToDays(endDate: number | string | Date): Array<string>;
export function everyDayToDays(endDate: number | string | Date, space: number): Array<string>;
export function everyDayToDays<T extends keyof TimeInstance>(
  endDate: number | string | Date,
  space: number,
  type: T,
  interval?: "-" | "/",
  HMS?: Array<string>
): Array<TimeInstance[T]>;
export function everyDayToDays<T extends keyof TimeInstance>(
  endDate: number | string | Date = new Date(),
  space = 0,
  type?: T,
  interval: "-" | "/" = "/",
  HMS = ["00:00:00", "23:59:59"]
): Array<TimeInstance[T] | string> {
  const endTime = formatTime("format", endDate, interval) + " " + HMS[1];
  const time = formatTime("stampMill", endTime);
  let startSpace = space;
  const result = [];
  while (startSpace > 0) {
    const reduce = 24 * 60 * 60 * 1000 * (space - startSpace);
    const starTimeNumber = time - reduce;
    const starTime = formatTime("format", starTimeNumber, interval) + " " + HMS[0];
    result.unshift(formatTime(type || "format", starTime));
    startSpace--;
  }

  return result;
}

/**
 * 生成需要计算的月份 或一个开始到结束的数组
 * @param endDate 结束的月份
 * @param space 距离开始时间的间隔，默认为0月
 * @param type 时间格式
 * @param mouths 是否返回数组
 * @return result
 */
export function everyMonths(): Array<string>;
export function everyMonths(space: number): Array<string>;
export function everyMonths(space: number, endDate: number | string | Date): Array<string>;
export function everyMonths<T extends keyof TimeInstance>(space: number, endDate: number | string | Date, type: T): Array<TimeInstance[T]>;
export function everyMonths<T extends keyof TimeInstance>(space: number, endDate: number | string | Date, type: T, mouths: true): Array<TimeInstance[T]>;
export function everyMonths<T extends keyof TimeInstance>(space: number, endDate: number | string | Date, type: T, mouths: false): TimeInstance[T];
export function everyMonths<T extends keyof TimeInstance>(
  space = 0,
  endDate: number | string | Date = new Date(),
  type?: T,
  mouths = true
): Array<TimeInstance[T] | string> | TimeInstance[T] | string {
  const { year, month } = timeObject(endDate);
  const monthDValue = Number(month) - (space % 12);
  const yearStart = monthDValue > 0 ? Number(year) - Math.floor(space / 12) : Number(year) - Math.floor(space / 12) - 1;
  const mouthStart = monthDValue > 0 ? monthDValue : Number(month) + (12 - (space % 12));
  const startTime = type ? formatTime(type, yearStart + "-" + mouthStart) : formatTime("format", yearStart + "-" + mouthStart);
  const endTime = type ? formatTime(type, endDate) : formatTime("format", endDate);
  return mouths ? [startTime, endTime] : startTime;
}

/**
 * 是否超过设定的时间区间
 * @param dates 需要计算的时间数组
 * @param type | month | year | day
 * @param space 间隔 number
 * @return boolean
 */
export const isInSpace = (dateArray: Array<string | number | Date>, space: number, type: "month" | "year" | "day" = "day"): boolean => {
  const multiple = type === "day" ? 1 : type === "month" ? 30 : 366;
  return formatTime("stampMill", dateArray[1]) - formatTime("stampMill", dateArray[0]) < space * 24 * 60 * 60 * 1000 * multiple;
};

/**
 * 时区的返回类型
 */
interface UserTimeZone {
  offsetMinute: number;
  offset: number;
  UTC: UTCString;
  GMT: string;
}

/**
 * 返回当前的时区
 * @param type num | UTC string
 * @param date Date 需要处理的事件
 * @returns number | string
 */
export function utilsTimeZone(): UTCString;
export function utilsTimeZone<T extends keyof UserTimeZone>(type: T, date?: Date | string | number): UserTimeZone[T];
export function utilsTimeZone<T extends keyof UserTimeZone>(type?: T, date: Date | string | number = new Date()): UserTimeZone[T] | string {
  const userDate = new Date(date);
  const offset = userDate.getTimezoneOffset();
  const hour = (offset / 60) * -1;
  const offsetHour = hour >= 0 ? "+" + makeUpZero(hour) : "-" + makeUpZero(hour * -1);
  const result: UserTimeZone = {
    offsetMinute: offset,
    offset,
    UTC: ("UTC" + offsetHour + ":00") as UTCString,
    GMT: "GMT" + offsetHour + "00"
  };

  return result[type || "UTC"];
}
/**
 * 使用moment格式化中英文语言环境中时间展示
 * @param date 需要格式化的时间
 * @param zh 中文的格式，需要可以被moment的format解析
 * @param en 英文的格式，需要可以被moment的format解析
 * @param language language 指定的语言， 默认为自动获取
 * @requires string
 */
export const formatDateWithLanguage = (date: MomentInput, format: string = "default") => {
  if (format === "default") {
    format = switchWithLanguage({ en: "ll", zh: "YYYY/MM/DD" });
  }
  return date ? moment(date).format(format) : translate("Unknown");
};

/**
 * 使用moment格式化中英文语言环境中时间展示
 * @param time 需要格式化的时间
 * @param zh 中文的格式，需要可以被moment的format解析
 * @param en 英文的格式，需要可以被moment的format解析
 * @param language language 指定的语言， 默认为自动获取
 * @requires string
 */
export const formatTimeWithLanguage = (time: MomentInput) => {
  return time ? moment(time).format(switchWithLanguage({ en: "LL, HH:mm", zh: "YYYY/MM/DD HH:mm" })) : translate("Unknown");
};

/**
 * 返回用户的时区, moment.tz返回
 */
export const getTimeZone = () => moment.tz.guess();

/**
 * 根据时区转换时间
 * @param date 需要转换的时间，可以传数组
 * @param timeZone 需要转换的时区，默认为当前浏览器时区
 * @param format 返回的时间类型 默认为中文为HH:mm， 英文为H:mm
 * @param defaultString 当时间格式错误或不存在时的返回占位信息
 * @returns T extends date
 */
export function transformWithTimezone(): string;
export function transformWithTimezone(date: MomentInput, timeZone?: string, format?: string, defaultString?: string): string;
export function transformWithTimezone(date: MomentInput[], timeZone?: string, format?: string, defaultString?: string): string[];
export function transformWithTimezone(date?: MomentInput | MomentInput[], timeZone?: string, format?: string, defaultString?: string): string | string[] {
  const timeZoneType = timeZone || getTimeZone();
  const def = defaultString || (translate("Unknown") as string);
  const type = format || translate(momentTypeHM, momentTypeHMEn);
  const resultArray = Array.isArray(date) && date.map((item) => (item ? moment.tz(item, timeZoneType).format(type) : def));
  return resultArray ? resultArray : date ? moment.tz(date, timeZoneType).format(type) : def;
}

export function formatMilliseconds(milliseconds: number) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds %= 60;
  minutes %= 60;

  let result = "";
  if (hours > 0) {
    result += hours + "h ";
  }
  if (minutes > 0) {
    result += minutes + "m ";
  }
  if (seconds > 0 || result === "") {
    result += seconds + "s";
  }

  return result.trim();
}

export function formatMillisecondsWithDelimiter(milliseconds: number) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedTime = `${hours.toString()}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  return formattedTime;
}

/**
 * 将毫秒数转化为小时数
 * @param milliseconds
 * @param fractionDigits 保留小数点后几位，默认 1
 * @returns
 */
export function millisecondsToHours(milliseconds: number, fractionDigits = 1) {
  return Number(moment.duration(milliseconds).asHours().toFixed(fractionDigits));
}

/**
 * 将毫秒数转化为分钟数
 * @param milliseconds
 * @param fractionDigits 保留小数点后几位，默认 1
 * @returns
 */
export function millisecondsToMinutes(milliseconds: number, fractionDigits = 1) {
  return Number(moment.duration(milliseconds).asMinutes().toFixed(fractionDigits));
}
