import { makeAutoObservable } from "mobx";

class AppDownloadStore {
  appDownloadGuideModalVisible = false;
  userFinishAppDownloadGuideCount = Number(localStorage.getItem("user-finish-app-download-guide-count") || 0); // 记录用户主动退出下载app引导的次数
  userFinishAppDownloadGuideDate = localStorage.getItem("user-finish-app-download-guide-date"); // 记录用户主动退出下载app引导的日期

  constructor() {
    makeAutoObservable(this);
  }

  updateAppDownloadGuideModalVisible = (visible: boolean) => {
    this.appDownloadGuideModalVisible = visible;
  };

  updateUserFinishAppDownloadGuideCount = (count: number) => {
    this.userFinishAppDownloadGuideCount = count;
    localStorage.setItem("user-finish-app-download-guide-count", count + "");
  };

  updateUserFinishAppDownloadGuideCountDate = (date: string) => {
    this.userFinishAppDownloadGuideDate = date;
    localStorage.setItem("user-finish-app-download-guide-date", this.userFinishAppDownloadGuideDate);
  };
}

export default AppDownloadStore;
