
import { axiosWrapper } from "@/utils";
import { MenuMode } from "@/apis/types";
import { makeAutoObservable } from "mobx";
import { SettingAPI } from "@gear/business";

const defaultGlobalQuickEntry = {
  DAILY_REPORT: false,
  WRITE_DAILY_REPORT: true,
  MY_TASKS: false,
  ADD_TASK: true,
  ORGANIZATION_WEEKLY_REPORT: false
};
class ConfigStore {
  menuMode: MenuMode = MenuMode.expand; // 记录菜单栏模式
  // TODO 下个版本要用
  timeZoneDialogVisible = false; // 时区选择弹窗
  // timeZoneDialogVisible = true; // 时区选择弹窗
  needUpdateTaskDataFlag = false; // 是否需要更新task数据
  globalQuickEntry: { [key: string]: boolean } = defaultGlobalQuickEntry;
  pathname = window.location.pathname;
  appDownloaded = true;

  constructor() {
    makeAutoObservable(this);
    this.updateMenuMode(MenuMode.expand);
  }

  updateNeedUpdateTaskDataFlag(value: boolean) {
    this.needUpdateTaskDataFlag = value;
  }

  updateMenuMode(mode: MenuMode) {
    this.menuMode = mode;
  }

  changeMenuMode() {
    this.updateMenuMode(this.menuMode === MenuMode.unexpand ? MenuMode.expand : MenuMode.unexpand);
  }

  updateTimeZoneDialogVisible(visible: boolean) {
    this.timeZoneDialogVisible = visible;
  }
  updatePersonalQuickOperationInfo(userId: number) {
    SettingAPI.getPersonalQuickOperationInfo(userId).then((res) => {
      this.globalQuickEntry = res?.data?.value || defaultGlobalQuickEntry;
    });
  }
  patchPersonalQuickOperationInfo(userId: number, value: { [key: string]: any }) {
    return SettingAPI.patchPersonalQuickOperationInfo(userId, { value }).then((res) => {
      this.globalQuickEntry = value;
    });
  }
  updatePathname(pathname: string) {
    this.pathname = pathname;
  }
  updateAppDownloaded(appDownloaded: boolean) {
    this.appDownloaded = appDownloaded;
  }
}

export default ConfigStore;
