import { GModal, GModalProps, useGTranslation } from "@gear/ui";
import { Task } from "@/apis/types";
import { FC } from "react";
import "./index.scss";
import { useGStores } from "@gear/business";
import { UseStores } from "@/stores";

export interface SelectSubscritionModalProps extends Omit<GModalProps, "onOk"> {
  visible: boolean;
  onClose: () => void;
  onDisplayCompareFeaturesModal: () => void;
}

export const SelectSubscritionModel: FC<SelectSubscritionModalProps> = (props: SelectSubscritionModalProps) => {
  const { translate } = useGTranslation();
  const { userStore, enterpriseStore } = useGStores<UseStores>();

  const clientReferenceId = () => {
    const userId = userStore?.user?.id;
    const enterpriseId = enterpriseStore?.enterprise?.id;
    const id = `e${enterpriseId}_u${userId}`;
    console.log("clientReferenceId ", id);
    return id;
  };

  return (
    <>
      <GModal visible={props.visible} onCancel={props.onClose} className="select-subscription-modal" width={"100vw"} footer={null}>
        <div className="">
          <div style={{ height: `calc(100vh - 100px)` }}>
            <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
            <stripe-pricing-table
              pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
              publishable-key={process.env.REACT_APP_PRICING_PUBLIC_KEY}
              client-reference-id={clientReferenceId()}
            ></stripe-pricing-table>
          </div>
          <div className="compare_link_container">
            <a className="compare_link" onClick={() => props.onDisplayCompareFeaturesModal()}>
              {translate("Compare all features")}
            </a>
          </div>
        </div>
      </GModal>
    </>
  );
};
