import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { GDivider } from "@gear/ui";
import { ReactComponent as IconArrow } from "./assets/icon_arrow.svg";
import "./index.scss";
import { useProduct } from "@/hooks/useProduct";

/**
 * 下拉列表中的选项
 * lable string 显示的文字
 * secondary? string 二级文案
 * secondaryView 二级页面
 * path? string 跳转的链接
 * prop? string 用来标识当前的值，当当前列被点击时，会传递给父级组件
 * isRouthPush?: srting 当path存在时，是否进行路由跳转，默认为true
 * render?: 自定义渲染节点
 * callback? 点击事件回调
 * divider?: 是否在上方展示分割线
 * roles?: 所需权限
 */
export type OverlayOption = {
  label: string;
  prop?: string;
  secondary?: string;
  secondaryView?: React.ReactNode;
  render?: React.ReactNode;
  divider?: boolean;
  roles?: string[];
  callback?: () => void;
  disabled?: boolean;
} & (
  | {
      path?: string;
      isRouthPush?: never;
      secondary?: string;
    }
  | {
      path: string;
      isRouthPush?: boolean;
      secondary?: string;
    }
);

/** 当前组件的Prop */
interface OverlayProps {
  visible?: boolean;
  options?: OverlayOption[];
  onClickItem?: (option: OverlayOption) => void;
}

/**
 * 头部功能区展开详情
 */
export const Overlay: React.FC<OverlayProps> = ({ visible, options, onClickItem }) => {
  const history = useHistory();
  const [selectedOptionLabel, setSelectedOptionLabel] = useState<OverlayOption["label"]>();

  useEffect(() => {
    if (visible) {
      setSelectedOptionLabel(undefined);
    }
  }, [visible]);

  const selectedOption = useMemo(() => {
    return options?.find((option) => option.label === selectedOptionLabel);
  }, [selectedOptionLabel, options]);

  return (
    <div className={`home-page-template-overlay ${selectedOption?.secondaryView ? "show-secondary-view" : ""}`}>
      <div className="home-page-template-overlay_main-view">
        {options?.map((option, index) => {
          return (
            <Fragment key={option.label}>
              {option.divider && <GDivider />}
              <div
                className={`avatar-dropdown-item ${selectedOption === option ? "selected" : ""} ${option.disabled ? "disabled" : ""}`}
                onClick={() => {
                  if (!option.disabled) {
                    setSelectedOptionLabel(option.label !== selectedOptionLabel ? option.label : undefined);
                    !option.secondaryView && onClickItem?.(option);
                  }
                }}
              >
                {option.render || (
                  <div
                    className="avatar-dropdown-item_title"
                    onClick={() => {
                      if (option.path && option.isRouthPush !== false) {
                        history.push(option.path);
                      } else {
                        option.callback?.();
                      }
                    }}
                  >
                    <span> {option.label}</span>
                    {option.secondary && <p className="avatar-dropdown-item_secondary">{option.secondary} </p>}
                  </div>
                )}
                {!option.disabled && (
                  <div className="icon-arrow">
                    <IconArrow />
                  </div>
                )}
              </div>
            </Fragment>
          );
        })}
      </div>
      {selectedOption?.secondaryView && <div className="home-page-template-overlay_secondary-view">{selectedOption?.secondaryView}</div>}
    </div>
  );
};
