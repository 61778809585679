import { urlParamsEncode } from "@/utils/common";
import { getLanguage } from "@/utils";
import { message } from "antd";
import axios from "axios";
import { t } from "i18next";
import storage from "storejs";
import { service, CustomAxiosRequestConfig, mapRequestError, ShareTokenKeys } from "@gear/business";

/**
 * 定义拦截器的语言
 */
const axiosLanguage = getLanguage();
const translationConfig = localStorage.getItem("auto_translation") === "true" ? "1" : "0";

let pending: { urlKey: string; f: () => void }[] = []; //存储每个请求的取消函数和请求标识
export let removePending = (urlKey: string, noParams = false) => {
  pending = pending.filter((item) => {
    if (item.urlKey === urlKey || (noParams && item.urlKey.indexOf(urlKey) > -1)) {
      item.f();
      return false;
    } else {
      return true;
    }
  });
};

const NoNeedToken = ["/account/sso/ticket/login"];

//请求拦截器
service.interceptors.request.use(
  (config: any) => {
    //对请求添加Token
    let token = storage.get("token");
    const simulated = storage.get("simulated_token");
    if (simulated) {
      token = storage.get("simulated_token");
    }
    // 对模拟用户拦截非GET的请求，登出除外
    if (simulated && config.method?.toUpperCase() !== "GET" && config.url !== "/api/account/logout") {
      message.error(t("Simulated user, no permission to operate."));
      return;
    }

    const url = config?.url as string;
    const isNeedToken = !NoNeedToken.some((item) => url.indexOf(item) >= 0);

    if (config.headers) {
      if (token && isNeedToken) {
        config.headers.Authorization = "Token " + token;
      }
      const enterpriseId = storage.get("current-enterprise-id");
      if (enterpriseId) {
        config.headers["X-Enterprise-ID"] = enterpriseId;
      }
      config.headers["X-APP-ID"] = process.env.REACT_APP_APP_ID || "";
      // 语言环境
      config.headers["X-LANGUAGE"] = axiosLanguage;
      config.headers["X-TIMEZONE"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.headers["X-AUTO-TRANSLATE"] = translationConfig;

      // 不同场景的分享需要单独管理凭证
      const tokenKeys = Object.values(ShareTokenKeys);
      for (const index in Object.values(tokenKeys)) {
        const shareToken = storage.get(tokenKeys[index]);
        if (shareToken) {
          config.headers[tokenKeys[index]] = shareToken;
        }
      }
    }

    let params = "@PARAMS" + (config.params ? urlParamsEncode(config.params) : "");
    let urlKey = config.url + "&" + config.method + params;

    config.cancelToken = new axios.CancelToken((c) => {
      if (config.isAbort) {
        pending.push({ urlKey: urlKey, f: c });
      }
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
service.interceptors.response.use(
  (response) => {
    let data = response.data;
    let content_disposition = response.headers["content-disposition"];
    if (response.headers["content-disposition"]) {
      data.content_disposition = content_disposition;
      data.data = data;
    }

    if (typeof (window as any).windowState == "object") {
      (window as any).windowState.notServe = false;
    }

    return response;
  },
  (error) => {
    console.log("响应错误-response", error.config, error.response);

    //断网
    if (!window.navigator.onLine) {
      message.error(t("Network error"));
      return Promise.reject(error);
    }

    //401
    const response = error.response ? error.response : {};
    if (response.status === 401) {
      storage.remove("token");
      window.location.href = "/login";
    } else if (response.status === 500) {
      message.error(t("Internal server error"));
    } else if (response.status === 504) {
      message.error(t("Network error"));
    } else if (response.status === 403) {
      if (window.location.pathname.indexOf("gantt-share") > -1) {
        // 甘特图分享如果存在没权限就是token被吊销了或者分享失效了
        localStorage.removeItem(ShareTokenKeys.projectGantt);
        message.error(t("LINK_EXPIRED"));
        // window.location.reload();
      }
      if (window.location.pathname.indexOf("project-weekly-report-share") > -1) {
        localStorage.removeItem(ShareTokenKeys.projectWeeklyReport);
        message.error(t("LINK_EXPIRED"));
        // window.location.reload();
      }
      if (window.location.pathname.indexOf("enterprises-weekly-report-share") > -1) {
        localStorage.removeItem(ShareTokenKeys.enterpriseWeeklyReport);
        message.error(t("LINK_EXPIRED"));
        // window.location.reload();
      }
      if (window.location.pathname.indexOf("enterprise-daily-report-share") > -1) {
        localStorage.removeItem(ShareTokenKeys.enterpriseDaily);
        message.error(t("LINK_EXPIRED"));
        window.location.reload();
      }
      if (window.location.pathname.indexOf("project-daily-report-share") > -1) {
        localStorage.removeItem(ShareTokenKeys.projectDaily);
        message.error(t("LINK_EXPIRED"));
        // window.location.reload();
      }
      if (window.location.pathname.indexOf("member-daily-report-share") > -1) {
        localStorage.removeItem(ShareTokenKeys.memberDaily);
        message.error(t("LINK_EXPIRED"));
        // window.location.reload();
      }
    } else {
      if (error.response && error.config.retryObj.showErrorMsg) {
        if (!error?.response?.data?.includes?.("<html>")) {
          const errorMsg = error?.response?.data ? mapRequestError(error?.response?.data) : "";
          message.error(t(errorMsg || "Request failed"));
        }
      }
    }

    //服务异常
    if (error.message === "Network Error") {
      var config = error.config;
      var isRetry = config.retryObj ? config.retryObj.isRetry : false;
      if (!config || !config.retryObj || !config.retryObj.retry || isRetry) return Promise.reject(error);
      config.retryObj.retryCount = config.retryObj.retryCount || 0;
      if (config.retryObj.retryCount >= config.retryObj.retry) {
        //几次全部都是错误时 -- 服务异常
        if (typeof (window as any).windowState == "object") {
          (window as any).windowState.notServe = true;
        }

        return Promise.reject(error);
      }

      config.retryObj.retryCount += 1;

      var backOff = new Promise((resolve) => {
        setTimeout(function () {
          resolve(true);
        }, config.retryObj.retryDelay || 1);
      });

      return backOff.then(function () {
        return service(config);
      });
    }

    return Promise.reject(error);
  }
);

export default service;
