import { GButton, GModal } from "@gear/ui";
import { useTranslation } from "react-i18next";
import { ReactComponent as DailyReportIcon } from "./assets/daily_report.svg";
import { ReactComponent as WriteDailyReportIcon } from "./assets/write_daily_report.svg";
import { ReactComponent as TaskIcon } from "./assets/tasks.svg";
import { ReactComponent as AddTaskIcon } from "./assets/add_task.svg";
import { ReactComponent as OrganizationWeeklyReportIcon } from "./assets/organization_weekly_report.svg";
import { ReactComponent as SelectedIcon } from "./assets/selected.svg";
import "./index.scss";
import { Badge } from "antd";
import { useEffect, useState } from "react";
import { useGStores } from "@gear/business";
import { UseStores } from "@/stores";
import { deepClone } from "@/utils";

export const Entries = {
  DAILY_REPORT: "DAILY_REPORT",
  WRITE_DAILY_REPORT: "WRITE_DAILY_REPORT",
  MY_TASKS: "MY_TASKS",
  ADD_TASK: "ADD_TASK",
  ORGANIZATION_WEEKLY_REPORT: "ORGANIZATION_WEEKLY_REPORT"
};
type SelectMapType = {
  [key: string]: undefined | boolean;
};
type EditOperationModalProps = {
  visible: boolean;
  setVisible: (flag: boolean) => void;
};
const EditOperationModal: React.FC<EditOperationModalProps> = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  const { configStore, userStore } = useGStores<UseStores>();
  const [selectedMap, setSelectMap] = useState<SelectMapType>(configStore.globalQuickEntry || {});
  useEffect(() => {
    setSelectMap(configStore.globalQuickEntry);
  }, [configStore.globalQuickEntry]);
  const handleSubmit = () => {
    if (userStore?.user?.id) {
      configStore
        .patchPersonalQuickOperationInfo(userStore?.user?.id, {
          ...selectedMap
        })
        .then((res) => {
          setVisible(false);
        });
    }
  };
  const modalProps = {
    width: 544,
    okText: t("Save"),
    className: "edit-operation-modal",
    title: t("Set quick entry"),
    visible,
    footer:[
      <GButton type={"primary"} onClick={handleSubmit}>{t("Save")}</GButton>,
      <GButton type={"default"} onClick={() => setVisible(false)}>{t("Cancel")}</GButton>
    ],
    onCancel: () => setVisible(false),
    onOk: handleSubmit
  };
  const allEntries = [
    {
      key: Entries.DAILY_REPORT,
      label: t("Daily report"),
      icon: <DailyReportIcon />
    },
    {
      key: Entries.WRITE_DAILY_REPORT,
      label: t("Write daily report"),
      icon: <WriteDailyReportIcon />
    },
    {
      key: Entries.MY_TASKS,
      label: t("My tasks"),
      icon: <TaskIcon />
    },
    {
      key: Entries.ADD_TASK,
      label: t("Add task"),
      icon: <AddTaskIcon />
    },
    {
      key: Entries.ORGANIZATION_WEEKLY_REPORT,
      label: t("Organization Weekly report"),
      icon: <OrganizationWeeklyReportIcon />
    }
  ];
  const handleSelected = (key: string) => {
    const tempSelectMap = deepClone(selectedMap) || {};
    tempSelectMap[key] = !Boolean(selectedMap?.[key]);
    setSelectMap(tempSelectMap);
  };
  return (
    <GModal {...modalProps}>
      {/*<div className="my-quick-entry">*/}
      {/*  <div className="title">{t("Setting quick entry")}</div>*/}
      {/*  <div className="current-entry">*/}
      {/*    {allEntries.map((item) => {*/}
      {/*      return selectedMap?.[item.key] ? (*/}
      {/*        <GButton key={item.key} type={"default"} className={`entry-item active`} icon={item.icon}>*/}
      {/*          <span className={"label"}>{item.label}</span>*/}
      {/*        </GButton>*/}
      {/*      ) : null;*/}
      {/*    })}*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="all-entries">
        <div className="all-entries-option">
          {allEntries.map((item, index) => {
            return (
              <Badge count={selectedMap?.[item.key] ? <SelectedIcon /> : null} key={item.key}>
                <GButton
                  type={"default"}
                  className={`entry-item ${selectedMap?.[item.key] ? "active" : ""}`}
                  icon={item.icon}
                  onClick={() => handleSelected(item.key)}
                >
                  <span className={"label"}>{item.label}</span>
                </GButton>
              </Badge>
            );
          })}
        </div>
      </div>
    </GModal>
  );
};

export default EditOperationModal;
