import { FC, useEffect, useMemo, useState } from "react";
import { GButton, translate, GSelect, GSelectOption } from "@gear/ui";
import { AppArch, AppInfo, AppPlatformType } from "@/apis/types";

import { ReactComponent as IconMicrosoft } from "./assets/icon_microsoft.svg";
import { ReactComponent as IconApple } from "./assets/icon_apple.svg";
import { ReactComponent as IconDownload } from "./assets/download_icon.svg";
import { AccountAPI, SettingAPI, useGStores } from "@gear/business";
import { getArchitectureForMacOS, getPlatform } from "@/utils";
import { UseStores } from "@/stores";
import "./index.scss";
interface DownloadAppGuideProps {
  showTitle?: boolean;
  onDownloaded?: () => void;
}

export const DownloadAppGuide: FC<DownloadAppGuideProps> = ({ showTitle = true, onDownloaded }) => {
  const [selectVal, setSelectVal] = useState<AppArch>(AppArch.universal);
  const { configStore } = useGStores<UseStores>();

  const os: any = getPlatform();
  let architecture = useMemo(() => {
    if (os === AppPlatformType.mac) {
      const validOSs = [AppPlatformType.win, AppPlatformType.mac, AppPlatformType.linux];
      return os && validOSs.indexOf(os) >= 0 ? getArchitectureForMacOS() : null;
    }
    return null;
  }, [os]);

  useEffect(() => {
    if (architecture) {
      setSelectVal(architecture);
    }
  }, [architecture]);

  const startDownload = (platform: AppPlatformType, arch: AppArch) => {
    AccountAPI.reportDownloadedApp("metawork");
    SettingAPI.getDownloadAppVersion()
      .then((response) => {
        const appInfos: AppInfo[] = response.data || [];
        const url = appInfos?.find((appInfo) => {
          return appInfo.platform === platform && appInfo.arch === arch;
        })?.url;
        if (url) {
          console.log(url);
          configStore.updateAppDownloaded(true);
          onDownloaded?.();
          window.location.href = url;
        }
      })
      .finally(() => {});
  };
  return (
    <div className="download-guide-box">
      {showTitle && <div className="download-guide-title">{translate("Download the desktop client for communication tools and productivity insights")}</div>}
      <div className="download-guide-content">
        <div className="download-item download-window">
          <IconMicrosoft />
          <div className={"download-item-title"}>Windows</div>
          <GButton
            style={{
              fontWeight: 600,
              marginTop: "34px",
              background: "linear-gradient(287deg, #5066ee 53.36%, #b5bfff 158.69%)"
            }}
            onClick={() => {
              startDownload(AppPlatformType.win, AppArch.x64);
            }}
          >
            <IconDownload style={{ marginRight: "4px" }} />
            {translate("Download")}
          </GButton>
        </div>
        <div className="download-item download-mac">
          <IconApple />
          <div className="download-item-title" style={{ marginTop: "12px" }}>
            macOS
          </div>
          <GSelect
            bordered={false}
            style={{
              width: "auto",
              marginTop: "-2px",
              height: "30px"
            }}
            value={selectVal}
            onChange={(value, option) => {
              setSelectVal(value);
            }}
            optionLabelProp="label"
            dropdownClassName={"down-select-drop-down-class"}
          >
            <GSelectOption value={AppArch.universal} label={translate("Universal")}>
              {translate("Universal")}
              {os === AppPlatformType.mac && !architecture && <span className="recommend-badge">{translate("Matched")}</span>}
            </GSelectOption>
            <GSelectOption value={AppArch.x64} label={translate("Intel chip")}>
              {translate("Intel chip")}
              {architecture === AppArch.x64 && <span className="recommend-badge">{translate("Matched")}</span>}
            </GSelectOption>
            <GSelectOption value={AppArch.arm64} label={translate("Apple silicon")}>
              {translate("Apple silicon")}
              {architecture === AppArch.arm64 && <span className="recommend-badge">{translate("Matched")}</span>}
            </GSelectOption>
          </GSelect>
          <GButton
            style={{
              fontWeight: 600,
              marginTop: "10px",
              background: "linear-gradient(287deg, #5066ee 53.36%, #b5bfff 158.69%)"
            }}
            onClick={() => {
              startDownload(AppPlatformType.mac, selectVal);
            }}
          >
            <IconDownload style={{ marginRight: "4px" }} />
            {translate("Download")}
          </GButton>
        </div>
      </div>
    </div>
  );
};
