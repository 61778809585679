import { GPermissions as Permissions } from "@gear/business";
import { GUser as User, AccountAPI } from "@gear/business";
import { getLanguage } from "@/utils";
import { Enterprise, isMetaworkHost } from "@gear/business";
import { makeAutoObservable } from "mobx";
import enterpriseStoreInstance from "@/stores/EnterpriseStore";

// 用户模块store

class UserStore {
  defaultLanguage = "en";
  defaultAutoTranslation = false;
  user: User | null = null;
  isCollapse?: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  updateCollapse = (collapse?: boolean) => {
    this.isCollapse = collapse;
  };

  updateUserInfo(_user: User) {
    this.user = _user;
    if (this.user?.user_info) {
      let oldLocalLanguage = localStorage.getItem("language") || getLanguage("abridge");
      const newLocalLanguage = this.user?.user_info?.language || this.defaultLanguage;
      localStorage.setItem("language", newLocalLanguage);
      localStorage.setItem(
        "auto_translation",
        `${this.user?.user_info?.auto_translation === undefined ? this.defaultAutoTranslation : this.user?.user_info?.auto_translation}`
      );
      if (oldLocalLanguage && oldLocalLanguage !== newLocalLanguage) {
        // 本地语言环境与配置不同，重新加载
        window.location.reload();
      }
    }
    this.updateEnterprises(_user.enterprises || []);
    this.filterPermissions();
  }

  updateEnterprises(_enterprises: Enterprise[]) {
    enterpriseStoreInstance.updateEnterprises(_enterprises);
  }

  fetchUser(fn?: any) {
    AccountAPI.getUserInfo().then((result) => {
      this.updateUserInfo(result.data);
      if (fn) {
        fn(result.data);
      }
    });
  }

  checkPermission(require?: Permissions[]) {
    if (!require || require.length === 0) {
      return true;
    }

    if (!this?.user?.permissions || this.user.permissions.length === 0) {
      return false;
    }

    return require.some((item) => {
      return this?.user?.permissions?.includes(item);
    });
  }

  filterPermissions() {
    if (this.user?.permissions) {
      // metawork禁用创建项目、招聘
      if (isMetaworkHost()) {
        const index = this.user.permissions.indexOf(Permissions.e_hiring);
        if (index >= 0) {
          this.user.permissions.splice(index, 1);
        }
      }
    }
    return this.user;
  }
}

export default UserStore;
