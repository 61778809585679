import { ConnectionApi, TeamApi } from "@gear/business";
import { EnterpriseSetting, WebSocketMessageName } from "@/apis/types";
import { ChildrenOptions } from "@/components/GGroupTeamCascader";
import {
  ChatMessage,
  ConnectionSchedule,
  GTask,
  GUser,
  GWebSocketMessage,
  wsInstance,
  WorkStation,
  WorkStationStatus,
  EnterpriseSettingType,
  hrApi
} from "@gear/business";
import { cloneDeep } from "lodash";
import { makeAutoObservable } from "mobx";
import { GUser as User, EnterpriseApi } from "@gear/business";

export interface VirtualOfficeFilter {
  teamUuids?: string[];
  groupIds?: number[];
  userUuids?: string[];
  status?: WorkStationStatus[];
}

export type UserCardData = { [key: string]: { weather: any; durationStat: any } };

export class VirtualOfficeStore {
  user?: GUser = undefined; // 登录的用户信息
  enterpriseId?: number; // 企业id
  filter?: VirtualOfficeFilter = {}; // 过滤选项
  loading = false; // 是否loading
  floor?: number = 1;
  mode: "simple" | "scene" = ["simple", "scene"].includes(localStorage.getItem("virtual_office_mode") || "")
    ? (localStorage.getItem("virtual_office_mode") as "simple" | "scene")
    : "scene"; // 简单、场景模式，默认场景模式
  openSettings = false; // 是否打开设置页面
  edittingSettings = false; // 是否正在编辑设置，即是否在分配位置
  allSchedules: { [key: number]: ConnectionSchedule[] } = {}; // 所有日程数据
  allWorkStations: WorkStation[] = []; // 所有工位列表
  operateWorkStations: WorkStation[] = []; // 用于操作的工位数据，用于本地操作，还未生效
  projectList: ChildrenOptions[] = []; // 项目列表选项
  groupList: ChildrenOptions[] = []; // 团队列表选项
  userCardData: UserCardData = {}; // 缓存用户名片数据，key是user_id
  voSettings: EnterpriseSetting = {};
  startTime?: number; // 页面时长统计
  isOpenStatusFilter?:boolean;  //开启 status 过滤

  constructor() {
    makeAutoObservable(this);
    wsInstance.registerMessageCallback({ key: "VirtualOfficeStore", callback: this.wsMessageCallback.bind(this) });
  }

  updateMode(mode: "simple" | "scene") {
    this.collectDuration(true);
    this.mode = mode;
    this.updateOpenSettings(false);
    localStorage.setItem("virtual_office_mode", mode);
  }

  updateProjectList(list: ChildrenOptions[]) {
    this.projectList = list;
  }

  updateGroupList(list: ChildrenOptions[]) {
    this.groupList = list;
  }

  updateUser(user: GUser) {
    this.user = user;
  }

  updateEnterpriseId(enterpriseId: number) {
    this.enterpriseId = enterpriseId;
  }

  updateAllWorkStations(workStations: WorkStation[]) {
    this.allWorkStations = workStations;
  }

  updateAllSchedules(schedules: { [key: number]: ConnectionSchedule[] }) {
    this.allSchedules = schedules;
  }

  updateUserSchedules(userID: string | number, schedules: ConnectionSchedule[]) {
    this.allSchedules[Number(userID)] = schedules;
  }

  updateFilter(filter: VirtualOfficeFilter, cover?: boolean) {
    if (cover) {
      this.filter = filter;
    } else {
      this.filter = Object.assign({}, this.filter || {}, filter);
    }
  }

  updateLoading(loading: boolean) {
    this.loading = loading;
  }
  updateOpenStatusFilter(open: boolean) {
    this.isOpenStatusFilter = open;
  }

  updateFloor(floor?: number) {
    this.floor = floor;
  }

  updateOpenSettings(open: boolean) {
    this.openSettings = open;
  }

  updateOperateWorkStations(workStations: WorkStation[]) {
    this.operateWorkStations = workStations;
  }

  updateEdittingSettings(editting: boolean) {
    this.edittingSettings = editting;
  }

  updateUserCardData(data: UserCardData) {
    this.userCardData = data;
  }

  updateVOSettings(settings: EnterpriseSetting) {
    this.voSettings = settings;
  }

  wsMessageCallback(data: GWebSocketMessage) {
    console.log(data?.name, "data?.name");
    switch (data?.name) {
      case WebSocketMessageName.TeamTeamUserCreated: // 增加工位
        this.handleCreateWorkStations(data.data);
        break;
      case WebSocketMessageName.TeamWorkstationUpdated: //更新工位
        this.handleUpdateWorkStations(data.data);
        break;
      case WebSocketMessageName.TeamWorkstationDeleted: //删除工位
        this.handleDelWorkStation(data.data);
        break;
      case WebSocketMessageName.ChatroomMessageCreated: // 收到消息
        this.handleUpdateChatroomMessage(data.data);
        break;
      case WebSocketMessageName.ChatroomMessageRead: // 阅读语音消息
        this.handleReadChatroomMessage(data.data);
        break;
      case WebSocketMessageName.ProjectTaskCreated: // 创建任务
        this.handleCreateTask(data.data);
        break;
      case WebSocketMessageName.ProjectTaskUpdated: // 更新任务
        this.handleUpdateTask(data.data);
        break;
      case WebSocketMessageName.ProjectTaskDeleted: // 删除任务
        this.handleDeleteTask(data.data);
        break;
      case WebSocketMessageName.CalendarTeammatesEventInProgressUpdated: // 日程更新
        this.handleUpdateSchedule(data.data);
        break;
    }
  }

  isSimpleMode() {
    return this.mode === "simple";
  }

  fetchProjectList(userId: number) {
    TeamApi.getEnterpriseTeams({ user: userId }).then((res) => {
      this.updateProjectList(
        res.data.team.map((item: { id: number; name: string; uuid: string }) => {
          return {
            id: item.id,
            uuid: item.uuid,
            name: item.name,
            type: "Project"
          };
        })
      );
    });
  }

  fetchGroupList() {
    hrApi.getGroups({ page: 1, page_size: 1000 }).then((res) => {
      this.updateGroupList(
        res.data.results.map((item: { id: number; name: string }) => {
          return {
            id: item.id,
            name: item.name,
            type: "Group"
          };
        })
      );
    });
  }

  /**
   * 获取所有工位数据
   * @param isHideLoading 是否隐藏loading动画
   * @returns
   */
  fetchAllWorkStations(isHideLoading?: boolean) {
    if (!this.enterpriseId) {
      console.log("enterpriseId is undefined");
      return;
    }
    this.updateLoading(!isHideLoading);

    const params = {
      enterprise: this.enterpriseId,
      with_teams: 1,
      page: 1,
      page_size: 1000
    };
    return TeamApi.getWorkStations(params)
      .then((response) => {
        this.updateAllWorkStations(response.data.results);
      })
      .finally(() => {
        this.updateLoading(false);
      });
  }

  /**
   * 获取当前正在进行的日程
   */
  fetchAllSchedules() {
    ConnectionApi.getTeamMembersSchedule({ scope: "in_progress", mode: "group_by_user" }).then((response) => {
      this.updateAllSchedules(response.data);
    });
  }

  /**
   * 获取用户正在进行的日程
   * @param userID
   */
  fetchUserSchedule(userID: string | number) {
    ConnectionApi.getTeamMembersSchedule({ scope: "in_progress", mode: "list", user_id: userID }).then((response) => {
      this.updateUserSchedules(userID, response.data);
    });
  }

  /**
   * 更新虚拟工位截图设置
   */
  fetchVOSettings() {
    if (this.enterpriseId) {
      EnterpriseApi.getEnterprisesSettings({ type: EnterpriseSettingType.VIRTUAL_OFFICE_SCREENSHOT + "" }).then((response) => {
        if (response.data.results.length > 0) {
          this.updateVOSettings(response.data.results[0]);
        } else {
          this.updateVOSettings({
            enterprise: this.enterpriseId,
            type: EnterpriseSettingType.VIRTUAL_OFFICE_SCREENSHOT,
            switch: true,
            setting: { duration: 10 }
          });
        }
      });
    }
  }

  handleCreateWorkStations(workStation: WorkStation) {
    const _workStations = [...(this.allWorkStations || [])];
    _workStations.push(workStation);
    this.updateAllWorkStations(_workStations);
    if (this.operateWorkStations?.length) {
      const _operateWorkStations = [...(this.operateWorkStations || [])];
      _operateWorkStations.push(cloneDeep(workStation));
      this.updateOperateWorkStations(_operateWorkStations);
    }
  }

  handleUpdateWorkStations(workStation: WorkStation) {
    const _workStations = [...(this.allWorkStations || [])];
    const index = _workStations?.findIndex((item) => {
      return item.id === workStation.id;
    });
    if (index !== undefined && index > -1) {
      _workStations.splice(index, 1, workStation);
      this.updateAllWorkStations(_workStations);

      if (this.operateWorkStations?.length) {
        const _operateWorkStations = [...(this.operateWorkStations || [])];
        const _index = _operateWorkStations?.findIndex((item) => {
          return item.id === workStation.id;
        });
        if (_index !== undefined && _index > -1) {
          const updateWorkStation = _workStations[index];
          const updateOperateWorkStations = _operateWorkStations[_index];
          // 比较座位是否有调整
          if (updateWorkStation.floor === updateOperateWorkStations.floor && updateWorkStation.seat === updateOperateWorkStations.seat) {
            // 没有调整过座位，则数据以推送下来的优先
            _operateWorkStations.splice(index, 1, cloneDeep(workStation));
          } else {
            // 调整过座位，则座位数据以操作数据里的优先，其他以推送的数据优先
            Object.assign(updateWorkStation, workStation, { floor: updateWorkStation.floor, seat: updateWorkStation.seat });
          }
          this.updateOperateWorkStations(_operateWorkStations);
        }
      }
    }
  }

  handleDelWorkStation(workStation: WorkStation) {
    const _workStations = [...(this.allWorkStations || [])];
    const index = _workStations?.findIndex((item) => {
      return item.id === workStation.id;
    });
    if (index !== undefined && index > -1) {
      _workStations.splice(index, 1);
      this.updateAllWorkStations(_workStations);
      if (this.operateWorkStations?.length) {
        const _operateWorkStations = [...(this.operateWorkStations || [])];
        const _index = _operateWorkStations?.findIndex((item) => {
          return item.id === workStation.id;
        });
        if (_index !== undefined && _index > -1) {
          _operateWorkStations.splice(_index, 1);
          this.updateOperateWorkStations(_operateWorkStations);
        }
      }
    }
  }

  handleUpdateChatroomMessage(data: ChatMessage) {
    if (data.enterprise_id && data.enterprise_id === this.enterpriseId) {
      // 过滤掉不是本企业的信息
      const _workStations = [...(this.allWorkStations || [])];
      const _workStation = _workStations.find((item) => {
        return item.user?.id === (data.recipient as User)?.id;
      });
      if (_workStation) {
        if (!_workStation.messages) {
          _workStation.messages = [];
        }
        _workStation.messages.push(data);
        this.updateAllWorkStations(_workStations);
      }
    }
  }

  handleReadChatroomMessage(data: { id: number }) {
    const _workStations = [...(this.allWorkStations || [])];
    const _workStation = _workStations?.find((item) => {
      return item.user?.id === this.user?.id;
    });
    if (_workStation) {
      _workStation.messages =
        _workStation.messages?.filter((message) => {
          return message.id !== data.id;
        }) || [];
      this.updateAllWorkStations(_workStations);
    }
  }

  handleCreateTask(data: GTask) {
    // 查找task负责人的工位
    const _workStations = [...(this.allWorkStations || [])];
    const _workStation = _workStations?.find((item) => {
      return (data.assignee as User)?.id === item.user?.id;
    });
    if (_workStation) {
      const index = _workStation.todo?.findIndex((todo) => {
        return todo.task?.id === data.id;
      });
      if (index === undefined || index === -1) {
        if (!_workStation.todo) {
          _workStation.todo = [];
        }
        _workStation.todo.push({
          id: data.id!, // 由于没有todo的id，这里先用任务id顶替，极端情况下会有问题
          task: data
        });
      }
      this.updateAllWorkStations(_workStations);
    }
  }

  handleUpdateTask(data: GTask) {
    // 查找task负责人的工位
    const _workStations = [...(this.allWorkStations || [])];
    const _workStation = _workStations?.find((item) => {
      return (data.assignee as User)?.id === item.user?.id;
    });
    //查找task更新之前负责人的工位
    const taskFormWorkStation = _workStations?.find((item) => {
      return item.todo && item.todo?.some((todo) => todo.task?.id === data.id);
    });
    if (_workStation) {
      const index = _workStation.todo?.findIndex((todo) => {
        return todo.task?.id === data.id;
      });
      if (index !== undefined && index > -1) {
        Object.assign(_workStation.todo?.[index]?.task!, data);
      } else {
        // 修改处理人，把任务加入到新的任务列表中
        if (!_workStation.todo) {
          _workStation.todo = [];
        }
        _workStation.todo.push({
          id: data.id!, // 由于没有todo的id，这里先用任务id顶替，极端情况下会有问题
          task: data
        });
      }
    }
    if (taskFormWorkStation && taskFormWorkStation.id !== _workStation?.id) {
      // 修改处理人，需要把任务从原任务列表中删除
      const index = taskFormWorkStation.todo?.findIndex((todo) => {
        return todo.task?.id === data.id;
      });
      if (index !== undefined && index > -1) {
        taskFormWorkStation.todo?.splice(index, 1);
      }
    }
    this.updateAllWorkStations(_workStations);
  }

  handleDeleteTask(data: GTask) {
    // 查找task负责人的工位
    const _workStations = [...(this.allWorkStations || [])];
    const _workStation = _workStations?.find((item) => {
      return (data.assignee as User)?.id === item.user?.id;
    });
    if (_workStation) {
      const index = _workStation.todo?.findIndex((todo) => {
        return todo.task?.id === data.id;
      });
      if (index !== undefined && index > -1) {
        _workStation.todo?.splice(index, 1);
        this.updateAllWorkStations(_workStations);
      }
    }
  }

  handleUpdateSchedule(data: { [key: number]: ConnectionSchedule[] }) {
    const _allSchedules = { ...(this.allSchedules || {}) };
    if (_allSchedules) {
      Object.keys(data).map((key) => {
        _allSchedules[Number(key)] = data[Number(key)];
      });
      this.updateAllSchedules(_allSchedules);
    }
  }

  appendUserCardData(data: UserCardData) {
    this.updateUserCardData(Object.assign({}, this.userCardData, data));
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      // 防止关闭浏览器页签时上传数据
      window.setTimeout(() => {
        this.collectDuration(true);
      }, 500);
    } else if (this.startTime) {
      this.startTime = Date.now();
    }
  };

  handleWindowUnload = () => {
    this.collectDuration(false);
  };

  collectDuration = (upload: boolean) => {
    const key = this.isSimpleMode() ? "e_vol_duration" : "e_vos_duration";
    let totalTime = parseInt(localStorage.getItem(key) || "0");
    if (this.startTime) {
      const now = Date.now();
      totalTime += now - this.startTime;
      this.startTime = now;
    }
    if (upload && totalTime > 5000) {
      window.collectionTag && window.collectionTag("event", key, { value: parseInt((totalTime / 1000).toFixed()) });
      localStorage.removeItem(key);
    } else if (this.startTime) {
      localStorage.setItem(key, "" + totalTime);
    }
  };
}
