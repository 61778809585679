import { get, post } from "@gear/business";

export default class SubscriptionApi {
  static async createBillingPortalLink(returnUrl: string) {
    return await post("/api/finance/enterprise_subscriptions/create_billing_portal_link", { return_url: returnUrl });
  }

  static async createSubscriptionPaymentLink(subscriptionType: string) {
    return await post("/api/finance/enterprise_subscriptions/create_subscription_payment_link", {
      subscription_type: subscriptionType
    });
  }
}
