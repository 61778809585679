import React, { FC, useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RouterContext } from "../RouterContext";
import { EnterpriseApi, GRenderRouters, useGStores, useUnreadMsgAndNotice } from "@gear/business";
import { useProduct } from "@/hooks/useProduct";
import { getSSOHost } from "@/utils";
import { useInitMyWorkStation } from "@/hooks/useInitMyWorkStation";
import { observer } from "mobx-react";
import { UseStores } from "@/stores";
import { SubscriptionExpired } from "@/components/SubscriptionExpired";
import { GButton, useGConfirm } from "@gear/ui";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "@/assets/icon/icon_close.svg";
import { isMobile } from "react-device-detect";
import { DeviceTipsModal } from "@/modals/DeviceTipsModal";
import { AppDownloadGuideModal } from "@/modals/AppDownloadGuideModal";
import { useAppDownloadGuide } from "@/hooks/useAppDownloadGuide";
import "./index.scss";

export interface AppStartProps {
  rootStore: UseStores;
}

/**
 * app 启动组件
 */
export const AppStart: FC<AppStartProps> = observer(({ rootStore }) => {
  const { enterpriseStore, userStore, configStore } = useGStores<UseStores>();
  const { filterRootRoutesWithProduct } = useProduct();
  const { fetchUnread } = useUnreadMsgAndNotice();
  const { warning } = useGConfirm();
  const { t } = useTranslation();
  const [showTipsModal, setShowTipsModal] = useState<boolean>(false);

  const { appDownloadGuideModalVisible, cancelGuide, downloaded } = useAppDownloadGuide();

  useInitMyWorkStation();

  useEffect(() => {
    if (userStore.user?.id) {
      fetchUnread();
      if (isMobile) {
        setShowTipsModal(true);
      }
    }
  }, [userStore.user?.id]);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [enterpriseStore.enterprise?.id]);

  const handleStorageChange = (e: StorageEvent) => {
    if (e.key === "current-enterprise-id") {
      if (Number(e.newValue?.replaceAll('"', "")) !== enterpriseStore.enterprise?.id) {
        // 其他tab里切换了企业，重新加载下页面
        window.location.reload();
      }
    }
  };

  const demoTips = useMemo(() => {
    const pathname = configStore.pathname;
    if (pathname.length === 0 || !enterpriseStore.enterprise || enterpriseStore.enterprise?.demo_stage !== 0) {
      return;
    }
    if (pathname.indexOf("/create-organization") >= 0) {
      return;
    }
    const isVirtualOffice = pathname.indexOf("/virtual_office") >= 0;
    return (
      <div
        style={{
          height: "54px",
          lineHeight: "54px",
          width: "100%",
          backgroundColor: "rgba(255, 116, 129, 0.1)",
          position: isVirtualOffice ? "relative" : "fixed",
          zIndex: 100,
          textAlign: "center"
        }}
      >
        <div>
          <span style={{ fontSize: "16px", color: "#FF7481", marginRight: "14px" }}>{t("We have added demo data to help you familiarize with Metawork.")}</span>
          <GButton
            type="primary"
            danger={true}
            style={{
              borderRadius: "6px",
              padding: "0 10px",
              height: "32px"
            }}
            onClick={() => {
              const enterpriseId = enterpriseStore.enterprise?.id;
              if (!enterpriseId) {
                return;
              }
              warning({
                title: t("Delete the demo data"),
                content: <div>{t("After deletion, other members of the enterprise will also be unable to see it and it cannot be recovered.")}</div>,
                closable: true,
                closeIcon: <CloseIcon />,
                className: "demo-data-warning-modal",
                okText: t("Delete demo data"),
                okButtonProps: { danger: true },
                onOk: () => {
                  EnterpriseApi.enterprisePatch(enterpriseId, { demo_stage: 1 }).then((res) => {
                    window.location.reload();
                  });
                }
              });
            }}
          >
            {t("Delete demo data")}
          </GButton>
        </div>
      </div>
    );
  }, [configStore.pathname, enterpriseStore.enterprise?.demo_stage]);

  return (
    <Router basename="/" forceRefresh={false}>
      <RouterContext>
        {demoTips}
        <GRenderRouters expiredComponent={SubscriptionExpiredPage} routes={filterRootRoutesWithProduct} ssoHost={getSSOHost() || ""} {...rootStore} />
        <DeviceTipsModal
          visible={showTipsModal}
          closeModal={() => {
            setShowTipsModal(false);
          }}
        />
        <AppDownloadGuideModal
          visible={appDownloadGuideModalVisible}
          onCancel={() => {
            cancelGuide();
          }}
          onDownloaded={() => {
            downloaded();
          }}
        />
      </RouterContext>
    </Router>
  );
});

interface SubscriptionExpiredPageProps {
  title?: string;
}
export const SubscriptionExpiredPage: FC<SubscriptionExpiredPageProps> = ({ title }) => {
  return (
    <div>
      <SubscriptionExpired isChangeEnterprise={true} isRootRouter={true} />
    </div>
  );
};
