import React, { FC, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GDrawer, GTooltip, HTMLElementProps, translate } from "@gear/ui";
import { NotificationApi } from "@/apis/notificationApi";
import { axiosWrapper } from "@/utils";
import { Notification, WebSocketMessageName } from "@/apis/types";
import { svgIcons } from "@/assets/svg";
import { NotificationsBox } from "./components/NotificationsBox";
import { NotificationsSettingBox } from "./components/NotificationsSettingBox";
import { GWebSocketMessage, useGReciveWsMessage } from "@gear/business";
import { ReactComponent as IconSetting } from "@/assets/svg/icon_setting.svg";
import "./index.scss";

export interface NotificationsProps extends HTMLElementProps<HTMLDivElement> {
  disabled?: boolean;
}

export const Notifications: FC<NotificationsProps> = ({ ...props }) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [list, setList] = useState<Notification[]>([]);

  useEffect(() => {
    axiosWrapper(NotificationApi.getList({ page: 1, page_size: 10000, ordering: "-id" })).then(({ isSuccess, result: { data } }) => {
      if (isSuccess && data.results) setList(data.results);
    });
  }, []);

  const filterList = useMemo(() => {
    const unread: Notification[] = [];
    const last: Notification[] = [];
    list.forEach((item) => {
      item.read_at ? last.push(item) : unread.push(item);
    });
    return {
      total: list.length,
      unread,
      last
    };
  }, [list]);

  /**
   * 接受WebSocket回调
   */
  useGReciveWsMessage("MessageCenter", (data: GWebSocketMessage<Notification>) => {
    if (data.data) {
      switch (data.name) {
        case WebSocketMessageName.Notice:
          noticeAddSuccess(data.data);
          break;
        case WebSocketMessageName.ReadNotice:
          readNotice(data.data);
          break;
        case WebSocketMessageName.ReadALLNotice:
          webSocketReadAll();
          break;
      }
    }
  });

  /**
   * 消息创建成功
   */
  const noticeAddSuccess = (item: Notification) => {
    list.unshift(item);
    setList([...list]);
  };

  /**
   * 已读消息
   */
  const readNotice = (item: Notification) => {
    setList(list.map((i) => (i.id === item.id ? Object.assign(i, { read_at: JSON.stringify(new Date()) }) : i)));
  };

  /**
   * WebSocket 全部已读
   */
  const webSocketReadAll = () => {
    setList(
      list.map((item) => {
        if (!item.read_at) item.read_at = JSON.stringify(new Date());
        return item;
      })
    );
  };

  const onRead = (id: number) => {
    axiosWrapper(NotificationApi.ReadNotification(id));
  };

  const onClose = () => {
    setVisible(false);
    setShowSetting(false);
  };

  return (
    <>
      {props.disabled ? (
        <div className="message-center-wrapper-icon" style={{ cursor: "no-drop" }} {...props}>
          {filterList.unread.length > 0 && (
            <div className="message-center-wrapper-icon-length">{filterList.unread.length > 99 ? "99+" : filterList.unread.length}</div>
          )}
        </div>
      ) : (
        <GTooltip title={translate("Notifications")}>
          <div
            className="message-center-wrapper-icon"
            onClick={() => {
              setVisible(true);
            }}
            {...props}
          >
            {filterList.unread.length > 0 && (
              <div className="message-center-wrapper-icon-length">{filterList.unread.length > 99 ? "99+" : filterList.unread.length}</div>
            )}
          </div>
        </GTooltip>
      )}

      <GDrawer
        className="notifications-drawer"
        width={556}
        title={
          <div className="notifications-drawer-title">
            {!showSetting && <div className="title-text">{t("Notifications")}</div>}
            <div className="setting-title" onClick={() => setShowSetting((value) => !value)}>
              {!showSetting ? (
                <IconSetting />
              ) : (
                <>
                  {svgIcons.goBack} {t("Settings")}
                </>
              )}
            </div>
          </div>
        }
        visible={visible}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.4)"
        }}
        onClose={onClose}
      >
        <div className="notifications-drawer-content">
          <NotificationsBox list={filterList} onRead={onRead} onClose={onClose} />
          <NotificationsSettingBox showSetting={showSetting} />
        </div>
      </GDrawer>
    </>
  );
};
