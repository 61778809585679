import React, { FC, useMemo, useState, useEffect, useCallback } from "react";
import { message, Space } from "antd";
import { GSelect, GSelectOption, GAvatar, GDrawer, GButton, useGConfirm, GForm, GFormItem, useGForm, GInput } from "@gear/ui";
import { useTranslation } from "react-i18next";
import { COUNTRY_CODE } from "@/utils/countryCode";
import { axiosWrapper, deepClone, getLanguage, getSSOHost, namePattern, stringIsEmail } from "@/utils";
import ChangePassword from "./ChangePassword";
import { AccountAPI, ssoLogout, useGStores, GWAvatarUpload } from "@gear/business";
import "./index.scss";
import { t } from "i18next";

interface PersonalSettingsProps {
  visible: boolean;
  onCancel: () => void;
}

/* 绑定邮箱 */
interface BindEmail {
  email: string;
  code: string;
  password?: string;
  repeat_password?: string;
}

/** 初始倒计时值 */
const startCodeNum = 60;
/** 倒计时执行器 */
let timeInterval: NodeJS.Timeout | null = null;
export const PersonalSettings: FC<PersonalSettingsProps> = ({ visible, onCancel }) => {
  const [form] = useGForm();
  const { t } = useTranslation();
  const { userStore } = useGStores();
  const [userName, setUsername] = useState<{ first_name?: string; last_name?: string; country_code?: string; phone_number?: string }>();
  /* 是否操作状态  */
  const [bind, setBind] = useState(false);
  /* 操作的类型  */
  const [bindType, setBindType] = useState<"bind" | "unbound">("bind");
  /* 操作的类型文字 */
  const [bindTypeText, setBindTypeText] = useState<"Email" | "手机">("Email");
  /** 获取邮箱验证码Loading */
  const [getCodeLoad, setGetCodeLoad] = useState(false);
  /** 倒计时 */
  const [downCode, setDownCode] = useState(startCodeNum);
  /** formRef */
  const [formRef] = useGForm();
  /* 保存时的loading */
  const [saveLoad, setSaveLoad] = useState(false);
  /** 至少成功获取过一次验证码 */
  const [isGetCode, setIsGetCode] = useState(false);
  /** 发送验证码的输入框获取焦点 */
  const [getCodeBlue, setGetCodeBlue] = useState(false);
  /* 修改密码 */
  const [passwordSettingsVisible, setPasswordSettingsVisible] = useState(false);
  /** 获取用户信息 */
  const user = useMemo(() => {
    const chineseName = userStore?.user && userStore?.user.full_name ? userStore?.user?.full_name : "";
    const englishName = userStore?.user && userStore?.user.last_name ? userStore?.user?.last_name : "";
    const full_name = userStore?.user && userStore?.user.full_name ? userStore?.user?.full_name : "";
    const phone = userStore?.user && userStore?.user.phone_number ? userStore?.user.phone_number : "--";
    const email = userStore?.user && userStore?.user.email ? userStore?.user.email : "--";
    const avatar = userStore?.user && userStore?.user.avatar ? userStore?.user.avatar : "";
    return {
      chineseName,
      englishName,
      full_name,
      phone,
      email,
      avatar
    };
  }, [userStore, userStore?.user]);
  const { confirm } = useGConfirm();

  useEffect(() => {
    if (userStore?.user) {
      const { first_name, last_name, phone_number } = userStore?.user;
      form.setFieldsValue({
        first_name,
        last_name,
        country_code: (userStore?.user as any)?.country_code,
        phone_number
      });
    }
  }, [userStore, userStore?.user, (userStore?.user as any)?.country_code, userStore?.user?.phone_number]);

  /**
   * 获取验证码
   */
  const getCodeFunction = () => {
    const enterEmail = formRef?.getFieldValue("email");
    if (!stringIsEmail(enterEmail)) {
      message.error(t("Please enter the correct email format"));
      return false;
    }
    const userEmail = bindType === "bind" ? enterEmail : user.email;
    setGetCodeLoad(true);
    axiosWrapper(AccountAPI.getVerificationCode(userEmail, "bind_email")).then(({ isSuccess }) => {
      if (isSuccess) {
        setIsGetCode(true);
        countDown(downCode);
      }
      setGetCodeLoad(false);
    });
  };

  /** 倒计时函数 */
  const countDown = (code: number) => {
    let num = code;
    num = num - 1;
    setDownCode(num);
    timeInterval = setInterval(() => {
      if (num > 0) {
        num = num - 1;
        setDownCode(num);
      } else {
        if (timeInterval) clearInterval(timeInterval);
        timeInterval = null;
        setDownCode(startCodeNum);
      }
    }, 1000);
  };

  /** 获取验证码的按钮 */
  const getCode = (
    <GButton type="text" className="get-code" onClick={getCodeFunction} loading={getCodeLoad} disabled={downCode < startCodeNum}>
      {downCode < startCodeNum ? downCode : getCodeLoad ? t("Sending") : t("Get verification code")}
    </GButton>
  );

  /** 提交表单 */
  const savePassword = useCallback(() => {
    if (isGetCode) {
      if (formRef) formRef.submit();
    } else {
      message.error(t("Please get the verification code first"));
    }
  }, [isGetCode]);

  /** 请求接口提交 */
  const submit = (value: BindEmail) => {
    setSaveLoad(true);
    const ajaxFunction = bindType === "bind" ? AccountAPI.bindEmail(value) : AccountAPI.bindEmail(value);
    axiosWrapper(ajaxFunction).then(({ isSuccess }) => {
      if (isSuccess) {
        message.success(bindType === "bind" ? t("Binding succeeded") : t("Unbinding succeeded"));
        const { user } = userStore;
        const userDeep = deepClone(user);
        // 这里用来更新公共状态管理里面的数据 后期手机解绑在这里改
        const update = bindType === "bind" ? value.email : "";
        bindTypeText === "Email" ? (userDeep.email = update) : (userDeep.phone_number = update);
        userStore.updateUserInfo(userDeep);
        if (formRef) formRef.resetFields();
        if (timeInterval) clearInterval(timeInterval);
        setDownCode(startCodeNum);
        setBind(false);
      }
      setSaveLoad(false);
    });
  };

  const handleUserAvatarOk = (id: number) => {
    axiosWrapper(
      AccountAPI.patchUser(userStore?.user && userStore?.user.id, {
        avatar_id: id
      })
    ).then((res) => {
      if (res.isSuccess) {
        // const { user } = userStore;
        // let userDeep = deepClone(user);
        // userDeep.avatar = res.result?.data?.avatar;
        // userStore.updateUserInfo(userDeep);
        userStore?.fetchUser();
      }
    });
  };
  const removeAvaHandle = () => {};

  const reset = useCallback(() => {
    confirm({
      title: t("Reset your password"),
      content: (
        <span>
          {t("HOMEPAGE_SEND_EMAIL_TO_PART1")} <b>{user.email}</b> {t("HOMEPAGE_SEND_EMAIL_TO_PART2")}
        </span>
      ),
      okText: t("Send"),
      cancelText: t("Cancel"),
      onOk: async () => {
        const { isSuccess } = await axiosWrapper(AccountAPI.resetPasswordEmail(user.email, process.env.REACT_APP_APP_ID));
        if (isSuccess) {
          message.success(t("Sent successfully"));
          return "success";
        }
        return Promise.reject();
      }
    });
  }, [user]);

  const patchUser = useCallback(() => {
    if (userName && userStore?.user?.id) {
      const { first_name, last_name, phone_number, country_code } = userName;
      const value = {
        first_name: first_name || undefined,
        last_name: last_name || undefined,
        phone_number: phone_number ? phone_number : phone_number === "" ? "" : undefined,
        country_code: country_code ? country_code : country_code === "" ? "" : undefined
      };
      if (value.first_name) {
        value.first_name = value.first_name.trim()
        if (!namePattern.test(value.first_name)) {
          value.first_name = undefined
        }
      }
      if (value.last_name) {
        value.last_name = value.last_name.trim()
        if (!namePattern.test(value.last_name)) {
          value.last_name = undefined
        }
      }
      if (JSON.stringify(value) === '{}') {
        userStore.updateUserInfo(deepClone(userStore.user));
        setUsername(undefined);
        return
      }
      axiosWrapper(AccountAPI.patchUser(userStore.user.id, value)).then(({ isSuccess, result: { data } }) => {
        if (isSuccess) {
          const { user } = userStore;
          const userDeep = deepClone(user);
          userDeep.first_name = data?.first_name;
          userDeep.last_name = data?.last_name;
          userDeep.country_code = data?.country_code;
          userDeep.phone_number = data?.phone_number;
          userStore.updateUserInfo(userDeep);
          setUsername(undefined);
        }
      });
    }
  }, [userName, userStore, userStore?.user]);

  const accountDeactivation = () => {
    confirm({
      title: t("Delete your talentOrg account"),
      content: t("Deleted accounts cannot be recovered and all your accounts and data will be lost."),
      okType: "danger",
      okText: t("Delete"),
      onOk: async () => {
        axiosWrapper(AccountAPI.delAccountUsers(userStore.user.id)).then(({ isSuccess, result: { data } }) => {
          if (isSuccess) {
            ssoLogout(process.env.PUBLIC_URL, getSSOHost() || "", process.env.REACT_APP_APP_ID || "");
          }
        });
      },
      onCancel: () => {}
    });
  };

  const footer = useMemo(() => {
    return (
      <Space>
        <GButton type="default">{t("Back")}</GButton>
        <GButton onClick={savePassword}>{t("Confirm")}</GButton>
      </Space>
    );
  }, [savePassword]);

  return (
    <>
      <GDrawer
        width={665}
        visible={visible}
        onClose={() => {
          if (userName) patchUser();
          if (bind) setBind(false);
          else onCancel();
        }}
        title={bind ? (bindType === "bind" ? t(`绑定${bindTypeText}`) : t(`解除绑定${bindTypeText}`)) : t("Personal account")}
        footer={bind ? footer : null}
        destroyOnClose={true}
        className="header-personal-settings avatar-dropdown-list-dialog"
      >
        {bind ? (
          <div className="header-personal-settings-edit">
            <GForm
              form={formRef}
              onFinish={submit}
              layout="vertical"
              initialValues={{
                email: bindType === "bind" ? "" : user.email
              }}
            >
              <GFormItem
                className={getCodeBlue ? "get-code-item get-code-item-focus" : "get-code-item"}
                label={t("Email address")}
                name="email"
                rules={[{ type: "email", required: true, message: t("Please enter the email") }]}
              >
                <GInput
                  disabled={bindType !== "bind"}
                  placeholder={t("Please enter the email")}
                  addonAfter={getCode}
                  bordered={false}
                  onFocus={() => {
                    setGetCodeBlue(true);
                  }}
                  onBlur={() => {
                    setGetCodeBlue(false);
                  }}
                />
              </GFormItem>
              <GFormItem
                label={t("Verification code")}
                name="code"
                rules={[
                  {
                    required: true,
                    min: 6,
                    max: 6,
                    message: t("Please enter a 6-digit verification code")
                  }
                ]}
              >
                <GInput placeholder={t("Please enter a 6-digit verification code")} type="number" maxLength={6} />
              </GFormItem>
              {bindTypeText === "Email" && bindType === "bind" && (
                <>
                  <GFormItem
                    label={t("New password")}
                    name="password"
                    rules={[
                      {
                        required: true,
                        min: 8,
                        max: 16,
                        message: ""
                      }
                    ]}
                  >
                    <GInput placeholder={t("Please enter a 8-16 digit password")} type="password" maxLength={16} minLength={8} />
                  </GFormItem>
                  <GFormItem
                    label={t("Please re-enter the password")}
                    name="repeat_password"
                    rules={[
                      {
                        required: true,
                        min: 8,
                        max: 16,
                        message: t("Please enter a 8-16 digit password")
                      }
                    ]}
                  >
                    <GInput placeholder={t("Please enter a 8-16 digit password")} maxLength={16} minLength={8} type="password" />
                  </GFormItem>
                </>
              )}
            </GForm>
          </div>
        ) : (
          <div className="header-personal-settings-content">
            <div className="header-personal-ava">
              <div className="user-avatar-item">
                <GAvatar size={64} src={user.avatar}>
                  {user.chineseName || user.englishName}
                </GAvatar>
              </div>
              <GWAvatarUpload
                children={
                  <div className="upload-wrapper">
                    <div className="top-box">
                      <GButton className="upload-btn" type="default" style={{ height: "42px" }}>
                        {/*{svgIcons.iconCloudUpload}*/}
                        <span>{t("Upload new photo")}</span>
                      </GButton>
                      {/*<span
                        className="remove-span"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeAvaHandle();
                        }}
                      >
                        {t("remove")}
                      </span>*/}
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="upload-dec"
                    >
                      {t("Photos help your teammates recognize you in TalentOrg")}
                    </div>
                  </div>
                }
                type={"sm"}
                src={user?.avatar}
                attachment={{ intent: 1 }}
                minWidth={64}
                maxWidth={128}
                minHeight={64}
                maxHeight={128}
                fillColor="transparent"
                imgType="png"
                onSuccess={({ id }) => {
                  handleUserAvatarOk(id);
                }}
              />
            </div>
            <GForm
              onValuesChange={(changedValues, AllValues) => {
                console.log(changedValues, AllValues, "changedValues");
                setUsername(Object.assign({}, userName, changedValues));
              }}
              form={form}
              onFinish={() => {}}
            >
              <div className="header-personal-settings-content-list">
                <span className="label-text">{t("First name")}:</span>
                <span>
                  <GFormItem rules={[{
                    required: true,
                    transform: (value) => value?.trim(),
                    message: t("Please input your first name")
                  }, {
                    pattern: namePattern,
                    message: t("Please do not enter special characters.")
                  }]} name="first_name">
                    <GInput size="large" style={{ width: "100%" }} borderRadius={"4px"} />
                  </GFormItem>
                </span>
              </div>
              <div className="header-personal-settings-content-list">
                <span className="label-text">{t("Last name")}:</span>
                <span>
                  <GFormItem rules={[{
                    required: true,
                    transform: (value) => value?.trim(),
                    message: t("Please input your last name")
                  }, {
                    pattern: namePattern,
                    message: t("Please do not enter special characters.")
                  }]} name="last_name">
                    <GInput size="large" style={{ width: "100%" }} borderRadius={"4px"} />
                  </GFormItem>
                </span>
              </div>
              <div className={`header-personal-settings-content-list`}>
                <span className="label-text">{t("Phone number")}:</span>
                <div className="header-personal-settings-content-list-phone">
                  <GFormItem rules={[{ required: false, message: "" }]} name="country_code">
                    <GSelect
                      size="large"
                      bordered={false}
                      dropdownMatchSelectWidth={false}
                      showSearch={true}
                      filterOption={(input, option) => ((option?.label as string) || "").toLowerCase().indexOf(input.toLowerCase()) > -1}
                      optionLabelProp="text"
                    >
                      {COUNTRY_CODE?.map((item) => {
                        const label = getLanguage() === "en-us" ? `${item.english_name}(+${item.phone_code})` : `${item.chinese_name}(+${item.phone_code})`;
                        return (
                          <GSelectOption value={item.phone_code} key={item.phone_code} text={`+${item.phone_code}`} label={label}>
                            {label}
                          </GSelectOption>
                        );
                      })}
                    </GSelect>
                  </GFormItem>
                  <GFormItem rules={[{ required: false, message: "" }]} name="phone_number">
                    <GInput
                      bordered={false}
                      size="large"
                      style={{ width: "100%" }}
                      borderRadius={"4px"}
                      onChange={(e) => {
                        form?.setFieldsValue({
                          phone: e.target.value?.toString()?.replace(/\D/g, "")
                        });
                      }}
                    />
                  </GFormItem>
                </div>
              </div>
            </GForm>
            <div className="header-personal-settings-content-list list-label">
              <span className="label-text-inline">{t("Account")}:</span>
              <span className="center-text">{user.email}</span>
              {
                user.email === "--" ? (
                  <span
                    className="change-password"
                    onClick={() => {
                      setBind(true);
                      setBindType("bind");
                      setBindTypeText("Email");
                    }}
                  >
                    {t("Binding")}
                  </span>
                ) : (
                  <></>
                )
                // 解绑先不做
                // <span
                //   onClick={() => {
                //     setBind(true)
                //     setBindType('unbound')
                //     setBindTypeText('邮箱')
                //   }}
                // >
                //   解绑
                // </span>
              }
            </div>
            {user.email !== "--" && (
              <div className="header-personal-settings-content-list list-label">
                <span className="label-text-inline">{t("Password")}:</span>
                <span className="center-text">******</span>
                <span onClick={reset} className="change-password">
                  {t("Reset")}
                </span>
              </div>
            )}
          </div>
        )}
        <div className="account-deactivation">
          <span className="del-span" onClick={accountDeactivation}>
            {t("Close your account")}
          </span>
        </div>
      </GDrawer>
      {user.email && passwordSettingsVisible && (
        <ChangePassword
          userEmail={user?.email || ""}
          visible={passwordSettingsVisible}
          onCancel={() => {
            setPasswordSettingsVisible(false);
            onCancel();
          }}
        />
      )}
    </>
  );
};

export default PersonalSettings;
