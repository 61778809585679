import { ProductMode } from "@/apis/types";
import { GPermissions as Permissions } from "@gear/business";
import { GRoute } from "@gear/ui";
import { lazy } from "react";
import { ROUTE_CONSTANTS } from "./const";

export interface Route extends GRoute {
  mode?: ProductMode[];
  selfLayout?: boolean;
  isFree?: boolean;
}

// 放置全屏页面
const rootRoutes: Route[] = [
  {
    path: "/login",
    title: "",
    component: lazy(() => import("@/views/Login")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  },
  {
    path: "/ticketlogin",
    component: lazy(() => import("@/views/ImpersonatorLogin")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  },
  {
    path: ROUTE_CONSTANTS.VIRTUAL_OFFICE,
    title: "Virtual office",
    component: lazy(() => import("@/views/VirtualOffice")),
    mode: [ProductMode.metawork]
  },
  {
    path: "/project/report/share",
    component: lazy(() => import("@/views/ReportReadOnly/WeeklyReportMailTemplate")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  },
  // 勿动，这是旧的分享链接，要兼容已发出的老链接，需要保留
  {
    path: "/weekly-report/share",
    redirect: "/weekly-report-share"
  },
  {
    path: "/weekly-report-share",
    redirect: "/enterprises-weekly-report-share"
  },
  {
    path: "/enterprises-weekly-report-share",
    component: lazy(() => import("@/views/ReportReadOnly/EnterpriseReportShare")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  },
  {
    path: "/project-weekly-report-share",
    component: lazy(() => import("@/views/ReportReadOnly/ProjectWeeklyReportShare")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  },
  {
    path: "/enterprise-daily-report-share",
    component: lazy(() => import("@/views/ReportReadOnly/EnterprisesDailyReportsShare")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  },
  {
    path: "/project-daily-report-share",
    component: lazy(() => import("@/views/ReportReadOnly/TimeSheetShare")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  },
  {
    path: ROUTE_CONSTANTS.MEMBER_SHARE_DAILY_REPORT,
    component: lazy(() => import("@/views/ReportReadOnly/MemberDailyReportShare")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  },
  {
    path: "/gantt-share",
    component: lazy(() => import("@/views/ProjectGanttReadOnly")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  },
  {
    path: "/invite-members",
    component: lazy(() => import("@/views/InviteMembers")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  },
  {
    path: "/create-organization",
    component: lazy(() => import("@/components/CreateOrganization")),
    auth: {
      loginRequired: true, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  },
  {
    path: "/middle-page",
    component: lazy(() => import("@/views/MiddlePage")),
    auth: {
      loginRequired: true, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  },
  {
    path: "/weekly-report",
    component: lazy(() => import("@/views/WeeklyReportsProject")),
    auth: {
      loginRequired: true, //是否验证登录
      permsRequired: false //是否验证权限
    },
    mode: [ProductMode.metawork]
  },
  {
    path: ROUTE_CONSTANTS.EMAIL_SHARE_DAILY_REPORT,
    component: lazy(() => import("@/views/EmailContent/ShareDailyReport")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  },
  {
    path: "/",
    exact: true,
    component: lazy(() => import("@/views/LandingPage")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  },
  {
    path: "/pricing",
    exact: true,
    component: lazy(() => import("@/views/LandingPage/Pricing")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  },
  {
    path: ROUTE_CONSTANTS.FEATURES_VIRTUAL_OFFICE,
    exact: true,
    component: lazy(() => import("@/views/LandingPage/VirtualOffice")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  },
  {
    path: ROUTE_CONSTANTS.FEATURES_COMMUNICATION_TOOL,
    exact: true,
    component: lazy(() => import("@/views/LandingPage/CommunicationTools")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  },
  {
    path: ROUTE_CONSTANTS.FEATURES_PRODUCTIVITY_INSIGHTS,
    exact: true,
    component: lazy(() => import("@/views/LandingPage/ProductiveInsights")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  },
  {
    path: "/connect/ClickUp",
    title: "Connect ClickUp",
    component: lazy(() => import("@/views/Connections/callback/ClickUp"))
  },
  // {
  //   path: "/404",
  //   component: lazy(() => import("@/views/Error/404")),
  //   isFree: true,
  //   exact: true
  // },
  {
    path: "/",
    component: lazy(() => import("@/views/Home")),
    // 限制mode，防止在打开其他的全屏页面时会闪一下home页面
    mode: [ProductMode.metawork],
    isFree: true
  },
  {
    // 添加一个兜底的默认页面，在未登录时，Home不会被注册到路由中，这时将不会自动跳转到登录页面，所以需要加入一个兜底的页面做登录跳转
    path: "/",
    component: lazy(() => import("@/views/Error/404")),
    isFree: true
  }
];

if (process.env.REACT_APP_DEPLOY_ENV === "development") {
  rootRoutes.unshift({
    path: "/svg-icon-view",
    component: lazy(() => import("@/views/IconView")),
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    },
    isFree: true
  });
}

// 放置嵌套在首页的页面
const otherRoutes: Route[] = [
  {
    path: ROUTE_CONSTANTS.METAWORK_SETTING,
    title: "METAWORK_SETTING_MENU",
    component: lazy(() => import("@/views/Enterprise/MetaworkSettings")),
    permissions: [Permissions.e_metawork_settings],
    subRoute: [
      {
        path: ROUTE_CONSTANTS.SETTINGS_APP_AND_SITES,
        permissions: [Permissions.e_metawork_settings],
        subRoute: [
          {
            path: ROUTE_CONSTANTS.SETTINGS_APP_AND_SITES_ENABLE_STATUS
          },
          {
            path: ROUTE_CONSTANTS.SETTINGS_APP_AND_SITES_CLASSIFICATION
          },
          {
            path: ROUTE_CONSTANTS.SETTINGS_APP_AND_SITES_ALERT
          }
        ]
      },
      {
        path: ROUTE_CONSTANTS.SETTINGS_DAILY_CHECKINS,
        permissions: [Permissions.e_metawork_settings]
      },
      {
        path: ROUTE_CONSTANTS.SETTINGS_WORKLOAD,
        subRoute: [
          {
            path: ROUTE_CONSTANTS.SETTINGS_WORKLOAD_ALERT
          },
          {
            path: ROUTE_CONSTANTS.SETTINGS_WORKLOAD_APPLICABLE_MEMBERS
          }
        ]
      },
      {
        path: ROUTE_CONSTANTS.SETTINGS_SCREENSHOTS,
        permissions: [Permissions.e_metawork_settings]
      }
    ]
  },
  {
    path: ROUTE_CONSTANTS.ORGANIZATION_INFO_SETTING,
    title: "ORGANIZATION_INFO_MENU",
    component: lazy(() => import("@/views/Enterprise/Setting")),
    permissions: [Permissions.e_company_info]
  },
  {
    path: "/system/role/edit",
    title: "Roles",
    component: lazy(() => import("@/views/System/Role/Edit")),
    permissions: [Permissions.e_roles]
  },
  {
    // 当前路由内的title 由组件内部设定， 详情请搜索 useDashboardTitle
    path: "/team/detail",
    component: lazy(() => import("@/views/Team/Detail")),
    mode: [ProductMode.metawork],
    title: "Project",
    // // 仅做路由校验使用
    subRoute: [
      {
        path: "/team/detail",
        permissions: [Permissions.e_hiring, Permissions.e_project_management, Permissions.e_project_view]
      },
      {
        path: ROUTE_CONSTANTS.PROJECT_DETAIL_DASHBOARD,
        permissions: [Permissions.e_project_management, Permissions.e_project_view]
      },
      {
        path: ROUTE_CONSTANTS.TEAM_USER,
        permissions: [Permissions.e_hiring, Permissions.e_project_management, Permissions.e_project_view]
      },
      {
        path: ROUTE_CONSTANTS.TEAM_DETAIL_GANTT,
        permissions: [Permissions.e_project_management, Permissions.e_project_view]
      },
      {
        path: "/team/detail/daily-work/project",
        permissions: [Permissions.e_project_management]
      },
      {
        path: "/team/detail/timesheet",
        permissions: [Permissions.e_project_management, Permissions.e_project_view]
      },
      {
        path: "/team/detail/team-user",
        permissions: [Permissions.e_hiring, Permissions.e_project_view]
      }
    ]
  },
  {
    path: "/no-roles",
    component: lazy(() => import("@/views/NoRoles")),
    permissions: []
  },
  {
    path: "/members",
    title: "Members",
    component: lazy(() => import("@/views/Members")),
    permissions: [Permissions.e_members],
    isFree: true
  },
  {
    path: ROUTE_CONSTANTS.PROFILE,
    title: "个人中心",
    component: lazy(() => import("@/views/Profile")),
    mode: [ProductMode.metawork],
    subRoute: [
      {
        path: "/profile/overview"
      },
      {
        path: "/profile/daily-report"
      },
      {
        path: "/profile/task"
      },
      {
        path: "/profile/project"
      }
    ]
  },

  {
    path: "/tasks",
    title: "My tasks",
    component: lazy(() => import("@/views/Tasks")),
    mode: [ProductMode.metawork]
  },
  {
    path: "/daily-reports",
    title: "Daily check-ins",
    component: lazy(() => import("@/views/DailyReports")),
    mode: [ProductMode.metawork],
    // // 仅做路由校验使用
    subRoute: [
      {
        path: "/daily-reports/all"
      },
      {
        path: "/daily-reports/my"
      }
    ]
  },
  {
    path: ROUTE_CONSTANTS.ORGANIZATION_WEEKLY_REPORT,
    title: "Weekly reports",
    component: lazy(() => import("@/views/OrganizationWeeklyReports")),
    mode: [ProductMode.metawork]
  },
  {
    path: "/groups",
    title: "Groups",
    component: lazy(() => import("@/views/Groups")),
    permissions: [Permissions.e_groups]
  },
  {
    path: ROUTE_CONSTANTS.ALL_PROJECTS,
    title: "Projects",
    component: lazy(() => import("@/views/AllProjects")),
    mode: [ProductMode.metawork]
  },
  {
    path: "/connections",
    title: "Connections",
    component: lazy(() => import("@/views/Connections"))
  },
  {
    path: ROUTE_CONSTANTS.DASHBOARD,
    title: "Project roadmap",
    component: lazy(() => import("@/views/Dashboard")),
    mode: [ProductMode.metawork]
  },
  {
    path: "/efficiency",
    title: "Team stats",
    component: lazy(() => import("@/views/EmployeeEfficiency")),
    mode: [ProductMode.metawork]
  },
  {
    path: ROUTE_CONSTANTS.INSIGHTS,
    title: "Insights",
    component: lazy(() => import("@/views/Insights")),
    mode: [ProductMode.metawork],
    permissions: [Permissions.e_workstation_screenshot]
  },
  {
    path: ROUTE_CONSTANTS.HOME_PAGE,
    title: "Home",
    component: lazy(() => import("@/views/HomePage")),
    mode: [ProductMode.metawork]
  },
  {
    path: ROUTE_CONSTANTS.ACTIVITY_STATS,
    title: "Reports",
    component: lazy(() => import("@/views/Reports/ActivityStats"))
  },
  {
    path: ROUTE_CONSTANTS.TASK_STATS,
    title: "Reports",
    component: lazy(() => import("@/views/Reports/TaskStats"))
  },
  {
    path: ROUTE_CONSTANTS.DAILY_STATS,
    title: "Reports",
    component: lazy(() => import("@/views/Reports/DailyStats"))
  },
  {
    path: ROUTE_CONSTANTS.REPORTS,
    title: "Reports",
    component: lazy(() => import("@/views/Reports"))
  },
  {
    path: "*",
    // redirect: "/404",
    component: lazy(() => import("@/views/Error/404")),
    isFree: true,
    auth: {
      loginRequired: false, //是否验证登录
      permsRequired: false //是否验证权限
    }
  }
];

/**
 * 每个权限对应路由的地址
 */
const routerRolesObject = [...rootRoutes, ...otherRoutes]
  .filter((item) => item.permissions)
  .reduce((total: GRoute[], item: GRoute) => {
    if (item.subRoute) total = total.concat(item.subRoute);
    else total.push({ path: item.path, permissions: item.permissions });
    return total;
  }, []);

const allCanNextRouter = [...rootRoutes, ...otherRoutes]
  .filter((item) => !item.permissions)
  .map((item) => item.path)
  .filter((item) => item !== "/");

export { rootRoutes, otherRoutes, routerRolesObject, allCanNextRouter };
