import { useGStores, wsInstance } from "@gear/business";
import { useEffect } from "react";
import { useMeetingDetected } from "./useMeetingDetected";
import { UseStores } from "@/stores";
import { useMyWorkStation } from "./useMyWorkStation";
/**
 * 我的工位数据初始化hook
 * 1、数据初始化
 * 2、对接长连接数据同步
 */
export function useInitMyWorkStation() {
  const { enterpriseStore } = useGStores<UseStores>();
  const { myWorkStation } = useMyWorkStation();

  /**
   * 开启会议检测
   */
  useMeetingDetected();

  useEffect(() => {
    // 开通metawork才能使用虚拟工位
    if (myWorkStation && enterpriseStore.enterprise?.metawork_opened) {
      wsInstance.setHeartParams({ data: { id: myWorkStation.id, enterprise_id: enterpriseStore.enterprise.id } });
    } else {
      wsInstance.setHeartParams(undefined);
    }
  }, [myWorkStation?.id, enterpriseStore.enterprise?.metawork_opened]);
}
