import { get, patch, post, del } from "@gear/business";
import { CalDAVUser, GitLabEnterpriseConfig, JiraEnterpriseConfig, ThirdPartyIntegrationSceneEnum, ThirdPartyIntegrationPlatformEnum } from "./types";

export default class ThirdPartiesApi {
  /**
   * 获取Git日志
   * @param parameters
   * @returns
   */
  static async getGitLogs(parameters: { user_id?: number; date?: string }) {
    const url = `/api/thirdparties/gitlab/dailylogs`;
    return get(url, parameters);
  }

  /**
   * 获取Git绑定信息
   * @param parameters
   * @returns
   */
  static async getGitLabConnectInfo() {
    const url = `/api/thirdparties/gitlab/connect_info`;
    return get(url);
  }

  /**
   * 添加 GitLab 绑定的拓展邮箱
   * @param parameters
   * @returns
   */
  static async addGitLabConnectInfoExtendEmail(id: number, extend_email: string) {
    const url = `/api/thirdparties/gitlab/connect_info/add_extend_mail`;
    return post(url, {
      id,
      extend_email
    });
  }
  /**
   * 删除 GitLab 绑定信息拓展邮箱
   * @param parameters
   * @returns
   */
  static async removeGitLabConnectInfoExtendEmail(id: number, extend_email: string) {
    const url = `/api/thirdparties/gitlab/connect_info/remove_extend_mail`;
    return del(url, {
      id,
      extend_email
    });
  }
  /**
   * 获取Jira日志
   * @param parameters
   * @returns
   */
  static async getJiraLogs(parameters: { user_id?: number; date?: string }) {
    const url = `/api/thirdparties/jira/dailylogs`;
    return get(url, parameters);
  }
  /**
   * 获取 Jira 任务数据
   * @param parameters
   * @returns
   */
  static async getJiraTasks(date: string) {
    const url = `/api/thirdparties/jira/issues?date=${date}`;
    return get(url);
  }

  /**
   * 获取Jira绑定信息
   * @param parameters
   * @returns
   */
  static async getJiraConnectInfo() {
    const url = `/api/thirdparties/jira/connect_info`;
    return get(url);
  }

  static async jiraDisconnect() {
    const url = "/api/thirdparties/jira/bind";
    return del(url);
  }

  /**
   * 获取Jira企业配置
   */
  static async getJiraEnterpriseConfig() {
    const url = `/api/thirdparties/jira/enterprise_config`;
    return get(url);
  }

  /**
   * 更新Jira企业配置
   */
  static async updateJiraEnterpriseConfig(data: JiraEnterpriseConfig) {
    const url = `/api/thirdparties/jira/enterprise_config`;
    return post(url, data);
  }

  /**
   * 绑定Jira用户
   */
  static async bindJiraUser(email: string) {
    const url = `/api/thirdparties/jira/bind`;
    return post(url, { email });
  }

  /**
   * 获取GitLab企业配置
   */
  static async getGitLabEnterpriseConfig() {
    const url = `/api/thirdparties/gitlab/enterprise_config`;
    return get(url);
  }

  /**
   * 更新GitLab企业配置
   */
  static async updateGitLabEnterpriseConfig(data: GitLabEnterpriseConfig) {
    const url = `/api/thirdparties/gitlab/enterprise_config`;
    return post(url, data);
  }

  /**
   * 绑定GitLab用户
   */
  static async bindGitLabUser(email: string) {
    const url = `/api/thirdparties/gitlab/bind`;
    return post(url, { email });
  }

  /**
   * 获取绑定链接
   * @param params
   */
  static async connectUrl(params: { scene: ThirdPartyIntegrationSceneEnum; redirect_uri: string; platform?: ThirdPartyIntegrationPlatformEnum }) {
    const url = `/api/thirdparties/connect_url`;
    return post(url, params);
  }

  /**
   * 绑定回调
   * @param params
   */
  static async connectCallback(params: { state: string; code?: string; error?: string; platform: ThirdPartyIntegrationPlatformEnum }) {
    const url = `/api/thirdparties/connect_callback`;
    return post(url, params);
  }

  /**
   * 获取Google绑定信息
   * @param params
   */
  static async getGoogleConnections(params: { scene: ThirdPartyIntegrationSceneEnum }) {
    const url = `/api/thirdparties/google`;
    return get(url, params);
  }

  /**
   * 解绑Google用户
   * @param id
   */
  static async disconnectGoogle(id: number) {
    const url = `/api/thirdparties/google/${id}`;
    return del(url);
  }

  /**
   * 更新Google日历开启状态
   * @param parameters
   */
  static async updateGoogleCalendarEnable(parameters: { calendar_id?: number; enabled: boolean }) {
    const url = `/api/thirdparties/google/update_calendar_enabled`;
    return post(url, parameters);
  }

  /**
   * 获取Microsoft绑定信息
   */
  static async getMicrosoftConnections(params: { scene: ThirdPartyIntegrationSceneEnum }) {
    const url = `/api/thirdparties/microsoft`;
    return get(url);
  }

  /**
   * 解绑Microsoft用户
   */
  static async disconnectMicrosoft(id: number) {
    const url = `/api/thirdparties/microsoft/${id}`;
    return del(url);
  }

  /**
   * 更新Microsoft日历开启状态
   */
  static async updateMicrosoftCalendarEnable(parameters: { calendar_id?: number; enabled: boolean }) {
    const url = `/api/thirdparties/microsoft/update_calendar_enabled`;
    return post(url, parameters);
  }

  /**
   * 获取 caldav 绑定信息
   * @param parameters
   */
  static async getCalDAV(parameters?: { page?: number; page_size?: number }) {
    const url = "/api/thirdparties/caldav";
    return get(url, parameters);
  }

  /**
   * 创建 caldav 绑定信息
   * @param parameters
   */
  static async createCalDAV(parameters: CalDAVUser) {
    const url = "/api/thirdparties/caldav";
    return post(url, parameters);
  }

  /**
   * 更新 caldav 绑定信息
   * @param id
   * @param parameters
   */
  static async updateCalDAV(id: number, parameters: CalDAVUser) {
    const url = `/api/thirdparties/caldav/${id}`;
    return patch(url, parameters);
  }

  /**
   * 删除 caldav 绑定信息
   * @param id
   */
  static async deleteCalDAV(id: number) {
    const url = `/api/thirdparties/caldav/${id}`;
    return del(url);
  }

  /**
   * 更新 caldav 日历开启状态
   * @param parameters
   */
  static async updateCalDAVCalendarEnable(parameters: { calendar_id?: number; enabled: boolean }) {
    const url = `/api/thirdparties/caldav/update_calendar_enabled`;
    return post(url, parameters);
  }

  /**
   * 获取投票应用列表
   * @returns
   */
  static async getVotingApps() {
    const url = "/api/thirdparties/voting/apps";
    return get(url);
  }

  static async voting(appId: number) {
    const url = "/api/thirdparties/voting/users";
    return post(url, { application: appId });
  }

  static async cancelVoting(voting_user_id: number) {
    const url = `/api/thirdparties/voting/users/${voting_user_id}`;
    return del(url);
  }

  static async postVotingOther(params: { voted_app_names: string[]; description: string }) {
    const url = "/api/thirdparties/voting/apps/other";
    return post(url, params);
  }

  static async getClickUpInitConnectInfo() {
    const url = "/api/thirdparties/clickup/get_connect_info";
    return get(url);
  }

  static async setClickUpInitConnectInfo(clientId: string, clientSecret: string) {
    const url = "/api/thirdparties/clickup/set_init_connect_info";
    return post(url, { client_id: clientId, client_secret: clientSecret });
  }

  static async getClickUpConnectUrl() {
    const url = "/api/thirdparties/clickup/get_connect_url";
    return get(url);
  }

  static async setClickUpConnectCode(code: string) {
    const url = "/api/thirdparties/clickup/set_connect_code";
    return post(url, { code });
  }

  static async bindClickUpUser(email: string) {
    const url = "/api/thirdparties/clickup/bind_to_email";
    return post(url, { third_party_email: email });
  }
}
