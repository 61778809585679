import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { NotificationApi } from "@/apis/notificationApi";
import { NotificationCategory, NotificationsConfig, NotificationsConfigures } from "@/apis/types";
import { GSwitch, GRadio, GRadioGroup, GSelect, GSelectOption, timeDeep, weekDay, GScrollBar } from "@gear/ui";
import { axiosWrapper } from "@/utils";
import { Checkbox, Spin } from "antd";
import { useTranslation } from "react-i18next";

interface ISettingProps {
  showSetting: boolean;
}
export const NotificationsSettingBox: FC<ISettingProps> = ({ showSetting }) => {
  const timeList = useMemo(() => timeDeep(), []);
  const { t } = useTranslation();
  const week = useMemo(() => weekDay.map((item) => t(item)), []);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState<{ load: boolean } & NotificationsConfig>({
    load: false,
    permissions: [],
    configures: []
  });

  useEffect(() => {
    if (showSetting && !config.load) {
      setLoading(true);
      axiosWrapper(NotificationApi.getConfig()).then(({ isSuccess, result: { data } }) => {
        if (isSuccess) setConfig(Object.assign({}, config, { load: true }, data));
        setLoading(false);
      });
    }
  }, [showSetting, config]);

  const onPatch = useCallback(
    <T extends keyof NotificationsConfigures>(id: number, prop: T, value: NotificationsConfigures[T]) => {
      axiosWrapper(NotificationApi.patchConfig({ id, [prop]: value })).then(({ isSuccess, result: { data } }) => {
        if (isSuccess) {
          config.configures = config.configures.map((item) => (item.id === data.id ? data : item));
          setConfig(Object.assign({}, config));
        }
      });
    },
    [config]
  );

  const mentionsAndCommentsMessageConfig = useMemo(() => {
    return config?.configures?.find((item) => item.category === NotificationCategory.MentionsAndCommentsMessage);
  }, [config]);

  const taskUpdateMessageConfig = useMemo(() => {
    return config?.configures?.find((item) => item.category === NotificationCategory.TaskUpdateMessage);
  }, [config]);

  const reminderUpdateWeeklyMessageConfig = useMemo(() => {
    return config?.configures?.find((item) => item.category === NotificationCategory.ReminderUpdateWeeklyMessage);
  }, [config]);

  const reminderWriteDailyMessageConfig = useMemo(() => {
    return config?.configures?.find((item) => item.category === NotificationCategory.ReminderWriteDailyMessage);
  }, [config]);

  const weeklyMessageConfig = useMemo(() => {
    return config?.configures?.find((item) => item.category === NotificationCategory.WeeklyMessage);
  }, [config]);

  return (
    <div className={"setting-box " + (showSetting ? "show" : "")}>
      <GScrollBar style={{ height: "100%" }}>
        <div className="setting-box-title">{t("Please confirm whether you want to enable these notifications")}</div>
        <Spin spinning={loading}>
          {config.load && (
            <div className="setting-box-content">
              <div className="setting-box-item">
                <div className="setting-box-item-center">
                  <div className="text-title">{t("Mentions and comments")}</div>
                  <GRadioGroup
                    disabled={!mentionsAndCommentsMessageConfig?.turn_on}
                    value={mentionsAndCommentsMessageConfig?.message_type}
                    className="radio-group-set"
                    onChange={(e) => {
                      if (mentionsAndCommentsMessageConfig?.id) {
                        onPatch(mentionsAndCommentsMessageConfig.id, "message_type", e.target.value);
                      }
                    }}
                  >
                    <GRadio value={1}>{t("In-app notifications")}</GRadio>
                    <GRadio value={2}>{t("Email notifications")}</GRadio>
                  </GRadioGroup>
                </div>
                <div className="setting-box-item-icon">
                  <GSwitch
                    defaultChecked={mentionsAndCommentsMessageConfig?.turn_on}
                    onChange={(checked) => {
                      if (mentionsAndCommentsMessageConfig?.id) {
                        onPatch(mentionsAndCommentsMessageConfig.id, "turn_on", checked);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="setting-box-item">
                <div className="setting-box-item-center">
                  <div className="text-title">{t("Task updates")}</div>
                  <GRadioGroup
                    disabled={!taskUpdateMessageConfig?.turn_on}
                    value={taskUpdateMessageConfig?.message_type}
                    className="radio-group-set"
                    onChange={(e) => {
                      if (taskUpdateMessageConfig?.id) {
                        onPatch(taskUpdateMessageConfig.id, "message_type", e.target.value);
                      }
                    }}
                  >
                    <GRadio value={1}>{t("In-app notifications")}</GRadio>
                    <GRadio value={2}>{t("Email notifications")}</GRadio>
                  </GRadioGroup>
                  <div className="setting-box-item_subtitle">{t("Updates of what I was assigned")}:</div>
                  <div className="setting-box-item-center">
                    <Checkbox
                      disabled={!taskUpdateMessageConfig?.turn_on}
                      checked={taskUpdateMessageConfig?.expand?.task_switch}
                      onChange={(e) => {
                        if (taskUpdateMessageConfig?.id) {
                          onPatch(taskUpdateMessageConfig.id, "expand", {
                            ...(taskUpdateMessageConfig?.expand || {}),
                            task_switch: e.target.checked
                          });
                        }
                      }}
                      style={{ marginBottom: "10px", width: "100%" }}
                    >
                      {t("Tasks")}
                    </Checkbox>
                    <Checkbox
                      disabled={!taskUpdateMessageConfig?.turn_on}
                      checked={taskUpdateMessageConfig?.expand?.version_switch}
                      onChange={(e) => {
                        if (taskUpdateMessageConfig?.id) {
                          onPatch(taskUpdateMessageConfig.id, "expand", {
                            ...(taskUpdateMessageConfig?.expand || {}),
                            version_switch: e.target.checked,
                            all_tasks_in_version_switch: e.target.checked && taskUpdateMessageConfig?.expand?.all_tasks_in_version_switch
                          });
                        }
                      }}
                      style={{ marginBottom: "10px", marginLeft: "0px", width: "100%" }}
                    >
                      {t("Versions")}
                    </Checkbox>
                    <Checkbox
                      disabled={!taskUpdateMessageConfig?.turn_on || !taskUpdateMessageConfig?.expand?.version_switch}
                      checked={taskUpdateMessageConfig?.expand?.all_tasks_in_version_switch}
                      onChange={(e) => {
                        if (taskUpdateMessageConfig?.id) {
                          onPatch(taskUpdateMessageConfig.id, "expand", {
                            ...(taskUpdateMessageConfig?.expand || {}),
                            all_tasks_in_version_switch: e.target.checked
                          });
                        }
                      }}
                      style={{ marginBottom: "10px", marginLeft: "28px ", width: "100%" }}
                    >
                      {t("All tasks in the version")}
                    </Checkbox>
                  </div>
                </div>
                <div className="setting-box-item-icon">
                  <GSwitch
                    defaultChecked={taskUpdateMessageConfig?.turn_on}
                    onChange={(checked) => {
                      if (taskUpdateMessageConfig?.id) {
                        onPatch(taskUpdateMessageConfig.id, "turn_on", checked);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="setting-box-item">
                <div className="setting-box-item-center">
                  <div className="text-title">{t("Project weekly report update reminder email")}</div>
                  <div className="select-time-box">
                    <div className="select-time-box-item">
                      <span className="select-label">{t("remind-at")}</span>
                      <GSelect
                        virtual={false}
                        disabled={!reminderUpdateWeeklyMessageConfig?.turn_on}
                        value={reminderUpdateWeeklyMessageConfig?.time_point}
                        style={{ width: 90 }}
                        onChange={(value) => {
                          if (reminderUpdateWeeklyMessageConfig?.id) {
                            onPatch(reminderUpdateWeeklyMessageConfig.id, "time_point", value);
                          }
                        }}
                      >
                        {timeList.map((item) => {
                          return (
                            <GSelectOption key={item} value={item}>
                              {item}
                            </GSelectOption>
                          );
                        })}
                      </GSelect>
                    </div>
                    <div className="select-time-box-item">
                      <span className="select-label">{t("every")}</span>
                      <GSelect
                        virtual={false}
                        disabled={!reminderUpdateWeeklyMessageConfig?.turn_on}
                        value={reminderUpdateWeeklyMessageConfig?.week}
                        style={{ width: "120px" }}
                        onChange={(value) => {
                          if (reminderUpdateWeeklyMessageConfig?.id) {
                            onPatch(reminderUpdateWeeklyMessageConfig.id, "week", value);
                          }
                        }}
                      >
                        {week.map((item, index) => {
                          return (
                            <GSelectOption key={item} value={index}>
                              {item}
                            </GSelectOption>
                          );
                        })}
                      </GSelect>
                    </div>
                  </div>
                  <GRadioGroup
                    disabled={!reminderUpdateWeeklyMessageConfig?.turn_on}
                    value={reminderUpdateWeeklyMessageConfig?.message_type}
                    className="radio-group-set"
                    onChange={(e) => {
                      if (reminderUpdateWeeklyMessageConfig?.id) {
                        onPatch(reminderUpdateWeeklyMessageConfig.id, "message_type", e.target.value);
                      }
                    }}
                  >
                    <GRadio value={1}>{t("In-app notifications")}</GRadio>
                    <GRadio value={2}>{t("Email notifications")}</GRadio>
                  </GRadioGroup>
                </div>
                <div className="setting-box-item-icon">
                  <GSwitch
                    defaultChecked={reminderUpdateWeeklyMessageConfig?.turn_on}
                    onChange={(checked) => {
                      if (reminderUpdateWeeklyMessageConfig?.id) {
                        onPatch(reminderUpdateWeeklyMessageConfig.id, "turn_on", checked);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="setting-box-item">
                <div className="setting-box-item-center">
                  <div className="text-title">{t("Project daily report reminder")}</div>
                  <div className="select-time-box">
                    <div className="select-time-box-item">
                      <span className="select-label">{t("remind-at")}</span>
                      <GSelect
                        virtual={false}
                        disabled={!reminderWriteDailyMessageConfig?.turn_on}
                        value={reminderWriteDailyMessageConfig?.time_point}
                        style={{ width: 90 }}
                        onChange={(value) => {
                          if (reminderWriteDailyMessageConfig?.id) {
                            onPatch(reminderWriteDailyMessageConfig.id, "time_point", value);
                          }
                        }}
                      >
                        {timeList.map((item) => {
                          return (
                            <GSelectOption key={item} value={item}>
                              {item}
                            </GSelectOption>
                          );
                        })}
                      </GSelect>
                    </div>
                    <div className="select-time-box-item">
                      <span className="select-label">{t("every working day")}</span>
                    </div>
                  </div>
                  <GRadioGroup
                    disabled={!reminderWriteDailyMessageConfig?.turn_on}
                    value={reminderWriteDailyMessageConfig?.message_type}
                    className="radio-group-set"
                    onChange={(e) => {
                      if (reminderWriteDailyMessageConfig?.id) {
                        onPatch(reminderWriteDailyMessageConfig.id, "message_type", e.target.value);
                      }
                    }}
                  >
                    <GRadio value={1}>{t("In-app notifications")}</GRadio>
                    <GRadio value={2}>{t("Email notifications")}</GRadio>
                  </GRadioGroup>
                </div>
                <div className="setting-box-item-icon">
                  <GSwitch
                    defaultChecked={reminderWriteDailyMessageConfig?.turn_on}
                    onChange={(checked) => {
                      if (reminderWriteDailyMessageConfig?.id) {
                        onPatch(reminderWriteDailyMessageConfig.id, "turn_on", checked);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="setting-box-item">
                <div className="setting-box-item-center">
                  <div className="text-title">{t("Project weekly report email (8:00 am every Monday)")}</div>
                </div>
                <div className="setting-box-item-icon">
                  <GSwitch
                    defaultChecked={weeklyMessageConfig?.turn_on}
                    onChange={(checked) => {
                      if (weeklyMessageConfig?.id) {
                        onPatch(weeklyMessageConfig?.id, "turn_on", checked);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </Spin>
      </GScrollBar>
    </div>
  );
};
