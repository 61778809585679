import React, { useState, useEffect, useMemo } from "react";
import { message } from "antd";
import { GDrawer, GDrawerProps } from "@gear/ui";
import { AccountAPI, GCity, SettingAPI, useGStores } from "@gear/business";
import { axiosWrapper } from "@/utils";
import { useTranslation } from "react-i18next";
import TimeZoneDialog from "./TimeZoneDialog";
import { observer } from "mobx-react";
import { UseStores } from "@/stores";
import { useMyWorkStation } from "@/hooks/useMyWorkStation";
import "./index.scss";

interface TimeZoneDrawerProps extends GDrawerProps {
  onCancel?: () => void;
}

export const TimeZoneDrawer: React.FC<TimeZoneDrawerProps> = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  const { userStore } = useGStores<UseStores>();
  const { myWorkStation, fetchMyWorkStation } = useMyWorkStation();
  const [currentCity, setCurrentCity] = useState<GCity>();

  /**
   * 获取用户id
   */
  const useId = useMemo(() => {
    return userStore?.user?.id ? userStore?.user?.id : "";
  }, [userStore, userStore?.user]);

  useEffect(() => {
    setCurrentCity(myWorkStation?.user?.city);
  }, [myWorkStation]);

  useEffect(() => {
    if (visible) {
      fetchMyWorkStation();
    }
  }, [visible]);

  /**
   * 请求接口更换时区
   */
  const saveChangeTimeZone = (city?: GCity) => {
    if (!city) {
      message.error(t("Please select at least one time zone or allow time zone to be set automatically"));
      return false;
    }

    axiosWrapper(
      AccountAPI.patchUser(useId, {
        city: city?.id
      })
    ).then(({ isSuccess, result: { data } }) => {
      if (isSuccess) {
        SettingAPI.postUserCity({ city_id: city?.id}).then(() => {
          SettingAPI.getCurrentCity()
            .then((res) => {
              const country = res.data?.country?.name;
              if (country) {
                res.data.t = +new Date();
                localStorage.setItem("city", JSON.stringify(res.data));
              }
            })
            .finally(() => {
              window.location.reload();
            });
        });
      }
    });
  };

  const timezoneDialogProps = {
    setCurrentCity: (city?: GCity) => {
      setCurrentCity(city);
      saveChangeTimeZone(city);
    },
    currentCity
  };
  return (
    <GDrawer width={665} visible={visible} className="header-time-zone-drawer" onClose={onCancel}>
      <div className="header-time-zone-drawer-wrapper">
        <div className="header-time-zone-drawer-title">
          <h3 className={"title"}>{t("Select your time zone")}</h3>
        </div>
        <div className={"drawer-content"}>{visible && <TimeZoneDialog {...timezoneDialogProps} />}</div>
      </div>
    </GDrawer>
  );
};

export default observer(TimeZoneDrawer);
