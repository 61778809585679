import React, { useState, useMemo } from "react";
import { message, Space } from "antd";
import { GButton, GDrawer, GForm, GFormItem, GInput, useGForm } from "@gear/ui";
import { useTranslation } from "react-i18next";
import { axiosWrapper } from "@/utils";
import { AccountAPI } from "@gear/business";

interface ChangePasswordProp {
  userEmail: string;
  visible: boolean;
  onCancel: () => void;
}

interface ResetPassword {
  email: string;
  new_password: string;
  repeat_new_password: string;
  code: string;
}

/** 初始倒计时值 */
const startCodeNum = 60;
/** 倒计时执行器 */
let timeInterval: NodeJS.Timeout | null = null;
export const ChangePassword: React.FC<ChangePasswordProp> = ({ userEmail, visible, onCancel }) => {
  const { t } = useTranslation();
  /** 弹窗的控制配置 */
  const [dialogVisible, setDialogVisible] = useState(false);
  /** 获取邮箱验证码Loading */
  const [getCodeLoad, setGetCodeLoad] = useState(false);
  /** 倒计时 */
  const [downCode, setDownCode] = useState(startCodeNum);
  /** formRef */
  const [formRef] = useGForm();
  /* 保存时的loading */
  const [saveLoad, setSaveLoad] = useState(false);
  /** 至少成功获取过一次验证码 */
  const [isGetCode, setIsGetCode] = useState(false);

  /**
   * 获取验证码
   */
  const getCodeFunction = () => {
    setGetCodeLoad(true);
    axiosWrapper(AccountAPI.getVerificationCode(userEmail, "reset_password")).then(({ isSuccess }) => {
      if (isSuccess) {
        setIsGetCode(true);
        countDown(downCode);
      }
      setGetCodeLoad(false);
    });
  };

  /** 倒计时函数 */
  const countDown = (code: number) => {
    let num = code;
    num = num - 1;
    setDownCode(num);
    timeInterval = setInterval(() => {
      if (num > 0) {
        num = num - 1;
        setDownCode(num);
      } else {
        if (timeInterval) clearInterval(timeInterval);
        timeInterval = null;
        setDownCode(startCodeNum);
      }
    }, 1000);
  };

  /** 获取验证码的按钮 */
  const getCode = (
    <GButton type="text" className="get-code" onClick={getCodeFunction} loading={getCodeLoad} disabled={downCode < startCodeNum}>
      {downCode < startCodeNum ? downCode : getCodeLoad ? t("Sending") : t("Get verification code")}
    </GButton>
  );

  /** 提交表单 */
  const savePassword = () => {
    if (isGetCode) {
      if (formRef) formRef.submit();
    } else {
      message.error(t("Please get the verification code first"));
    }
  };

  /** 请求接口提交 */
  const submit = (value: ResetPassword) => {
    setSaveLoad(true);
    axiosWrapper(AccountAPI.resetPassword(value), false).then((res) => {
      if (res.isSuccess) {
        message.success(t("Password modified successfully"));
        setIsGetCode(true);
        if (timeInterval) clearInterval(timeInterval);
        if (formRef) formRef.resetFields();
        setDownCode(startCodeNum);
        setDialogVisible(false);
        onCancel && onCancel();
      }
      setSaveLoad(false);
    });
  };
  const footer = useMemo(() => {
    return (
      <Space>
        <GButton onClick={savePassword}>{t("Save changes")}</GButton>
      </Space>
    );
  }, [isGetCode]);

  return (
    <>
      {/*<div*/}
      {/*  className="avatar-dropdown-list list-change-password"*/}
      {/*  onClick={() => {*/}
      {/*    setDialogVisible(true)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {t('修改密码')}*/}
      {/*</div>*/}
      <GDrawer
        className="avatar-dropdown-list-dialog change-password-modal"
        width={665}
        visible={visible}
        title={t("Change Password")}
        onClose={() => {
          onCancel && onCancel();
        }}
        footer={footer}
      >
        <div className="list-change-password-content">
          <GForm
            form={formRef}
            onFinish={submit}
            layout="vertical"
            initialValues={{
              email: userEmail
            }}
          >
            <GFormItem className="get-code-item" label={t("Email address")} name="email" rules={[{ required: true, message: t("Please enter the email") }]}>
              <GInput disabled={true} placeholder={t("Please enter the email")} bordered={false} addonAfter={getCode} />
            </GFormItem>
            <GFormItem
              label={t("Verification code")}
              name="code"
              rules={[
                {
                  required: true,
                  min: 6,
                  max: 6,
                  message: t("Please enter a 6-digit verification code")
                }
              ]}
            >
              <GInput placeholder={t("Please enter a 6-digit verification code")} type="number" maxLength={6} />
            </GFormItem>
            <GFormItem
              label={t("New password")}
              name="new_password"
              rules={[
                {
                  required: true,
                  min: 8,
                  max: 16,
                  message: t("Please enter a 8-16 digit password")
                }
              ]}
            >
              <GInput placeholder={t("Please enter a 8-16 digit password")} maxLength={16} minLength={8} type="password" />
            </GFormItem>
            <GFormItem
              label={t("Please re-enter the password")}
              name="repeat_new_password"
              rules={[
                {
                  required: true,
                  min: 8,
                  max: 16,
                  message: t("Please enter a 8-16 digit password")
                }
              ]}
            >
              <GInput placeholder={t("Please re-enter the password")} type="password" maxLength={16} minLength={8} />
            </GFormItem>
          </GForm>
        </div>
      </GDrawer>
    </>
  );
};

export default ChangePassword;
