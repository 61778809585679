import { RoleGroup, AccountAPI } from "@gear/business";
import { makeAutoObservable } from "mobx";

// 配置模块store

export default class PermissionStore {
  groups: RoleGroup[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchData() {
    AccountAPI.roleGroups().then((response) => {
      if (response.status === 200) {
        this.groups = response.data.results;
      }
    });
  }
}
