import HotIcon from "./assets/hot-icon.svg";
import { allFeatures } from "@/utils";
import completeIcon from "./assets/complete-icon.svg";
import errorIcon from "./assets/error-icon.svg";
import React, { FC } from "react";
import { GButton, GModal, translate } from "@gear/ui";
import "./index.scss";
import { ReactComponent as IconProductMetawork } from "@/components/Header/assets/iocn_product_metawork.svg";
import SubscriptionApi from "@/apis/subscriptionApi";

interface CompareAllFeaturesModalProps {
  visible?: boolean;
  onCancel?: Function;
}
export const CompareAllFeaturesModal: FC<CompareAllFeaturesModalProps> = ({ visible, onCancel }) => {
  const selectPlan = (subscriptionType: string) => {
    SubscriptionApi.createSubscriptionPaymentLink(subscriptionType).then((res) => {
      if (res.data["link"]) {
        window.location = res.data["link"];
      }
    });
  };
  const getAQuote = () => {
    Calendly.initPopupWidget({ url: "https://calendly.com/talentorg/metawork-enterprise-discovery" });
  };
  return (
    <GModal
      width={"100vw"}
      visible={visible}
      footer={null}
      className="all-features-box-modal"
      onCancel={() => {
        onCancel?.();
      }}
    >
      <div className="all-features-box-modal-header">
        <IconProductMetawork style={{ marginLeft: "23px", marginTop: "15px" }} />
      </div>
      <div className="all-features-box-body">
        <div className="all-features-content ">
          <div className="g-container">
            <div className="all-features-content-header-box">
              <div className="all-features-title">{translate("Compare all features")}</div>
              <div className="all-features-content-header">
                <div className="all-features-content-header-left"></div>
                <div className="all-features-content-header-right">
                  <div className="header-item">
                    <div className={"header-item-title"}>{translate("STARTER_PLAN")}</div>
                    {/*<div className={"header-item-dec"}>{translate("FREE")}</div>*/}
                    {/*<GButton type={"default"} onClick={() => selectPlan("starter")}>*/}
                    {/*  {translate("Select Starter")}*/}
                    {/*</GButton>*/}
                  </div>
                  <div className="header-item">
                    <div className={"header-item-title"}>{translate("GROWTH_PLAN")}</div>
                    {/*<div className={"header-item-dec"}>{translate("$49/mo")}</div>*/}
                    {/*<GButton type={"default"} onClick={() => selectPlan("growth")}>*/}
                    {/*  {translate("Select Growth")}*/}
                    {/*</GButton>*/}
                  </div>
                  <div className="header-item most-popular">
                    <div className={"header-item-title"}>
                      <img src={HotIcon} alt="" />
                      {translate("PRO_PLAN")}
                    </div>
                    {/*<div className={"header-item-dec"}>{translate("$99/mo")}</div>*/}
                    {/*<GButton type={"primary"} onClick={() => selectPlan("pro")}>*/}
                    {/*  {translate("Select Pro")}*/}
                    {/*</GButton>*/}
                  </div>
                  <div className="header-item">
                    <div className={"header-item-title"}>{translate("ENTERPRISE_PLAN")}</div>
                    {/*<div className={"header-item-dec"}>{translate("Custom pricing")}</div>*/}
                    {/*<GButton type={"default"} onClick={() => getAQuote()}>*/}
                    {/*  {translate("Get a quote")}*/}
                    {/*</GButton>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="all-features-content-body">
          {allFeatures.map((feature, index) => {
            return (
              <div className="all-features-item-content">
                <div className={"all-features-item-content-title g-container"}>
                  <span>{translate(feature.label)}</span>
                </div>
                {feature.data.map((item) => {
                  return (
                    <div className="all-features-item-box">
                      <div className="g-container all-features-item-box-center">
                        <div className="all-features-item-box-left">{translate(item.label)}</div>
                        <div className={"all-features-item-box-right"}>
                          <div className={"all-features-item-box-right-item"}>
                            {item.starter ? translate(item.starter) : item.allow_starter ? <img src={completeIcon} alt="" /> : <img src={errorIcon} alt="" />}
                          </div>
                          <div className={"all-features-item-box-right-item"}>
                            {item.growth ? translate(item.growth) : item.allow_growth ? <img src={completeIcon} alt="" /> : <img src={errorIcon} alt="" />}
                          </div>
                          <div className={"all-features-item-box-right-item"}>
                            {item.pro ? translate(item.pro) : item.allow_pro ? <img src={completeIcon} alt="" /> : <img src={errorIcon} alt="" />}
                          </div>
                          <div className={"all-features-item-box-right-item"}>
                            {item.enterprise ? (
                              translate(item.enterprise)
                            ) : item.allow_enterprise ? (
                              <img src={completeIcon} alt="" />
                            ) : (
                              <img src={errorIcon} alt="" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </GModal>
  );
};
