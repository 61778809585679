export enum TYPES_OF_FETCHING {
  INIT = "init",
  MORE = "more"
}

export const DEFAULT_PAGE_SIZE = 5;
export const PAGE_SIZE_WITHOUT_PAGINATION = 1000;

export const DEFAULT_SPLIT_SYMBOL = ",";

export const DEFAULT_BUSY_CONFIG = [2, 6, 8];
export const VIRTUAL_OFFICE_WS_STATUS_FILTER_KEY = "virtual-office-ws-status-filter-key";
export const VIRTUAL_OFFICE_WS_STATUS_SWITCH_KEY = "virtual-office-ws-status-switch-key";

export enum AppUsageProductivityType {
  unknown = 0,
  core_work = 1,
  non_core_work = 2,
  non_work = 3
}

export const allFeatures = [
  {
    label: "Virtual office",
    data: [
      {
        label: "Automatic presence detection (active/away/in meeting/offline)",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Location and time zone display",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Voice messages",
        starter: "1 year",
        growth: "1 year",
        pro: "1 year",
        enterprise: "Unlimited",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Calendar sharing (Support for integration)",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Todo sharing (Support for integration)",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Contacts settings",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Seating assignment",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Message board",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Multi-language and integrated translation",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Global timezone support",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      }
    ]
  },
  {
    label: "Communication",
    data: [
      {
        label: "Real-time intercom",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Answering machine",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Quick replies",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Video messages",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: false,
        allow_growth: false,
        allow_pro: false,
        allow_enterprise: true
      },
      {
        label: "Zoom or Teams integration",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: false,
        allow_growth: false,
        allow_pro: false,
        allow_enterprise: true
      }
    ]
  },
  {
    label: "Tracking",
    data: [
      {
        label: "Attendance tracking",
        starter: "30 days",
        growth: "60 days",
        pro: "60 days",
        enterprise: "Unlimited",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Screen/mouse/keyboard activity detection",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Meeting detection",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Apps usage tracking",
        starter: "30 days",
        growth: "60 days",
        pro: "60 days",
        enterprise: "Unlimited",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Sites usage tracking",
        starter: "30 days",
        growth: "60 days",
        pro: "60 days",
        enterprise: "Unlimited",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Screenshots",
        starter: "30 days",
        growth: "60 days",
        pro: "60 days",
        enterprise: "Unlimited",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Suspicious activity detection",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Task tracking",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      }
    ]
  },
  {
    label: "Productivity insights",
    data: [
      {
        label: "Work focus analysis",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Work scheduling analysis",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Productivity comparison",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Abnormal work status alert",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Data and report export",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Organization/Group/Individual productivity analysis",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      }
    ]
  },
  {
    label: "Daily check-ins",
    data: [
      {
        label: "AI-assisted daily check-ins",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Cross-review of daily check-ins",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Daily check-ins subscription",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Daily check-in comments and replies",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Weekly reports",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      }
    ]
  },
  {
    label: "Task/Project management",
    data: [
      {
        label: "Task kanban",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Task Gantt chart",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Task sharing",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Risk alert",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Project burndown",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Project timesheet",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Project daily reports",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Project milestone tracking",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      }
    ]
  },
  {
    label: "User management",
    data: [
      {
        label: "Group management",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "User management",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Role management",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      }
    ]
  },
  {
    label: "Notifications",
    data: [
      {
        label: "Comments or mentions",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Suspicious activity",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Daily check-ins",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Weekly reports",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Work login reminder",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Start of work notification",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "In-app notifications",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Email notifications",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Third-party integration reminder",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      }
    ]
  },
  {
    label: "Customer service",
    data: [
      {
        label: "Onboarding planning and support",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Email support",
        starter: "Limited support",
        growth: "24 hours",
        pro: "24 hours",
        enterprise: "12 hours",
        allow_starter: true,
        allow_growth: true,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Chat support",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: false,
        allow_growth: false,
        allow_pro: true,
        allow_enterprise: true
      },
      {
        label: "Dedicated account manager",
        starter: "",
        growth: "",
        pro: "",
        enterprise: "",
        allow_starter: false,
        allow_growth: false,
        allow_pro: false,
        allow_enterprise: true
      }
    ]
  }
];
