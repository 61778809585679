import { useEffect } from "react";

export function useAudioServerStateSync() {
  useEffect(() => {
    window.audioServer?.setObserver({
      recordingObserver: (isRecording: boolean) => {
        console.log("useAudioServerStateSync recordingObserver isRecording=", isRecording);
        handleRecordEvent();
      }
    });

    return () => {
      window.audioServer?.setObserver(undefined);
    };
  }, []);

  /**
   * 处理录音事件
   */
  const handleRecordEvent = () => {
    if (window.audioServer?.isPlaying()) {
      // 正在播放音频则停止播放
      window.audioServer?.pauseAudio();
    }
  };
}
