import { UseStores } from "@/stores";
import { useMyWorkStation } from "@/hooks/useMyWorkStation";
import { Overlay, OverlayOption } from "@/templates/HomepageTemplate/components/Overlay";
import PersonalSettings from "@/templates/HomepageTemplate/components/PersonalSettings";
import TimeZoneDrawer from "@/templates/HomepageTemplate/components/TimeZoneDrawer";
import { getSSOHost } from "@/utils";
import { AccountAPI, GWorkStationAvatar, GWorkStationStatusSelector, WorkStationClient, ssoLogout, useGAuth, useGStores } from "@gear/business";
import { GAvatar, switchWithLanguage, translate } from "@gear/ui";
import { Dropdown, message } from "antd";
import { observer } from "mobx-react";
import { FC, useMemo, useState } from "react";
import { useProduct } from "@/hooks/useProduct";

export const HeaderAvatar: FC = observer(() => {
  const { checkPermission } = useGAuth();
  const { configStore, userStore } = useGStores<UseStores>();
  const { myWorkStation, myWorkStationStatus, updateMyWorkStationStatus, updateWayOfWorking } = useMyWorkStation();
  const [personalSettingsVisible, setPersonalSettingsVisible] = useState(false);
  const [showHeaderDropdown, setShowHeaderDropdown] = useState(false);
  const { openedMetawork } = useProduct();

  const currentCity = useMemo(() => {
    return myWorkStation?.user?.city;
  }, [myWorkStation]);
  /**
   * 获取用户时区
   */
  const userTimeZone = useMemo(() => {
    return currentCity?.timezone;
  }, [currentCity]);

  const offset = useMemo(() => {
    const offset = userTimeZone?.offset;
    return offset !== undefined ? ` (UTC${offset > 0 ? "+" : ""}${offset})` : "";
  }, [userTimeZone]);

  const timeZoneView = useMemo(() => {
    return (
      <div
        className="avatar-dropdown-item_title list-time-zone"
        onClick={() => {
          configStore?.updateTimeZoneDialogVisible(true);
        }}
      >
        <div>{translate("Time zone")}</div>
        {currentCity && (
          <div className="avatar-dropdown-item_secondary">
            {userTimeZone?.standard_time}
            {offset}
          </div>
        )}
      </div>
    );
  }, [userTimeZone]);

  const termsAndDataPolicyView = useMemo(() => {
    return (
      <>
        <div className="avatar-dropdown-list-no-right">
          <a href={translate("TermsUrl")} target="_blank">
            {translate("Terms")}
          </a>{" "}
          {translate("and")}{" "}
          <a href={translate("PPUrl")} target="_blank">
            {translate("Privacy Policy")}
          </a>
        </div>
      </>
    );
  }, []);

  /**
   * 配置头像下的目录
   */
  const overlayOptionsConfig: OverlayOption[] = useMemo(() => {
    return [
      ...[
        {
          label: translate("Status"),
          secondaryView: (
            <GWorkStationStatusSelector
              height={switchWithLanguage({
                en: 430,
                zh: 405
              })}
              status={myWorkStation?.status}
              wayOfWorking={myWorkStation?.work_method}
              statusDuration={myWorkStation?.status_duration}
              statusEndTime={myWorkStation?.status_end_time}
              onChangeStatus={(status, duration) => {
                if (myWorkStation?.clients?.includes(WorkStationClient.desktop)) {
                  // app端运行着的话，不允许在web端修改工位状态
                  message.error(translate("Please operate on the client"));
                  return;
                }
                const params = {
                  status
                };
                if (duration) {
                  Object.assign(params, {
                    status_duration: duration
                  });
                }
                updateMyWorkStationStatus(params);
              }}
              onChangeWayOfWorking={(way) => {
                updateWayOfWorking(way);
              }}
            />
          ),
          disabled: !openedMetawork
        }
      ],
      ...[
        {
          label: translate("Personal account"),
          callback: () => {
            setPersonalSettingsVisible(true);
          }
        },
        {
          label: translate("Time zone"),
          render: openedMetawork ? timeZoneView : null,
          disabled: !openedMetawork
        }
      ],
      {
        label: "Terms and Private Policy",
        divider: true,
        render: termsAndDataPolicyView
      },
      ...[
        {
          label: translate("Log out"),
          divider: true,
          callback: () => {
            logout();
          }
        }
      ]
    ];
  }, [myWorkStation, timeZoneView, termsAndDataPolicyView]);

  /**
   * 配置头像的列表
   */
  const overlayOptions = useMemo(() => {
    const result: {
      label: string;
      path?: string;
      secondary?: string;
    }[] = [];

    overlayOptionsConfig.forEach((list) => {
      if (list.roles) {
        if (checkPermission(list.roles)) {
          const { label, path, secondary } = list;
          result.push({
            label,
            path,
            secondary
          });
        }
      } else {
        result.push(list);
      }
    });

    return result;
  }, [overlayOptionsConfig]);

  /**
   * 退出登录
   */
  const logout = () => {
    AccountAPI.logout()
      .then(() => {
        window.localStorage.removeItem("token");
        ssoLogout(process.env.PUBLIC_URL, getSSOHost() || "", process.env.REACT_APP_APP_ID || "", window.location.origin);
      })
      .catch((error) => {
        message.error(error?.response?.data?.detail);
      });
  };

  return (
    <>
      <Dropdown
        visible={showHeaderDropdown}
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName="right-container-dropdown"
        overlay={<Overlay visible={showHeaderDropdown} options={overlayOptions} onClickItem={() => setShowHeaderDropdown(false)} />}
        onVisibleChange={(visible) => setShowHeaderDropdown(visible)}
      >
        {myWorkStation ? (
          <GWorkStationAvatar
            size={32}
            badgeIconSize={12}
            avatarBorderWidth={1.5}
            src={userStore.user?.avatar}
            status={myWorkStationStatus}
            wayOfWorking={myWorkStation?.work_method}
            wayIconSize={17}
            clients={myWorkStation?.clients}
          >
            {userStore.user?.full_name}
          </GWorkStationAvatar>
        ) : (
          <GAvatar size={32} src={userStore.user?.avatar}>
            {userStore.user?.full_name}
          </GAvatar>
        )}
      </Dropdown>
      {/* 个人设置 */}
      <PersonalSettings
        visible={personalSettingsVisible}
        onCancel={() => {
          setPersonalSettingsVisible(false);
        }}
      />
      {/** 设置时区 */}
      <TimeZoneDrawer
        visible={configStore.timeZoneDialogVisible}
        onCancel={() => {
          configStore.updateTimeZoneDialogVisible(false);
        }}
      />
    </>
  );
});
