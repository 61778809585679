import { CSSProperties, FC, forwardRef, useImperativeHandle, useState } from "react";
import { GTooltip, GPopover, translate } from "@gear/ui";
import { ContactsView, useUnreadMsgAndNotice } from "@gear/business";
import { ReactComponent as TooltipMessageIcon } from "@/assets/icon/message_icon.svg";
import { observer } from "mobx-react";
import "./index.scss";

interface VoiceMessagesProps {
  style?: CSSProperties;
  disabled?: boolean;
}

export interface VoiceMessagesHandler {
  showPopover: () => void;
}

export const VoiceMessagesTooltip = observer(
  forwardRef<VoiceMessagesHandler, VoiceMessagesProps>(({ disabled, style }, ref) => {
    const { numberOfUnreadMessages } = useUnreadMsgAndNotice();

    const [messagePopoverVisible, setMessagePopoverVisible] = useState(false);

    useImperativeHandle(ref, () => {
      return {
        showPopover: () => {
          setMessagePopoverVisible(true);
        }
      };
    });

    /**
     * 格式化数字，0：返回空字符串，大于99返回99+
     * @param number
     */
    const formatNumber = (number: number) => {
      return number === 0 ? "" : number <= 99 ? number + "" : "99+";
    };
    if (disabled) {
      return (
        <div
          className={`voice-message-btn`}
          style={{
            ...style,
            cursor: "no-drop"
          }}
        >
          <TooltipMessageIcon />
          {!!numberOfUnreadMessages && <span className="unread-message-num">{formatNumber(numberOfUnreadMessages)}</span>}
        </div>
      );
    }
    return (
      <GPopover
        overlayClassName={"voice-message-dropdown"}
        placement="bottomRight"
        arrowPointAtCenter={true}
        trigger={["click"]}
        content={
          <ContactsView
            emojiSelectorPorps={{
              placement: "topRight"
            }}
          />
        }
        visible={messagePopoverVisible}
        onVisibleChange={(visible: boolean) => {
          setMessagePopoverVisible(visible);
        }}
      >
        <GTooltip title={translate("Chat")}>
          <div className={`voice-message-btn`} style={style}>
            <TooltipMessageIcon />
            {!!numberOfUnreadMessages && <span className="unread-message-num">{formatNumber(numberOfUnreadMessages)}</span>}
          </div>
        </GTooltip>
      </GPopover>
    );
  })
);
