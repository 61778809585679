import { Notification, NotificationSystemNoticeExpand } from "@/apis/types";
import { svgIcons } from "@/assets/svg";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import EmptyIcon from "@/assets/icon/icon_notification.png";
import { NotificationApi } from "@/apis/notificationApi";
import { axiosWrapper } from "@/utils";
import moment, { MomentInput } from "moment-timezone";
import storage from "storejs";
import { useHistory } from "react-router";
import { GButton, switchWithLanguage, translate, useGTranslation } from "@gear/ui";
import { useGetRoles } from "@/hooks";
import { EventModule, EventName, GUseStores, useGStores, GMessageCategoryType } from "@gear/business";

interface INotificationsBoxProps {
  list: {
    total: number;
    unread: Notification[];
    last: Notification[];
  };
  onRead?: (id: number) => void;
  onClose?: () => void;
}
export const NotificationsBox: FC<INotificationsBoxProps> = ({ list, onClose, onRead }) => {
  const { t } = useTranslation();
  const { language } = useGTranslation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { observerStore } = useGStores<GUseStores>();
  const { enterpriseStore } = useGStores();

  const { hiringRoles, projectManegementRoles } = useGetRoles();

  const readAll = () => {
    setLoading(true);
    axiosWrapper(NotificationApi.ReadAllNotification()).then(() => {
      setLoading(false);
    });
  };

  const timeText = useCallback((date: MomentInput) => {
    const isToday = moment(date).isSame(new Date(), "day");
    return isToday ? t("Today") : moment(date).format(switchWithLanguage({ en: "MMM D, HH:mm", zh: "MM/DD HH:mm" }));
  }, []);

  const itemDomContent = (expand: NotificationSystemNoticeExpand & { id: number }) => {
    return (
      // TODO multi-language obsolete code
      <span className="notifications-item-center-text-span"></span>
    );
  };

  const itemDom = useCallback((item: Notification) => {
    return (
      <div
        onClick={async () => {
          if (
            [
              GMessageCategoryType.MentionsAndCommentsRemind,
              GMessageCategoryType.SystemReminderSubmitDaily,
              GMessageCategoryType.UserRemindSubmitDaily
            ].includes(item.system_notice?.action || 0) &&
            item.system_notice?.expand
          ) {
            onClose?.();
            if (!item.read_at) onRead?.(item.id);
            const eId = Number(storage.get("current-enterprise-id"));
            const thisEid = item.system_notice.expand.enterprise || item.system_notice.expand.enterprise_id;
            if (
              item!.system_notice!.action === GMessageCategoryType.MentionsAndCommentsRemind &&
              ["sprint", "task"].indexOf(item.system_notice?.expand?.ot || "undefined") > -1
            ) {
              window.location.href = `/middle-page?oid=${item.system_notice?.expand?.oid}&ot=${item.system_notice?.expand?.ot}${
                item.system_notice?.expand?.cid ? "&cid=" + item.system_notice?.expand?.cid : ""
              }&enterprise_id=${thisEid}`;
            } else {
              let nextUrl = "";
              if (item.system_notice?.action === GMessageCategoryType.SystemReminderSubmitDaily) {
                nextUrl = `/daily-reports/my?date=${moment(new Date()).format("YYYY-MM-DD")}`;
              } else if (item.system_notice?.action === GMessageCategoryType.UserRemindSubmitDaily) {
                nextUrl = `/daily-reports/my?date=${moment(item.system_notice?.expand?.date).format("YYYY-MM-DD")}`;
              } else {
                //是日报消息
                const params: { [key: string]: any } = {};
                if (item.system_notice.expand.date) {
                  params["date"] = item.system_notice.expand.date;
                }
                if (item.system_notice.expand.user) {
                  params["user"] = item.system_notice.expand.user;
                }
                if (item.system_notice.expand.work_item) {
                  params["work_item"] = item.system_notice.expand.work_item;
                }
                if (item.system_notice.expand.cid) {
                  params["cid"] = item.system_notice.expand.cid;
                }
                if (item.system_notice?.expand?.ot === "entdailywork") {
                  if (`${thisEid}` !== `${eId}`) {
                    enterpriseStore?.updateCurrentEnterprise(thisEid);
                  }
                  observerStore.sendEvent(EventModule.daily, EventName.dailyMessageJump, params);
                  return;
                }
              }
              if (eId === thisEid) {
                window.location.href = nextUrl;
              } else {
                if (Boolean(Number(thisEid))) localStorage.setItem("current-enterprise-id", thisEid! + "");
                window.location.href = nextUrl;
              }
            }
          }
        }}
        key={item.id}
        className={
          "notifications-item " + ([1, 5, 15, 30].includes(item.system_notice?.action || 0) && item.system_notice?.expand ? "notifications-item-hiring" : "")
        }
      >
        <div className="notifications-item-center">
          <div className="notifications-item-center-text">
            {item.system_notice?.action === 1 && item.system_notice?.expand ? (
              itemDomContent(Object.assign({ id: item.id }, item.system_notice.expand))
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: item.system_notice?.content || ""
                }}
              />
            )}
          </div>
          <div className="notifications-item-center-time">{timeText(item.created_at)}</div>
        </div>
        <div className="notifications-item-icon">
          {!item.read_at && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onRead?.(item.id);
              }}
              className="icon-check"
            >
              {svgIcons.determine}
            </div>
          )}
        </div>
      </div>
    );
  }, []);

  return (
    <div className="notifications-box">
      {list.total > 0 ? (
        <>
          {list.unread.length > 0 && (
            <div className="notifications-box-tab">
              <div className="notifications-box-tab-item">
                {t("KEY_Unread")} ({list.unread.length})
              </div>
              <GButton type="link" loading={loading} className="notifications-box-tab-item-handle" onClick={readAll}>
                {t("Mark all as read")}
              </GButton>
            </div>
          )}
          <div className="notifications-box-center">
            {list.unread.length > 0 && list.unread.map((item) => itemDom(item))}
            {list.last.length > 0 && (
              <>
                <div className="notifications-item-text">{t("Last 30 days")}</div>
                <div className="notifications-last">{list.last.map((item) => itemDom(item))}</div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="notifications-empty">
          <div>{t("No notifications for the last 30 days")}</div>
          <img src={EmptyIcon} />
        </div>
      )}
    </div>
  );
};
