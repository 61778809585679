import { Moment } from "moment-timezone";
import { GBaseGanttConst, getMaxWidth } from "@gear/ui";
import { DefaultGroups, GGanttRiskStatus, GGanttTaskStatus, GProjectStatus, GSprint, GTask, GTeam, GUser } from "@gear/business";

const siderBarWidth = 418;
const userRowHeight = 40;
const taskRowHeight = 40;
const calendarViewWidth = getMaxWidth() - GBaseGanttConst.homeMenuWidth + (GBaseGanttConst.paddingTab + GBaseGanttConst.paddingContainer) * 2 - siderBarWidth; // 日历视窗宽度
const calendarViewX = GBaseGanttConst.homeMenuWidth + GBaseGanttConst.paddingTab + GBaseGanttConst.paddingContainer + siderBarWidth;
const visibleDateCount = Math.ceil(calendarViewWidth / GBaseGanttConst.colWidth) + 1; // 日历视窗展示的可见日期数
const expandRowKey = "tasks_gantt_expand_row_keys";
export const UNASSIGNEE_ID = -1;
export const TaskGanttConst = {
  siderBarWidth,
  headerHeight: GBaseGanttConst.headerHeight,
  userRowHeight,
  taskRowHeight,
  colWidth: GBaseGanttConst.colWidth,
  calendarViewWidth,
  calendarViewX,
  visibleDateCount,
  loadDateCount: GBaseGanttConst.loadDateCount,
  defTodayIndex: GBaseGanttConst.defTodayIndex,
  loadEdgeThreshold: GBaseGanttConst.loadEdgeThreshold,
  expandRowKey
};

export interface TaskGanttResponse {
  data: {
    team: GTeam;
    sprint: GSprint;
    tasks: GTask[];
  }[];
  user: GUser;
}

export type TaskGanttFilterType = {
  taskStatus: (GGanttTaskStatus | string)[];
  range: [Moment?, Moment?];
  assignee: string[];
  risk: (GGanttRiskStatus | string)[];
  tag: number[];
  group?: number;
  team_status?: string[];
  sprint_status?: string[];
  task_type?: string[];
};

export const defaultFilterParams: TaskGanttFilterType = {
  taskStatus: [GGanttTaskStatus.todo, GGanttTaskStatus.ongoing],
  range: [],
  assignee: [],
  risk: [],
  tag: [],
  group: DefaultGroups,
  team_status: [GProjectStatus?.active],
  sprint_status: [],
  task_type: []
};
