import { DownloadAppGuide } from "@/components/DownloadAppGuide";
import { GModal, GModalProps } from "@gear/ui";
import { FC } from "react";
import "./index.scss";

interface AppDownloadModalProps extends GModalProps {
  onDownloaded?: () => void;
}

export const AppDownloadModal: FC<AppDownloadModalProps> = ({ onDownloaded, ...modalProps }) => {
  return (
    <GModal className="app-download-modal" width={492} title={null} footer={null} closable={false} maskClosable={true} {...modalProps}>
      <DownloadAppGuide showTitle={false} onDownloaded={onDownloaded} />
    </GModal>
  );
};
