import { LanguageInterface } from "@gear/business";
import { getSearchQueryAsProp, LanguageAbridgeType, LanguageStandardType } from "@gear/ui";
import { getFullLangName, getShortLangName } from "./util";
/**
 * 返回当前Strorage中的语言配置
 */
export function getLanguageWithStrorage(): LanguageStandardType | null {
  const result = localStorage.getItem("language");
  // TODO why LanguageStandardType ???
  if (!result) return null;

  if (result === "en") {
    return "en-us";
  } else if (result === "zh") {
    return "zh-cn";
  } else {
    return result as LanguageStandardType;
  }
}

/**
 * 获取语言
 * 优先从地址中查询language 参数，再从i18n中获取，如果没有则从域名中获取，默认值 en
 * @param  type standard | abridge
 * @returns 'en-us' | 'zh-cn' | 'zh' | 'en'
 */
export function getLanguage(): LanguageStandardType;
export function getLanguage<T extends keyof LanguageInterface>(type: T): LanguageInterface[T];
export function getLanguage<T extends keyof LanguageInterface>(type?: T): LanguageStandardType | LanguageAbridgeType {
  const t = type || "standard";
  const searchProps = getSearchQueryAsProp("language");
  const searchPropsLan = !searchProps ? null : getFullLangName(searchProps);

  let systemLanguage = null;
  if (navigator.language) {
    systemLanguage = getFullLangName(navigator.language);
  }
  const result = (searchPropsLan || getLanguageWithStrorage() || systemLanguage) as LanguageStandardType;
  return t === "standard" ? result : (getShortLangName(result) as LanguageStandardType);
}
