import { GProjectStatus, ProjectApi, TeamStatus } from "@gear/business";

import { makeAutoObservable, toJS } from "mobx";
import { EventModule, EventName, GGanttStoreHandler, GGanttTreeRecord, GTask, observerStoreInstance } from "@gear/business";
import { defaultFilterParams, TaskGanttConst, TaskGanttFilterType } from "../common/task_gantt_view_types";
import { preHandleTaskGanttTreeRecord, syncSource } from "../utils/util";
import { cloneDeep } from "lodash";

export interface LoadCallback {
  key: string;
  onLoad: () => void;
}
// 任务甘特图store

class TaskGanttStore implements GGanttStoreHandler {
  filterParams: TaskGanttFilterType = defaultFilterParams;
  allRowKeys: string[] = [];
  expandRowKeys?: string[] = [];
  treeDataSource: GGanttTreeRecord[] = []; // 转化后的适配甘特图的数据
  originTreeDataSource: any;
  onLoadCallbackList: LoadCallback[] = [];

  constructor() {
    makeAutoObservable(this);
    const keys = localStorage.getItem(TaskGanttConst.expandRowKey)?.split(",") || [];
    this.updateExpandRowKeys(keys);
    this.initObserver();
  }

  initObserver = () => {
    observerStoreInstance.addObserver({
      module: EventModule.gantt,
      eventName: EventName.deleteTask,
      key: "taskGanttStore",
      callback: (id: number) => {
        if (window.location?.pathname?.includes?.("/tasks") || window.location?.pathname?.includes?.("/profile/task")) {
          // 正在企业甘特图页面
          // 重新拉取下甘特图数据
          this.fetchTaskGanttData();
        }
      }
    });
    observerStoreInstance.addObserver({
      module: EventModule.gantt,
      eventName: EventName.updateTask,
      key: "taskGanttStore",
      callback: (task: GTask) => {
        if (window.location?.pathname?.includes?.("/tasks") || window.location?.pathname?.includes?.("/profile/task")) {
          // 正在企业甘特图页面
          // 重新拉取下甘特图数据
          this.fetchTaskGanttData();
        }
      }
    });
    observerStoreInstance.addObserver({
      module: EventModule.gantt,
      eventName: EventName.createTask,
      key: "taskGanttStore",
      callback: (task: GTask) => {
        if (window.location?.pathname?.includes?.("/tasks") || window.location?.pathname?.includes?.("/profile/task")) {
          // 正在企业甘特图页面
          // 重新拉取下甘特图数据
          this.fetchTaskGanttData();
        }
      }
    });
  };

  updateAllRowKeys = (keys: string[]) => {
    this.allRowKeys = keys;
  };

  updateExpandRowKeys = (keys: string[]) => {
    this.expandRowKeys = keys;
    if (this.expandRowKeys.length > 0) {
      localStorage.setItem(TaskGanttConst.expandRowKey, this.expandRowKeys.join());
    } else {
      localStorage.removeItem(TaskGanttConst.expandRowKey);
    }
    observerStoreInstance.sendEvent(EventModule.gantt, EventName.collapseTask);
  };

  updateFilterParams = (params: TaskGanttFilterType) => {
    this.filterParams = params;
  };

  updateTreeDataSource = (data: GGanttTreeRecord[]) => {
    syncSource(data);
    this.treeDataSource = data;
  };

  updateCallbackList = (list: LoadCallback[]) => {
    this.onLoadCallbackList = list;
  };

  fetchGanttData = () => {
    this.fetchTaskGanttData();
  };

  // 获取甘特图数据
  fetchTaskGanttData = () => {
    // let period: any[] = [];
    let filterParams = cloneDeep(this.filterParams);

    // if (filterParams.range?.length > 1) {
    //   let start_date = filterParams.range[0]?.valueOf().toString();
    //   let end_date = filterParams.range[1]?.add(1, "days").subtract(1, "seconds").valueOf().toString();
    //   period = (start_date && end_date && [start_date, end_date]) || [];
    // }
    let team_status: any[] = [];
    if (filterParams.team_status) {
      if (filterParams.team_status?.includes(GProjectStatus.active)) {
        team_status.push(TeamStatus.Pending);
        team_status.push(TeamStatus.Ongoing);
      }
      if (filterParams.team_status?.includes(GProjectStatus.archived)) {
        team_status.push(TeamStatus.Terminated);
      }
    }
    const parameters = {
      // estimated_period: period.length ? period.join() : undefined,
      assignee: this.filterParams.assignee?.join() || undefined,
      risk: this.filterParams.risk?.join() || undefined,
      team_status: team_status?.length ? team_status.join() : undefined,
      sprint_status: this.filterParams?.sprint_status?.join() || undefined,
      status: this.filterParams?.taskStatus?.join() || undefined,
      task_type: this.filterParams?.task_type?.join() || undefined
    };
    ProjectApi.getTaskGanttPerson(parameters).then((response) => {
      let data: any = response.data || [];
      this.originTreeDataSource = data;
      this.fetchGanttDataComplete();
      const records = preHandleTaskGanttTreeRecord(data);
      this.updateTreeDataSource(records);
      this.updateAllRowKeys(records.map((item) => item.key!));
    });
  };
  updateOriginTreeDataSource = (data?: []) => {
    this.originTreeDataSource = data;
  };

  fetchGanttDataComplete = () => {
    this.onLoadCallbackList.map((item) => {
      item.onLoad();
    });
  };
  /**
   * 折叠展开sprint/section等
   */
  recordExpandChange = (key: string, isExpand: boolean) => {
    const _expandRowKeys = [...(this.expandRowKeys || [])];
    const index = _expandRowKeys.indexOf(key);

    if (isExpand) {
      // 展开
      if (index === -1) {
        _expandRowKeys.push(key);
      }
    } else {
      // 折叠
      if (index > -1) {
        _expandRowKeys.splice(index, 1);
      }
    }
    this.updateExpandRowKeys(_expandRowKeys);
  };

  /**
   * 设置过滤参数
   * @param partialFilters
   */
  setFilters = (partialFilters: Partial<TaskGanttFilterType>) => {
    const newFilterParams = Object.assign({}, this.filterParams, partialFilters);
    this.updateFilterParams(newFilterParams);
  };

  /**
   * 重置过滤参数
   */
  resetFilters = () => {
    this.updateFilterParams(defaultFilterParams);
  };

  cleanGanttData = () => {
    this.updateTreeDataSource([]);
  };
  addLoadCallback = (callback: LoadCallback) => {
    const _list = [...this.onLoadCallbackList];
    const index = _list.findIndex((item) => item.key === callback.key);
    if (index === -1) {
      _list.push(callback);
    }
    this.updateCallbackList(_list);
  };

  removeLoadCallback = (key: string) => {
    const _list = [...this.onLoadCallbackList];
    const index = _list.findIndex((item) => item.key === key);
    if (index > -1) {
      _list.splice(index, 1);
    }
    this.updateCallbackList(_list);
  };
}

export default TaskGanttStore;
