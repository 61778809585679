import { UseStores } from "@/stores";
import { useGStores, WorkStation } from "@gear/business";
import { cloneDeep, intersection } from "lodash";
import { toJS } from "mobx";
import { useMemo } from "react";

export function useVirtualOffice() {
  const { virtualOfficeStore, userStore } = useGStores<UseStores>();

  const { allWorkStations, filter, floor, mode, openSettings, operateWorkStations, edittingSettings,isOpenStatusFilter } = virtualOfficeStore;

  const hasFilter = useMemo(() => {
    return Object.values(virtualOfficeStore.filter || {}).some((value) => {
      if (value instanceof Array) {
        return value.length;
      } else {
        return !!value;
      }
    });
  }, [virtualOfficeStore.filter]);

  /**
   * 返回实际渲染的工位数据
   */
  const renderWorkStations = useMemo(() => {
    // 渲染工位时，allWorkStations要经过operateWorkStations的过滤和重组
    console.log("==useVirtualOffice==", mode, `openSettings=${openSettings}`);
    let _renderWorkStations: WorkStation[] = [];
    if (mode === "scene") {
      let _renders: WorkStation[] = openSettings ? operateWorkStations : allWorkStations;
      _renderWorkStations = _renders.filter((item) => item.floor && item.seat); // 过滤掉无座位的数据
    } else {
      _renderWorkStations = cloneDeep(allWorkStations);
    }
    console.log("==useVirtualOffice== before filter => ", `hasFilter=${hasFilter}`, _renderWorkStations?.length);
    if (hasFilter && !openSettings) {
      console.log("==useVirtualOffice== filter => ", toJS(filter));
      // 按团队/项目过滤
      _renderWorkStations = _renderWorkStations.filter((workStation) => {
        return (
          (!filter?.groupIds?.length && !filter?.teamUuids?.length) ||
          // intersection拿到两个数组的交集
          !!intersection(filter?.groupIds || [], (workStation.groups || [])?.map((group) => group.id)).length ||
          !!intersection(filter?.teamUuids || [], (workStation.teams || [])?.map((team) => team.uuid)).length
        );
      });
      // 按成员过滤
      _renderWorkStations = _renderWorkStations.filter((workStation) => {
        return !filter?.userUuids?.length || filter?.userUuids.includes(workStation.user?.uid!);
      });
      if(isOpenStatusFilter){
        // 按状态过滤
        _renderWorkStations = _renderWorkStations.filter((workStation) => {
          return !filter?.status?.length || filter?.status.includes(workStation.status!);
        });
      }
    }
    console.log("==useVirtualOffice== after filter => ", _renderWorkStations.length);
    return _renderWorkStations;
  }, [allWorkStations, operateWorkStations, mode, openSettings, edittingSettings, filter, floor, hasFilter,isOpenStatusFilter]);

  /**
   * 返回没有座位的工位数据，用于设置座位和设置红点提醒
   */
  const noSeatWorkStations = useMemo(() => {
    return allWorkStations?.filter((workStation) => !workStation.floor || !workStation.seat);
  }, [allWorkStations]);

  /**
   * 获取经过过滤后的工位数据，楼层不属于过滤
   */
  const filterWorkStations = useMemo(() => {
    return allWorkStations
      .filter((workStation) => {
        // 过滤团队、项目
        return (
          (!filter?.groupIds?.length && !filter?.teamUuids?.length) ||
          // intersection拿到两个数组的交集
          !!intersection(filter?.groupIds || [], (workStation.groups || [])?.map((group) => group.id)).length ||
          !!intersection(filter?.teamUuids || [], (workStation.teams || [])?.map((team) => team.uuid)).length
        );
      })
      ?.filter((workStation) => {
        // 过滤成员选择
        return !filter?.userUuids?.length || filter?.userUuids.includes(workStation.user?.uid!);
      })
      ?.filter((workStation) => {
        // 按状态过滤
        return !filter?.status?.length || filter?.status.includes(workStation.status!);
      });
  }, [filter, allWorkStations]);

  /**
   * 获取经过过滤后的工位数据，状态了 楼层不过滤
   */
  const filterAllStatusWorkStations = useMemo(() => {
    return allWorkStations
        .filter((workStation) => {
          // 过滤团队、项目
          return (
              (!filter?.groupIds?.length && !filter?.teamUuids?.length) ||
              // intersection拿到两个数组的交集
              !!intersection(filter?.groupIds || [], (workStation.groups || [])?.map((group) => group.id)).length ||
              !!intersection(filter?.teamUuids || [], (workStation.teams || [])?.map((team) => team.uuid)).length
          );
        })
        ?.filter((workStation) => {
          // 过滤成员选择
          return !filter?.userUuids?.length || filter?.userUuids.includes(workStation.user?.uid!);
        })

  }, [filter, allWorkStations]);

  /**
   * 我的工位
   */
  const myWorkStation = useMemo(() => {
    return virtualOfficeStore.allWorkStations?.find((workStation) => workStation.user?.id === userStore.user?.id);
  }, [userStore.user?.id, virtualOfficeStore.allWorkStations]);

  return {
    hasFilter,
    renderWorkStations,
    noSeatWorkStations,
    filterWorkStations,
    myWorkStation,
    allWorkStations,
    filterAllStatusWorkStations,
  };
}
