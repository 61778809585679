import { UseStores } from "@/stores";
import { useGStores } from "@gear/business";
import { useEffect, useRef, useState } from "react";

export function useAfterOnBoard() {
  const LOG_TAG = "useAfterOnBoard";
  const { appDownloadStore } = useGStores<UseStores>();
  const detectTargetNodeIntervalRef = useRef<NodeJS.Timeout>();
  const detectOnBoardFinishTimeoutRef = useRef<NodeJS.Timeout>();
  const onBoardingRef = useRef(false);

  const [targetNode, setTargetNode] = useState<HTMLElement | null>();

  useEffect(() => {
    return () => {
      cleanDetectTargetNodeInterval();
      cleanDetectOnBoardFinishTimeout();
    };
  }, []);

  const cleanDetectTargetNodeInterval = () => {
    if (detectTargetNodeIntervalRef.current) {
      clearInterval(detectTargetNodeIntervalRef.current);
      detectTargetNodeIntervalRef.current = undefined;
    }
  };

  const cleanDetectOnBoardFinishTimeout = () => {
    if (detectOnBoardFinishTimeoutRef.current) {
      clearTimeout(detectOnBoardFinishTimeoutRef.current);
      detectOnBoardFinishTimeoutRef.current = undefined;
    }
  };

  const startDetectTargetNode = () => {
    cleanDetectTargetNodeInterval();
    detectTargetNodeIntervalRef.current = setInterval(() => {
      const targetNode = document.getElementById("intercom-positioner-tree");
      setTargetNode(targetNode);
      console.log(LOG_TAG, "detectTargetNode", targetNode);
      if (targetNode && !onBoardingRef.current) {
        onBoardingRef.current = true;
      }
    }, 5000);
  };

  const stopDetectTargetNode = () => {
    console.log(LOG_TAG, "stopDetectTargetNode");
    cleanDetectTargetNodeInterval();
  };

  useEffect(() => {
    if (targetNode) {
      // targetNode出现或者重新出现，停止检测onboard流程是否结束
      cleanDetectOnBoardFinishTimeout();
    } else if (onBoardingRef.current) {
      // targetNode消失，开始检测onboard流程是否结束
      startDetectOnBoardFinish();
    }
  }, [targetNode]);

  /**
   * 检测onboard流程结束
   */
  const startDetectOnBoardFinish = () => {
    console.log(LOG_TAG, "startDetectOnBoardFinish");
    cleanDetectOnBoardFinishTimeout();
    detectOnBoardFinishTimeoutRef.current = setTimeout(() => {
      console.log(LOG_TAG, "finish onboarding!!!!");
      stopDetectTargetNode();
      appDownloadStore.updateAppDownloadGuideModalVisible(true);
    }, 20000);
  };

  return {
    startDetectTargetNode,
    stopDetectTargetNode
  };
}
