import { UseStores } from "@/stores";
import { TeamApi, WAY_OF_WORKING, WorkStationStatus, useGStores, useIsOnlineStatus, useWebsocketStatus } from "@gear/business";
import { useMemo } from "react";

/**
 * 自己工位的业务hook
 */
export function useMyWorkStation() {
  const isOnline = useIsOnlineStatus();
  const { isWsAlive } = useWebsocketStatus();
  const { workStationStore, userStore } = useGStores<UseStores>();

  const { fetchMyWorkStation } = workStationStore;

  const myWorkStation = useMemo(() => {
    return workStationStore.allWorkStations.find((item) => item.user?.id === userStore.user?.id);
  }, [workStationStore.allWorkStations, userStore.user?.id]);

  /**
   * 更换工作方式
   * @param way
   */
  const updateWayOfWorking = (way: WAY_OF_WORKING) => {
    if (myWorkStation?.id) {
      // 确保myWorkStation是有效数据
      const oldWorkStation = myWorkStation;
      workStationStore.handleUpdateWorkStation(Object.assign({}, myWorkStation, { work_method: way }));
      TeamApi.patchWorkstation(myWorkStation?.id!, {
        work_method: way
      })
        .then((res) => {
          workStationStore.handleUpdateWorkStation(res.data);
        })
        .catch(() => {
          // 修改失败，恢复旧数据
          workStationStore.handleUpdateWorkStation(oldWorkStation!);
        });
    }
  };

  const updateMyWorkStationStatus = (params: {
    status: WorkStationStatus; // 工位状态
    status_duration?: number; // 工位状态保持时长，用于会议状态
    status_end_time?: string; // 工位状态结束时间，用于会议状态
  }) => {
    if (myWorkStation?.id) {
      // 确保myWorkStation是有效数据
      const oldWorkStation = myWorkStation;
      workStationStore.handleUpdateWorkStation(Object.assign({}, myWorkStation, params));
      TeamApi.updateMyWorkStationStatus(myWorkStation?.id!, params).catch(() => {
        // 修改失败，恢复旧数据
        workStationStore.handleUpdateWorkStation(oldWorkStation!);
      });
    }
  };

  const myWorkStationStatus = useMemo(() => {
    return isOnline && isWsAlive ? myWorkStation?.status : WorkStationStatus.offline;
  }, [myWorkStation?.status, isOnline, isWsAlive]);

  return {
    updateWayOfWorking,
    updateMyWorkStationStatus,
    fetchMyWorkStation,
    myWorkStationStatus,
    myWorkStation
  };
}
