import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AudioServer } from "@gear/business";

if (process.env.REACT_APP_DEPLOY_ENV && process.env.REACT_APP_DEPLOY_ENV !== "development" && process.env.REACT_APP_DEPLOY_ENV.indexOf("_dev") < 0) {

  Sentry.init({
    dsn: "https://1a75231a0f294f4b8f3a4d557042aefb@o4504892917153792.ingest.sentry.io/4504915629441024",
    // @ts-ignore
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    environment: process.env.REACT_APP_DEPLOY_ENV
  });
}

audioServer = new AudioServer({
  recorder: {
    sampleBits: 16,
    sampleRate: 16000,
    numChannels: 1,
    compiling: false
  }
});

const rootElement = document.getElementById('root');
if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
