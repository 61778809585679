import { useEffect, useMemo, useState } from "react";
import { debounce, loadMoreCallBackByScrolling, TYPES_OF_FETCHING } from "@/utils";
import { GSelect } from "@gear/ui";
import { cloneDeep, isEqual, uniqWith } from "lodash";
import { useTranslation } from "react-i18next";
import { GCity, SettingAPI } from "@gear/business";
import { Spin } from "antd";
import { useMyWorkStation } from "@/hooks/useMyWorkStation";
import "./index.scss";

const DEFAULT_PAGE_SIZE = 50;

type ManualSettingProps = {
  currentCity?: GCity;
  setCurrentCity?: (val: GCity) => void;
};
// 手动设置时区
const ManualSetting: React.FC<ManualSettingProps> = ({ currentCity, setCurrentCity }) => {
  const [cities, setCities] = useState<GCity[]>([]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState(currentCity?.name || "");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { myWorkStation } = useMyWorkStation();
  const currentValidCity = myWorkStation?.user?.city;
  const [initComplete, setInitComplete] = useState(false);
  const fetchCity = ({
    page,
    page_size,
    fetchType = TYPES_OF_FETCHING.INIT,
    name = ""
  }: {
    page: number;
    page_size: number;
    fetchType?: TYPES_OF_FETCHING;
    name?: string;
  }) => {
    setLoading(true);
    SettingAPI.getCities({ page, page_size, name, center_city: !name ? currentValidCity?.id : 0 })
      .then((res) => {
        const { data } = res;
        const { results } = data;
        let tempCities = [];
        const prevCities = cloneDeep(cities);

        if (fetchType === TYPES_OF_FETCHING.INIT) {
          tempCities = results;
        } else {
          tempCities = [...prevCities, ...results];
        }

        setPage(page);
        setCities(uniqWith(tempCities, isEqual));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchByName = (e: string) => {
    setName(e);
    setPage(1);
  };

  useEffect(() => {
    if (initComplete) {
      fetchCity({ page, page_size: DEFAULT_PAGE_SIZE, name });
    }
  }, [name]);

  const init = async () => {
    setLoading(true);
    const p1 = SettingAPI.getCities({
      page: 1,
      page_size: DEFAULT_PAGE_SIZE,
      name: "",
      center_city: currentValidCity?.id
    });
    const p2 = SettingAPI.getCities({ page: 1, page_size: DEFAULT_PAGE_SIZE, name });
    const [res1, res2] = await Promise.all([p1, p2]);
    setLoading(false);
    const { results: list1 } = res1.data;
    const { results: list2 } = res2.data;
    setCities(uniqWith([...list2, ...list1], isEqual));
    setInitComplete(true);
  };
  useEffect(() => {
    init();
  }, []);

  const options = useMemo(() => {
    return cities?.map((city) => {
      // 等antd升级到 4.17 以后可以使用 fieldNames 字段自动映射
      return {
        ...city,
        label: `${city?.name} - ${city?.country?.name}`,
        value: city?.id
      };
    });
  }, [cities]);
  const offset = useMemo(() => {
    const offset = currentCity?.timezone?.offset;
    if (!offset) return "";
    return `(UTC ${offset > 0 ? "+" : ""}${offset})`;
  }, [currentCity]);

  return (
    <div className={"manual-setting-container"}>
      <div className={"city-info"}>
        <span className={"label"}>{t("Closest City:")}</span>
        <Spin spinning={loading}>
          <GSelect
            width={"358px"}
            height="36px"
            // allowClear={true}
            value={currentCity?.id}
            dropdownClassName={"select-member-dropdown"}
            loading={loading}
            onChange={(value, option) => {
              setCurrentCity && setCurrentCity(option as any);
            }}
            showSearch
            searchValue={name}
            optionLabelProp={"label"}
            onSearch={handleSearchByName}
            filterOption={false}
            options={options}
            onPopupScroll={debounce((e) => {
              loadMoreCallBackByScrolling(e, { dispatch: fetchCity, page, page_size: DEFAULT_PAGE_SIZE, params: { name } });
            }, 200)}
          />
        </Spin>
      </div>
      <div className={"city-info"}>
        <span className={"label"}>{t("Timezone")}</span>
        <span className={"value"}>
          {currentCity?.timezone?.standard_time || "--"} {offset}
        </span>
      </div>
    </div>
  );
};

export default ManualSetting;
