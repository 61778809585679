import ICON_ANDROID from "@/assets/icon/icon_android.png";
import ICON_ANDROID_ACTIVE from "@/assets/icon/icon_android_active.png";
import ICON_DESKTOP from "@/assets/icon/icon_desktop.png";
import ICON_DESKTOP_ACTIVE from "@/assets/icon/icon_desktop_active.png";
import ICON_IOS from "@/assets/icon/icon_ios.png";
import ICON_IOS_ACTIVE from "@/assets/icon/icon_ios_active.png";
import ICON_MINIPROGRAM from "@/assets/icon/icon_miniprogram.png";
import ICON_MINIPROGRAM_ACTIVE from "@/assets/icon/icon_miniprogram_active.png";
import ICON_OTHER from "@/assets/icon/icon_other.png";
import ICON_OTHER_ACTIVE from "@/assets/icon/icon_other_active.png";
import ICON_WEB from "@/assets/icon/icon_web.png";
import ICON_WEB_ACTIVE from "@/assets/icon/icon_web_active.png";

import {
  GCandidate,
  GProfile as Profile,
  GResumeData,
  GSprint,
  GTask,
  GTeam,
  GGanttTaskStatus,
  Group,
  GMilestone,
  GDailyWorkReview,
  GLikeUsers,
  GViewerRecordUsers,
  Enterprise,
  GAccountGroup,
  TeamStatus,
  GEmploymentMode as EmploymentMode,
  GCurrencyType,
  EditorReport,
  TeamUser,
  GComment,
  EnterprisePaymentStatus,
  EOffer,
  TimesheetSetReviewerMethod,
  EnterpriseSettingType,
  AppCategory,
  EnterpriseAppFeature,
  GUser
} from "@gear/business";
import { GColor, PlainObject, PlainOption } from "@gear/ui";
import React from "react";
import { GUser as User } from "@gear/business";
import { AppUsageProductivityType } from "@/utils";
import exp from "constants";

export interface TagsType {
  tag_id?: number;
  tag_name?: string;
}

// export interface WorkExperience {
//   remarks?: string;
//   position?: string;
//   start_time?: string;
//   end_time?: string;
//   enterprise_name?: string;
//   id?: number;
//   url?: string;
//   desc?: string;
//   company_type?: TagsType[];
//   venture_capital?: TagsType[];
//   industry?: TagsType[];
// }

// export interface ProjectExperiences {
//   id?: number;
//   name?: string;
//   description?: string;
//   desc?: string;
//   remarks?: string;
//   role?: string;
//   start_time?: string;
//   end_time?: string;
//   enterprise_name?: string;
//   abstract?: string;
//   tag_levels?: TagLevel[];
//   attachments?: GAttachment[];
//   url?: string;
// }

// export interface User extends GUser {
//   position?: string;
//   is_tpm?: boolean;
//   user_info?: UserInfo; // 用户配置
//   profile_id?: number;
//   agreement_required?: boolean;
// }

// export interface UserInfo {
//   id?: number;
//   language?: string; // 用户配置语言，zh | en
//   auto_translation?: boolean; // 是否开启自动翻译，开启后将会翻译用户产出的内容，比如日报、评论
// }

// 企业
// export const EnterpriseNature: { [key: string]: string } = {
//   "0": "Unknown",
//   /*'1': '国有企业',
//     '2': '国有控股企业',
//     '3': '外资企业',
//     '4': '合资企业',
//     '5': '私营企业'*/
//   "1": "Private organization",
//   "2": "State-owned organization",
//   "3": "Joint venture",
//   "4": "Foreign organization"
// };
export const EnterpriseScale: { [key: string]: string } = {
  "0": "Unknown",
  "1": "Less than 20 employees",
  "2": "20~100 employees",
  "3": "100~500 employees",
  "4": "More than 500 employees"
};

// export interface Enterprise {
//   id: number;
//   logo: string;
//   name: string;
//   nature: string; // EnterpriseNature
//   scale: string; // EnterpriseScale
//   main_business: string;
//   area_code: string;
//   landline_number: string;
//   address: string;
//   region: string;
//   contact_name: string;
//   contact_phone_number: string;
//   contact_email: string;
//   created_at: string;
//   creator: number;
//   number_of_demands: number;
//   number_of_teams?: number;
//   principal?: User;
//   currency_type?: CurrencyType;
//   logo_id?: number;
//   region_id?: number;
//   url?: string;
//   description?: string;
//   total_floor?: number;
//   marketplace_opened?: boolean; // 是否开通marketplace
//   metawork_opened?: boolean; // 是否开通metawork
// }

// 流程进度节点
export interface Node {
  id?: number;
  created_at?: string;
  creator?: number;
  demand?: number;
  remarks?: string;
  status?: DemandStatus;
  user?: Profile;
  name?: string; // 本地用，表示状态名称
  action?: string; // 本地用，表示动作
}

// export interface Demand {
//   id?: number;
//   serial_number?: string;
//   name?: string;
//   description?: string;
//   status?: number;
//   created_at?: string;
//   creator?: number;
//   products?: Product[];
//   nodes?: Node[];
//   principal?: Profile | number;
//   attachments?: number[] | GAttachment[];
//   assignment_at?: string; // 分配时间
//   assignment_times?: number; // 分配次数
//   enterprise?: Enterprise;
//   completed_at?: string; // 完成、关闭时间
//   terminated_reason?: string; // 终止原因
// }

export interface Product {
  id?: number;
  description?: string;
  number?: number;
  start_time?: string;
  end_time?: string;
  position_tag?: number | TagLevel; // 职位标签级别/id
  language_tags?: number[] | TagLevel[]; // 开发语言标签级别/id
  framework_tags?: number[] | TagLevel[]; // 框架标签级别/id
  candidates?: GCandidate[]; // 候选人
  status?: ProductStatus;
  period?: number; // 预估周期，本地用
  start_time_moment?: moment.Moment; // 配合表单使用，本地用
  position?: number; // 配合表单使用，本地用，放职位id
  languages?: number[]; // 配合表单使用，本地用， 放标签id
  frameworks?: number[]; // 配合表单使用，本地用， 放标签id
  show_badge?: boolean; // 展示角标，本地详情页用
}

export enum RejectedReasonKey {
  Rate = 1, // rate太高，超出预算
  Requirements = 2, // 技术栈能力不满足需求
  Skillsets = 3, // 缺少某些必要的技术栈
  Experiences = 4, // 缺少必要的项目经验
  Communication = 21, // 沟通能力有待提高
  NotMatch = 22, // 技术能力与简历不符
  Headcount = 23, // HC满了，如果再次招聘，愿意用他
  Other = 99 // 其他
}

export const RejectedReasonKeyStyle: {
  [key: number]: { label: string };
} = {
  1: { label: "Rate is over budget" },
  2: { label: "Skill level does not meet requirements" },
  3: { label: "Lack of essential skillsets" },
  4: { label: "Lack of specific project experiences" },
  21: { label: "Lacking in communication or other soft skills" },
  22: { label: "Technical abilities do not match profile" },
  23: { label: "Headcount filled, but interested in potential future collaboration" },
  99: { label: "Other" }
};

export interface InterviewRecord {
  id?: number;
  link: string; // 会议记录链接
  candidate?: number; // 候选id
  start_time: string;
  end_time: string;
  duration?: number;
  remarks: string;
}

export enum DemandStatus {
  submitted = 10, // 需求已提交
  demandConfirmed = 20, // 需求已确认
  allocated = 40, // 完成分配
  revoked = 60, // 需求已撤回
  closed = 88, // 需求已关闭
  order = 99 // 需求已成单
}

// export const DemandStatusStyle: {
//   [key: string]: {
//     action: string;
//     label: string;
//     color: string;
//     bgColor: string;
//     borderColor: string;
//   };
// } = {
//   "10": {
//     action: "需求提交",
//     label: "待需求确认",
//     color: "#FF2D2D",
//     bgColor: "#FFFAFA",
//     borderColor: "#FFA2A2"
//   },
//   "20": {
//     action: "需求确认",
//     label: "待分配工程师",
//     color: "#2154FF",
//     bgColor: "#F7F9FF",
//     borderColor: "#2154FF"
//   },
//   "40": {
//     action: "完成分配",
//     label: "待确认工程师",
//     color: "#FF7D21",
//     bgColor: "#FFFFFF",
//     borderColor: "#FF7D21"
//   },
//   "60": {
//     action: "需求撤回",
//     label: "需求撤回",
//     color: "#FF2D2D",
//     bgColor: "#FFFAFA",
//     borderColor: "#FFA2A2"
//   },
//   "88": {
//     action: "需求关闭",
//     label: "需求关闭",
//     color: "#FF2D2D",
//     bgColor: "#FFFAFA",
//     borderColor: "#FFA2A2"
//   },
//   "99": {
//     action: "需求成单",
//     label: "需求成单",
//     color: "#24D170",
//     bgColor: "#FFFFFF",
//     borderColor: "#24D170"
//   }
// };

export interface Tag {
  id: number;
  name: string;
  priority: string;
  created: string;
  created_at: string;
  is_active: false;
  levels: TagLevel[];
  modified_at: string;
}

export interface TagLevel {
  id?: number;
  level?: number;
  description?: string;
  number_of_users?: number;
  tag_id?: number;
  tag_name?: string;
  tag_name_en?: string;
  number_of_levels?: number;
  number_of_stars?: number;
  title?: string;
  has_report?: boolean;
  report?: EditorReport;
  report_en?: EditorReport;
  group?: string; // 标签类别
  is_authenticated?: boolean;
}

export const TagLevelStyle: {
  [key: string]: {
    label: string;
    color: string;
    bgColor: string;
    tipColor: string;
  };
} = {
  "-1": {
    label: "缺失",
    color: "#00000099",
    bgColor: "#E7E9ED",
    tipColor: ""
  },
  "0": {
    label: "Unknown",
    color: "#00000099",
    bgColor: "#E7E9ED",
    tipColor: ""
  },
  "1": { label: "Beginner", color: "#00000099", bgColor: "#E7E9ED", tipColor: "" },
  "2": {
    label: "Experienced",
    color: "#FFFFFF",
    bgColor: "#2E35FF",
    tipColor: "#2E35FF"
  },
  "3": {
    label: "Advanced",
    color: "#FFFFFF",
    bgColor: "#006FFF",
    tipColor: "#006FFF"
  },
  "4": {
    label: "Expert",
    color: "#FFFFFF",
    bgColor: "#19B7FF",
    tipColor: "#19B7FF"
  }
};

export enum ProductStatus {
  wait_confirm = 10,
  confirmed = 20,
  reallocate = 30
}

export const ProductStatusStyle: {
  [key: string]: {
    label: string;
    color: string;
    bgColor: string;
    borderColor: string;
  };
} = {
  "10": {
    label: "Awaiting confirmation",
    color: "#FF2D2D",
    bgColor: "#FFFAFA",
    borderColor: "#FFA2A2"
  },
  "20": {
    label: "Confirmed",
    color: "#24D170",
    bgColor: "#FFFFFF",
    borderColor: "#24D170"
  },
  "30": {
    label: "重新分配",
    color: "#FF2D2D",
    bgColor: "#FFFAFA",
    borderColor: "#FFA2A2"
  }
};

// export interface Profile extends GProfile {
//   email: string;
//   is_admin: boolean;
//   is_serving: boolean;
//   is_internal: boolean; // 是否企业内部
//   is_active: boolean; // 是否激活
//   date_joined: string;
//   enterprise: number;
//   user: User;
//   groups: AccountGroup[];
//   permissions: Permission[];
//   user_id: number;
//   user_tags?: TagLevel[];
//   position?: string;
// }

/**
 * 上传到S3文件
 */
// export enum AttachmentIntent {
//   resume = 0, // 简历
//   examination_answers = 1, // 答案
//   video_introduction = 1, // 视频介绍
//   video_subtitle = 2, // 字幕
//   video_subtitle_en = 3, // 英文字幕
//   editor = 999 // 富文本中的图片
// }

/** */
// export interface S3Sign {
//   url: string; // 上传地址
//   fields: {
//     AWSAccessKeyId: string;
//     "Content-Type": string;
//     acl: string;
//     key: string;
//     policy: string;
//     signature: string;
//   };
// }

// export interface AccountGroup {
//   id: number;
//   name: string;
//   enterprise: number;
//   is_locked: boolean;
//   permissions: number[];
//   number_of_users?: number;
// }

// export enum TeamStatus {
//   Pending = "pending", // 等待分配
//   Ongoing = "ongoing", // 进行中
//   Terminated = "terminated", // 已终止
//   Completed = "completed" // 已完成
// }

/**
 * ANAN ADD
 * 团队已经终止或结束的状态
 */
export const TeamSuspend = [TeamStatus.Terminated, TeamStatus.Completed];

// export interface ProjectType {
//   id: number;
//   title: string;
// }

// export interface ApplicationPlatforms {
//   id: number;
//   name: string;
//   icon: string;
// }

export const ApplicationPlatformsStyle: { [key: string]: any } = {
  Android: {
    label: "Android",
    icon: ICON_ANDROID,
    icon_active: ICON_ANDROID_ACTIVE
  },
  iOS: { label: "iOS", icon: ICON_IOS, icon_active: ICON_IOS_ACTIVE },
  小程序: {
    label: "小程序",
    icon: ICON_MINIPROGRAM,
    icon_active: ICON_MINIPROGRAM_ACTIVE
  },
  Web: { label: "Web", icon: ICON_WEB, icon_active: ICON_WEB_ACTIVE },
  桌面应用: {
    label: "Desktop app",
    icon: ICON_DESKTOP,
    icon_active: ICON_DESKTOP_ACTIVE
  },
  其他: { label: "Other", icon: ICON_OTHER, icon_active: ICON_OTHER_ACTIVE }
};

// export interface Employment {
//   id?: number;
//   number?: number;
//   remarks?: string;
//   mode?: number;
//   status?: EmploymentStatus;
//   role?: string;
//   duration?: number;
//   duration_unit?: string;
//   start_time?: string | moment.Moment;
//   created_at?: string;
//   updated_at?: string;
//   team?: number | Team;
//   position?: TagLevel | number;
//   position_name?: string;
//   creator?: Profile;
//   required_tags?: { [key: string]: TagLevel[] };
//   required_skills?: number[] | TagLevel[];
//   candidates?: GCandidate[];
//   terminated_reason?: string;
//   other_tags?: string; // 其他标签，逗号分隔
//   period?: number; // 雇佣时长，本地用
//   serial_number?: string; // 工单号字段
//   replaced_team_user?: number; // 替换 TPM
//   years?: number; // 工作年限
//   minimum_salary?: number; // 最低预估薪酬
//   maximum_salary?: number; // 最高预估薪酬
//   currency_type?: string;
//   position_level?: number; // 自定义职位的等级
//   position_group?: string; // 自定义职位的分类
//   expert_tags?: string; // 自定义的高级标签，逗号分隔
//   proficient_tags?: string; // 自定义的中级标签，逗号分隔
//   familiar_tags?: string; // 自定义的初级标签，逗号分隔
//   expect_start_period?: ExpectStartPeriod; // 预计到岗时间
//   tpm_type?: string;
// }

// export enum ExpectStartPeriod {
//   PENDING = 0, // 'i will decide later'
//   IMMEDIATELY = 1, // 'immediately'
//   IN_2_WEEKS = 2, // 'in 1~2weeks'
//   AFTER_2_WEEKS = 3 // 'more than 2weeks from now'
// }

export const ExpectStartPeriodStyle: { [key: number]: { label: string } } = {
  1: { label: "Immediately" },
  2: { label: "In 1~2 weeks" },
  3: { label: "More than 2 weeks from now" },
  0: { label: "I will decide later" }
};

export const EmploymentModeLabel: {
  [key: string]: { label: string; tip: string };
} = {
  "0": { label: "Unknown", tip: "" },
  "1": { label: "Hourly", tip: "Billing based on actual working hours" },
  "2": { label: "Full time", tip: "At least 8 hours per day and more than 40 hours per week" }
};

// export enum DurationUnit {
//   Minute = "minute", // 分
//   Hour = "hour", // 时
//   Day = "day", // 天
//   Week = "week", // 周
//   Month = "month", // 月
//   Year = "year" // 年
// }

// export const DurationUnitLabel: { [key: string]: { label: string } } = {
//   minute: { label: "分" },
//   hour: { label: "Hour" },
//   day: { label: "day" },
//   week: { label: "Weeks" },
//   month: { label: "Months" },
//   year: { label: "Year" }
// };

// export interface Playbook {
//   template: PlaybookTemplate;
//   records: PlaybookRecord[];
// }

// export interface PlaybookTemplate {
//   id: number;
//   version: string;
//   status: string;
//   check_items: PlaybookCheckItem[];
// }

// export interface PlaybookCheckItem {
//   id: number;
//   name: string;
// }

export interface PlaybookRecord {
  id?: number;
  remarks?: string;
  status?: string;
  expected_at?: string;
  completed_at?: string;
  user?: User;
  check_item?: number;
  app_label?: string;
  model?: string;
  object_id?: number;
  check_item_id?: number;
}

// export enum PlaybookRecordStatus {
//   Unknown = "unknown", // 未知
//   Ignored = "ignored", // 已忽略
//   Completed = "completed" // 已完成
// }

// export enum ProjectStatus {
//   Idle = "idle", // 未开始
//   Ongoing = "ongoing", // 进行中
//   Terminated = "terminated", // 异常终止
//   Completed = "completed" // 正常完成
// }

// interface EditorReport {
//   EditorNode: string; // 报告的node节点
//   EditorHtml: string; // 报告的html文本
// }

// export interface UserTag {
//   id?: number;
//   level?: TagLevel;
//   years?: number;
//   remarks?: string; //'能够胜任技术经理'
//   report?: EditorReport;
//   report_en?: EditorReport;
//   has_report?: boolean;
//   tag_category_code?: string;
//   tag_category_description?: string;
//   description?: string;
//   title?: string;
//   levelText?: string;
//   is_authenticated?: boolean;
//   score?: number;
//   is_core?: boolean;
// }

// export interface SkillTag {
//   developer: number;
//   id?: number;
//   level?: TagLevel;
//   remark?: string;
//   score?: number;
//   has_report?: boolean;
// }

export interface ResumeData extends GResumeData {}

/**
 * Add From ANAN
 * 简历的评论
 */
export type CommentResume = {
  content_type?: number;
} & Omit<GComment, "creator | id"> & {
    id: number;
  } & (
    | {
        anonymous: string;
      }
    | {
        anonymous: null;
        creator: User;
      }
  );

export interface Sprint extends GSprint {
  team_id?: number;
  team_uuid?: string;
  is_deleted?: boolean;
}

export interface Task extends GTask {
  remarks?: string;
  is_deleted?: boolean;
  team_uuid?: string;
}

export enum TaskStatus {
  pending = 10,
  ongoing = 20,
  terminated = 88,
  completed = 99,
  timeout = 0 // 本地用
}

/*项目版本状态*/
export const GanttSprintStatusStyle: { [key: number]: { label: string; bgColor: string; color: string } } = {
  0: { label: "To do", bgColor: "#EFF1F3", color: "#37383B" },
  10: { label: "On track", bgColor: "rgba(97, 115, 232, 0.1)", color: "#5066EE" },
  30: { label: "At risk", bgColor: "rgba(255, 244, 242, 1)", color: "#FD957F" },
  40: { label: "Off track", bgColor: "rgba(255, 241, 242, 1)", color: "#FF7481" },
  50: { label: "On hold", bgColor: "rgba(255, 116, 129, 0.1)", color: "#FF7481" },
  20: { label: "Completed", bgColor: "rgba(14, 164, 125, 0.1)", color: "#14B087" }
};
//列表固定顺序
// export const GanttSprintStatusOption: {
//   label: string;
//   value: number;
// }[] = [
//   { label: "To do", value: 0 },
//   { label: "On track", value: 10 },
//   { label: "At risk", value: 30 },
//   { label: "Off track", value: 40 },
//   { label: "On hold", value: 50 },
//   { label: "Completed", value: 20 }
// ];

export interface Bill {
  id?: number;
  user_name?: string;
  status?: BillStatus;
  start_date?: string;
  end_date?: string;
  position_name?: string;
  employment_mode?: EmploymentMode;
  external_price?: number;
  internal_price?: number;
  deducted_amount?: number; //
  original_amount?: number; //
  adjusted_amount?: number; //
  total_amount?: number; //
  expected_days?: number; //
  actual_hours?: number; //
  actual_days?: number; //
  absent_days?: number; //
  overtime_days?: number; // 加班天数
  leave_days?: number; // 请假天数
  adjusted_days?: number; // 调整天时
  adjusted_hours?: number; // 调整小时数
  team_user_id?: number;
  team_user?: TeamUser;
  updated_at?: string;
  user_id?: number;
  disputes?: string[]; //
  adjustments?: string[]; //
}

export enum BillStatus {
  pending = 10, // 待确认
  obsolete = 88, // 废弃
  confirmed = 99 // 已确认
}

export enum AffairExamineStatus {
  agree = 2,
  refuse
}

export enum PositionGroup {
  backend = "backend",
  frontend = "frontend"
}

export const PositionGroupStyle: {
  [key: string]: {
    label: string;
    label_en: string;
  };
} = {
  backend: {
    label: "后端",
    label_en: "Backend"
  },
  frontend: {
    label: "前端",
    label_en: "Frontend"
  },
  Design: {
    label: "设计师",
    label_en: "Design"
  },
  "QA&Tester": {
    label: "测试/QA",
    label_en: "QA"
  }
};

//TODO: this could be imported from business. but it's defined in component director in business.
export interface PositionSkill {
  id?: number;
  years?: number; //
  daily_salary?: number; // 基础薪酬
  hourly_salary?: number;
  daily_salary_usd?: number; // 基础薪酬
  hourly_salary_usd?: number;
  created_at?: string;
  updated_at?: string;
  position?: TagLevel;
  proficient?: TagLevel[]; // 精通技能，对应标签等级4
  familiar?: TagLevel[]; // 熟悉技能，对应标签等级3
  understood?: TagLevel[]; // 了解技能，对应标签等级2
  nice_to_have?: TagLevel[]; // 锦上添花技能，对应标签等级1
  proficient_daily_price?: number; // 精通的日价
  proficient_hourly_price?: number; // 精通的时价
  familiar_daily_price?: number; // 熟悉的日价
  familiar_hourly_price?: number; // 熟悉的时价
  understood_daily_price?: number; // 了解的日价
  understood_hourly_price?: number; // 了解的时价
  nice_to_have_daily_price?: number; // 锦上添花的日价
  nice_to_have_hourly_price?: number; // 锦上添花的时价
  proficient_daily_price_usd?: number; // 精通的日价
  proficient_hourly_price_usd?: number; // 精通的时价
  familiar_daily_price_usd?: number; // 熟悉的日价
  familiar_hourly_price_usd?: number; // 熟悉的时价
  understood_daily_price_usd?: number; // 了解的日价
  understood_hourly_price_usd?: number; // 了解的时价
  nice_to_have_daily_price_usd?: number; // 锦上添花的日价
  nice_to_have_hourly_price_usd?: number; // 锦上添花的时价
  currency_type?: GCurrencyType;
  group?: string; // 分组或类别
}

// export interface EnterpriseBill {
//   amount: number;
//   created_at: string;
//   end_date: string;
//   enterprise: Enterprise;
//   id: number;
//   start_date: string;
//   status: EnterprisePaymentStatus;
//   currency_type: GCurrencyType;
//   payment_status: EnterprisePaymentStatus;
//   invoice_id?: number;
//   invoice_url?: string;
// }

// export interface TeamBill {
//   amount: number;
//   currency_type?: GCurrencyType;
//   created_at: string;
//   end_date: string;
//   team: Team;
//   id: number;
//   start_date: string;
//   status: EnterprisePaymentStatus;
// }

// export interface EnterpriseUserBill {
//   id: number;
//   created_at: string;
//   updated_at?: string;
//   is_deleted: boolean;
//   start_date?: string;
//   end_date?: string;
//   employment_mode: EmploymentMode;
//   external_price?: number;
//   internal_price?: number;
//   hours?: number;
//   days?: number;
//   external_amount?: number;
//   internal_amount?: number;
//   currency_type: GCurrencyType;
//   absent_days: number;
//   overtime_days: number;
//   enterprise: Enterprise;
//   user: User;
//   position?: TagLevel;
//   discounted_price?: number; // 折扣价
//   discount_is_valid?: boolean; // 折扣是否有效
//   discount_remarks?: string | null; // 折扣备注
// }

// export enum EnterprisePaymentStatus {
//   all = 99,
//   not = 10,
//   partial = 30
// }

// export enum EnterprisePaymentStatus {
//   DRAFT = 1, //'待生成'
//   CONFIRMED = 2, //'待发送'
//   UNPAID = 10, //'待支付'
//   WITHDRAWN = 15, //'已撤回'
//   NO_COST = 30, // '未产生费用'
//   FREE = 66, //'无需支付'
//   PAID = 99, //'已支付'
//   VOID = 120 //'已废除'
// }

export const EnterprisePaymentMap: PlainObject<string> = Object.freeze({
  [EnterprisePaymentStatus.PAID]: "Paid",
  [EnterprisePaymentStatus.UNPAID]: "Unpaid",
  [EnterprisePaymentStatus.NO_COST]: "No costs incurred"
});

export const enterpriseBillPaidOptions = [
  {
    label: EnterprisePaymentMap[EnterprisePaymentStatus.PAID],
    value: EnterprisePaymentStatus.PAID,
    color: GColor.transparent
  },
  {
    label: EnterprisePaymentMap[EnterprisePaymentStatus.UNPAID],
    value: EnterprisePaymentStatus.UNPAID,
    color: GColor.red522
  }
];

export const teamBillPaidMap = {
  [EnterprisePaymentStatus.PAID]: "Paid",
  [EnterprisePaymentStatus.UNPAID]: "Unpaid"
};

export const teamBillPaidOptions: (PlainOption<EnterprisePaymentStatus> & {
  color: GColor;
})[] = [
  {
    label: teamBillPaidMap[EnterprisePaymentStatus.PAID],
    value: EnterprisePaymentStatus.PAID,
    color: GColor.transparent
  },
  {
    label: teamBillPaidMap[EnterprisePaymentStatus.UNPAID],
    value: EnterprisePaymentStatus.UNPAID,
    color: GColor.red522
  }
];

// 企业端-概览-统计结果
// export interface Overview {
//   total_actual_remainder_workload: number; // 实际剩余工时（毫秒）
//   progress: number; // 进度对比，正数为提前，负数为延迟
//   total_completed_task_count: number; // 完成任务数
//   today_completed_task_count: number; // 今日完成任务数
//   avg_active_duration: number; // 平均活跃时长
//   total_time_spent: number; // 合计工时（毫秒）
//   today_time_spent: number; // 今日工时（毫秒）
// }

// 工时燃起图
// export interface RemainderWorkload {
//   date: string;
//   actual_remainder_workload: number; // 实际剩余工时
//   estimated_remainder_workload: number; // 预计剩余工时
// }

// 工时燃起图2
export interface RemainderWorkloadSecond {
  date: string;
  total_tasks: number; // 总任务数
  plan_completed_tasks: number; // 计划完成的任务数
  actual_completed_tasks: number; // 实际完成的任务数
}

// 已完成任务图
// export interface CompletedTaskStatus {
//   statistic_data: {
//     "0": number; // 按时完成
//     "+_20-50": number;
//     "+_50-100": number; // 提前50-100
//     "-_20-50": number; // 延迟20-50
//     "-_50-100": number; // 延迟50-100
//     "-_100+": number; // 延迟100+
//   };
//   team: {
//     id: number;
//     name: string;
//   };
// }

// export interface EnterpriseTaskStatus {
//   team: {
//     id: number;
//     name: string;
//   };
//   statistic_data: {
//     pending: number; // 未开始的
//     ongoing_no_risk: number; // 进行中（正常）任务数
//     ongoing_with_risk: number; // 进行中（风险）任务数
//     completed: number; // 按时完成
//     completed_with_delay: number; // 延期完成
//   };
// }

// export interface UserSumHours {
//   user: {
//     id: number;
//     name: string;
//   };
//   statistic_data: [
//     {
//       date: string;
//       time_spent: number;
//     }
//   ];
// }

// export interface TeamSumHours {
//   team: {
//     id: number;
//     name: string;
//   };
//   statistic_data: [
//     {
//       date: string;
//       time_spent: number;
//     }
//   ];
// }

// export enum DailyProjectItemCategory {
//   temp = 10,
//   today = 20,
//   tomorrow = 30
// }

// export interface DailyProjectItem {
//   category: DailyProjectItemCategory;
//   name: string;
//   remarks: string;
//   progress: number;
//   risky: boolean;
//   // requirement?: number;
//   task?: number;
// }

// export enum HolidayType {
//   CN = "1",
//   US = "2"
// }

/**
 * From ANAN
 * 消息中心相关开始
 */
// export interface NoticeWorkFlowOrder {
//   id: number;
//   team: number;
//   start_time: number;
//   end_time: number;
//   duration: string;
//   remark: string;
//   status: 1 | 2 | 3 | 4;
//   creator: number;
//   created_at: number;
//   updated_at: number;
//   workflow_name: string;
//   workflow_name_en?: string;
// }

// export type NoticeMessage = {
//   source: 1 | 2 | 3 | 4;
//   source_id: number;
//   receive: 0 | 1 | 2 | 3 | 4;
//   receive_id: number;
//   receive_uuid: string;
//   created_at: number;
//   source_name: string;
//   source_name_en?: string;
//   receive_name: string;
//   receive_name_en?: string;
// } & (
//   | {
//       action: 1;
//       content: {
//         position: TagLevel;
//       };
//     }
//   | {
//       action: 2;
//       content: {
//         workflow_order: NoticeWorkFlowOrder;
//       };
//     }
// );

export interface NotificationSystemNoticeExpand {
  candidate_id: number;
  developer_name_en?: string;
  developer_name_zh?: string;
  enterprise_id?: number;
  position_name?: string;
  team_id?: number;
  team_name?: string;
  uuid?: string;
  url?: string;
  user?: number;
  enterprise?: number;
  date?: string;
  ot?: string; // 对象类型，目前就(entdailywork, sprint, requirement,task), 为了兼容老数据，缺省值就当entdailywork
  oid?: number;
  vid?: number;
  work_item?: number;
  cid?: number;
}

export interface NotificationSystemNotice {
  id: number;
  created_at: string;
  updated_at: string;
  action: number;
  content: string;
  expand?: NotificationSystemNoticeExpand;
}

export interface Notification {
  id: number;
  created_at: string;
  updated_at: string;
  system_notice_id: number;
  user_id: number;
  read_at?: string;
  system_notice?: NotificationSystemNotice;
}

export enum ProjectDailyTaskProgress {
  todo,
  ongoing,
  done
}
/**
 * From ANAN
 * 消息中心相关结束
 */

export const ProjectDailyTaskStatusStyle: { [key: number | string]: { label: string; color: string; bgColor: string; className: string } } = {
  [ProjectDailyTaskProgress.todo]: {
    color: "#37383b",
    label: "To do",
    bgColor: "rgba(99, 114, 139, 0.08)",
    className: "todo"
  },
  [ProjectDailyTaskProgress.ongoing]: {
    color: "#5066ee",
    label: "Ongoing",
    bgColor: "rgba(97, 115, 232, 0.1)",
    className: "ongoing"
  },
  [ProjectDailyTaskProgress.done]: {
    label: "Done",
    bgColor: "rgba(14, 164, 125, 0.1)",
    color: "#14b087",
    className: "done"
  }
  // isDelay: {
  //   label: "Delayed",
  //   bgColor: "rgba(253, 149, 127, 0.1)",
  //   color: "#fc7355",
  //   className: "delayed"
  // }
};

export const WeeklyTaskStatusStyle: { [key: number | string]: { label: string; color: string; bgColor: string; className: string } } = {
  [GGanttTaskStatus.todo]: {
    color: "#37383b",
    label: "To do",
    bgColor: "rgba(99, 114, 139, 0.08)",
    className: "todo"
  },
  [GGanttTaskStatus.ongoing]: {
    color: "#5066ee",
    label: "Ongoing",
    bgColor: "rgba(97, 115, 232, 0.1)",
    className: "ongoing"
  },
  [GGanttTaskStatus.done]: {
    label: "Done",
    bgColor: "rgba(14, 164, 125, 0.1)",
    color: "#14b087",
    className: "done"
  }
};

/**
 * 客户端下载
 */

export enum AppPlatformType {
  win = "win",
  linux = "linux",
  mac = "mac"
}

export enum AppArch {
  x64 = "x64",
  x86_64 = "x86_64",
  arm64 = "arm64",
  universal = "universal"
}

export type AppInfo = {
  version: string;
  url: string;
  release_date: string;
  size: number;
  platform: AppPlatformType;
  arch: AppArch;
};

//TODO may use GMember in business but there's a few difference..
export interface Members {
  id: number;
  date_joined?: string;
  e_offer?: EOffer;
  enterprise?: Enterprise;
  groups?: Group[];
  roles?: GAccountGroup[];
  invitation?: number;
  is_active?: boolean;
  is_admin?: boolean;
  is_internal?: boolean;
  is_serving?: boolean;
  permissions?: Permissions[];
  user?: User;
  email?: string;
  position?: string;
  expired_contract?: {
    expired_time?: string;
  };
}

export interface Roadmap {
  team: {
    name: string;
    id: number;
  };
  sprints: Sprint[];
}

//周报
//操作日志
export interface OperationLogsType {
  id?: number;
  log_type?: string;
  title?: string;
  payload?: {
    object?: string;
    uuid?: string;
  };
  object_id?: number;
  created_at?: string;
  content_type?: string;
  creator?: {
    full_name?: string;
    avatar?: string;
    gender?: number;
    timezone?: string;
    timezone_offset?: string;
    region?: string;
  };
}

// export enum WeeklyReportStatus {
//   draft = 10,
//   Update = 20,
//   Send = 30
// }

export enum WebSocketMessageName {
  // 聊天
  ChatroomMessageCreated = "chatroom_message_created",
  ChatroomMessageRead = "chatroom_message_read",
  // 项目
  ProjectTaskCreated = "project_task_created",
  ProjectTaskUpdated = "project_task_updated",
  ProjectDailyWorkCreated = "project_daily_work_created",
  ProjectTaskDeleted = "project_task_deleted",
  // 团队
  TeamWorkstationCreated = "team_workstation_created",
  TeamWorkstationDeleted = "team_workstation_deleted",
  TeamWorkstationUpdated = "team_workstation_updated",
  TeamMessageBoardCreated = "team_message_board_created",
  TeamIndividualSignatureCreated = "team_individual_signature_created",
  TeamIndividualSignatureDeleted = "team_individual_signature_deleted",
  TeamTeamUserCreated = "team_team_user_created",
  // 评论
  CommentCreated = "comment_created",
  CommentDeleted = "comment_deleted",
  // 消息通知
  Notice = "new_system_notice",
  ReadNotice = "notice_read",
  ReadALLNotice = "notice_read_all",
  // 日程
  CalendarTeammatesEventInProgressUpdated = "calendar_teammates_events_in_progress_updated"
}

export const timePerSecond = 1000;
export const timePerMinite = timePerSecond * 60;
export const timePerHour = timePerMinite * 60;
export const timePerDay = timePerHour * 24;

// export interface LanguageInterface {
//   standard: LanguageStandardType;
//   abridge: LanguageAbridgeType;
// }

// export interface DailyTasks {
//   id: number;
//   created_at?: string;
//   updated_at?: string;
//   is_deleted?: boolean;
//   date?: string;
//   timer_switch?: string;
//   remarks?: string;
//   timer_last_start_time?: string;
//   time_spent?: number;
//   enterprise?: number;
//   user?: number;
//   team?: number;
//   task?: Task;
//   milestone?: GMilestone;
//   progress?: number;
//   actual_workload?: number;
//   estimated_workload?: number;
// }

export interface DailyTasks {
  id: number;
  created_at?: string;
  updated_at?: string;
  is_deleted?: boolean;
  date?: string;
  timer_switch?: string;
  remarks?: string;
  timer_last_start_time?: string;
  time_spent?: number;
  enterprise?: number;
  user?: number;
  team?: number;
  task?: Task;
  milestone?: GMilestone;
  progress?: number;
  actual_workload?: number;
  estimated_workload?: number;
}

export interface InteractionRecords {
  id?: number;
  reviews: GDailyWorkReview[];
  like_record: GLikeUsers[];
  view_records: GViewerRecordUsers[];
}

// export interface DailyWorkReview extends GDailyWorkReview {
//   reviewer: User;
//   status: GDailyWorkStatus;
//   reject_reason?: string;
//   updated_at?: string;
// }

// export enum DailyWorkStatus {
//   reviewed = "reviewed",
//   rejected = "rejected",
//   pending = "pending"
// }

// export enum WorkItemCategory {
//   Task = "task", // 开发任务 工作进展 - 添加任务
//   Event = "event", // 临时事件 工作进展 - 添加事项
//   Plan = "plan", // 明日计划 - 添加事项
//   PlanTask = "plan_task" // 明日计划 - 添加任务
// }

//  interface WorkItem {
//   id?: number;
//   milestone?: GMilestone;
//   mileage_time?: string;
//   date?: string;
//   category?: WorkItemCategory;
//   name?: string; // 关联的任务名称
//   remarks?: string;
//   time_spent?: number; // 当前工作项的实际工作量/耗时，单位：小时
//   progress?: number;
//   old_progress?: number;
//   risk?: GGanttRiskStatus;
//   risky?: boolean;
//   daily_work?: number;
//   number_of_comments?: number;
//   actual_workload?: number; // 总的实际工作量/耗时，单位：小时
//   ent_daily_work?: number;
//   estimated_end_time?: string;
//   estimated_start_time?: string;
//   estimated_workload?: number; // 预计工作量/耗时，单位：小时
//   estimate_spent?: number; // 当前的预计工作量/耗时，单位：小时
//   is_tomorrow_task?: boolean;
//   sprint_name?: string;
//   sprint_id?: number;
//   team_name?: string;
//   team?: Team;
//   sprint?: Sprint;
//   task?: Task;
//   parents?: GTask[];
//   team_visible?: boolean;
//   comment_count?: number; // 评论数量
// }
export interface TaskGroupType {
  team?: GTeam;
  sprint?: GSprint;
  parents?: GTask[];
  data?: GTask[];
}

export enum WorkItemProgressStatus {
  todo = 0, // 未开始
  ongoing = 1, // 进行中
  done = 2 // 正常完成
}

export interface NotificationsConfigures {
  id: number;
  created_at: string;
  updated_at: string;
  user_id: number;
  category: number;
  turn_on: boolean;
  message_type: number;
  week: number;
  time_point: string;
  expand: {
    version_switch: boolean;
    task_switch: boolean;
    all_tasks_in_version_switch: boolean;
  };
}

export interface NotificationsConfig {
  permissions: Permissions[];
  configures: NotificationsConfigures[];
}

export enum MenuMode {
  expand,
  unexpand
}

export const HRPositionLevelStyle: { [key: number]: { label: string } } = {
  5: { label: "Junior engineer" },
  10: { label: "Intermediate engineer" },
  15: { label: "Senior engineer" },
  20: { label: "Staff engineer" }
};

// export enum TalentCrossTime {
//   unknown = 0,
//   from_6_to_9 = 1,
//   from_20_to_2 = 2,
//   both = 3,
//   none = 4
// }

// export interface HRCandidateInterview {
//   id?: number;
//   candidate_id?: number;
//   enterprise_id?: number;
//   employment_id?: number;
//   created_at?: string;
//   updated_at?: string;
//   status?: string;
//   scheduling_url?: string;
//   name?: string;
//   start_time?: string;
//   end_time?: string;
//   join_url?: string;
//   password?: string;
//   meeting_id?: number;
// }

// export interface HREmploymentShare {
//   id?: number;
//   parent_id?: number;
//   created_at?: string;
//   enterprise_id?: number;
//   enterprise?: Enterprise;
//   share_code?: string;
//   shared_by_user_id?: number;
//   shared_by_guest_email?: string;
// }

// export enum EmploymentExpectFinishAt {
//   Immediately = 10, // immediately
//   In2Weeks = 20, // in 1~2weeks
//   After2Weeks = 30, // more than 2weeks from now
//   Unknown = 99 // i will decide later
// }

// export const EmploymentExpectFinishAtStyle: { [key: number]: { label: string } } = {
//   10: { label: "Immediately" },
//   20: { label: "In 1~2 weeks" },
//   30: { label: "More than 2 weeks from now" },
//   99: { label: "I will decide later" }
// };

//项目日报
// export interface ProjectDaily {
//   avatar?: string;
//   daily_work: ProjectDailyWork;
//   is_internal?: boolean;
//   is_tpm?: boolean;
//   null_reason?: number; //0正常 1 no task 2 miss
//   user_id?: number;
//   user_name?: string;
// }

// export interface ProjectDailyWork {
//   date?: string;
//   delayed_days?: number;
//   experience?: string;
//   id?: number;
//   no_task_reason?: string;
//   reject_reason?: string;
//   status?: string;
//   team?: ProjectDailyWorkTeam;
//   unread_users?: number[];
//   user?: GUser;
//   work_items?: ProjectDailyWorkWorkItems[];
// }

// export interface ProjectDailyWorkTeam {
//   id?: number;
//   name?: string;
// }

// export interface ProjectDailyWorkWorkItems {
//   actual_end_time?: string;
//   actual_start_time?: string;
//   actual_workload?: number;
//   description?: string;
//   estimated_end_time?: string;
//   estimated_start_time?: string;
//   estimated_workload?: number;
//   id?: number;
//   uid?: string;
//   name?: string;
//   progress?: number;
//   remarks?: string;
//   // requirement?: Requirement;
//   sprint?: Sprint;
//   status?: number;
//   task?: Task;
//   time_spent?: number;
// }

// export enum ProgressStatus {
//   todo = 0, // 未开始
//   ongoing = 1, // 进行中
//   done = 2 // 正常完成
// }

export const projectDistributionMaxLength = 2000;

export interface TimesheetReviewerConfig {
  default_reviewer?: User;
  default_reviewer_id?: number;
  members?: {
    reviewer?: User;
    reviewer_id?: number;
    set_reviewer_method?: TimesheetSetReviewerMethod;
    user?: User;
    user_id?: number;
  }[];
}

// export interface TimesheetReviewerConfigRequest {
//   default_reviewer_id?: number | null;
//   members?: {
//     reviewer_id?: number | null;
//     set_reviewer_method?: TimesheetSetReviewerMethod;
//     user_id?: number;
//   }[];
// }

// export enum TimesheetSetReviewerMethod {
//   default = "default",
//   custom = "custom"
// }

// export type HireInfo = {
//   id: number;
//   talent: {
//     id: number;
//     user_id: number;
//     full_name: string;
//     share_id: string;
//   };
//   position: {
//     id: number;
//     level: number;
//     title: string;
//     tag_id: number;
//     tag_name: string;
//     category_code: string;
//   };
//   employment_mode: EmployMode;
//   client_email: string;
//   currency_type: string;
// };

export enum POST_OFFER_VERSION {
  OLD = "v1",
  NEW = "v2"
}

export enum JIRA_LOG_TYPE {
  created = 0,
  statuschanged = 1
}

export interface JiraEnterpriseConfig {
  id: number;
  api_token?: string;
  api_user?: string;
  domain?: string;
  enabled: boolean;
}

export interface GitLabEnterpriseConfig {
  id: number;
  api_token?: string;
  domain?: string;
  enabled: boolean;
}

export interface JiraConnectInfo {
  api_user?: string;
  domain?: string;
  enabled?: boolean;
  jira_account_id?: string;
  jira_email?: string;
  jira_username?: string;
}
export interface JiraLog {
  event_type?: JIRA_LOG_TYPE;
  summary?: string;
  issue_url?: string;
  status_from?: string;
  status_to?: string;
}

export class JiraLogStatistics {
  created: number = 0;
  to_do: number = 0;
  invalid: number = 0;
  in_review: number = 0;
  in_progress: number = 0;
  unrepeatable: number = 0;
  resolved: number = 0;
  done: number = 0;
}

export interface GitLabConnectInfo {
  id: number;
  gitlab_id?: string;
  name?: string;
  username?: string;
  avatar_url?: string;
  email?: string;
  domain?: string;
  extend_emails?: string[];
}
export interface GitLog {
  created_at?: string;
  message?: string;
  commit_id?: string;
  web_url?: string;
  additions?: number;
  deletions?: number;
  gitlab_project: {
    name_with_namespace?: string;
    web_url?: string;
  };
}

export class GitLogStatistics {
  additions: number = 0;
  deletions: number = 0;
}

export interface GeneralConnectInfo {
  profile: any;
  email: string;
  valid: boolean;
  scopes: string[];
}

export interface GeneralCalendar {
  calendar_id: string;
  summary: string;
  description: string;
  timezone: string;
  color: string;
  enabled: boolean;
}

export enum SummaryTaskType {
  preview = "preview",
  edit = "edit"
}

export interface CustomSuccessType {
  name?: string;
  email?: string;
  is_internal?: boolean;
  groups: string[];
  groups_split: {
    level0: string[];
    level1: string[];
    level2: string[];
  };
  workingday_count?: number;
  daily_wirted_days?: number;
  daily_wirted_rate?: string;
  daily_write_on_day?: number;
  daily_write_on_day_rate?: string;
  daily_reviewed_days?: number;
  daily_reviewed_rate?: string;
  daily_passed_days?: number;
  daily_passed_rate?: string;

  total_daily_item_count?: number;
  remarked_daily_item_count?: number;
  remarked_daily_item_rate?: string;

  submitted_weeks?: number;
  need_weekly_count?: number;
  submitted_weeks_rate?: string;

  ontime_mileston?: number;
  pland_mileston?: number;
  delayed_mileston?: number;
  ontime_mileston_rate?: string;
  delayed_mileston_rate?: string;

  total_tasks?: number;
  planed_tasks?: number;
  planed_tasks_rate?: string;

  vo_days_count?: number;
  online_duration_total?: number;
  meeting_duration_total?: number;
  vo_avg_online_duration?: number;
}

export enum NotificationCategory {
  HireMessage = 1,
  MentionsAndCommentsMessage = 5,
  TaskUpdateMessage = 8,
  ReminderUpdateWeeklyMessage = 10,
  ReminderWriteDailyMessage = 15,
  WeeklyMessage = 20
}

// export enum EnterpriseSettingType {
//   REMIND_OPEN_VIRTUAL_DESKTOP = 1, //'提醒打开虚拟工位'
//   REMIND_SET_WORK_METHOD = 2, // '提醒设置工作方式'
//   HIRE_LEAD = 3, // '招聘线索配置'
//   BUSY_HOURS_RANGE = 4, //'繁忙程度时间范围配置'
//   VIRTUAL_OFFICE_SCREENSHOT = 5 // '虚拟办公室截图'
// }

export interface EnterpriseSetting {
  id?: number;
  enterprise?: number;
  type?: EnterpriseSettingType;
  setting?: any;
  switch?: boolean;
}

export enum WorkSaturationStyle {
  Free = "rgba(99, 114, 139, 0.08)",
  Light = "rgba(14, 164, 125, 0.2)",
  Normal = "#14B087",
  Busy = "#FF7481"
}

export enum WorkSaturationStatus {
  Free = "free",
  Light = "light",
  Normal = "normal",
  Busy = "busy"
}

export enum ProductMode {
  marketplace = "marketplace",
  metawork = "metawork"
}

export interface CopilotChatError {
  message?: string;
  type?: string;
  param?: string;
  code?: string;
}
export interface CopilotChat {
  query?: string;
  answer?: string | React.ReactNode;
  created_at?: string;
  conversion_id?: string;
  error?: string | CopilotChatError;
  retryable?: boolean;
  context_cleared?: boolean;
  id?: number;
}

export interface CopilotChatContext {
  conversion_id?: string;
  history?: CopilotChat[];
  org_id?: number;
}
export interface HelpDoc {
  updated_at?: string;
  doc_type?: string;
  content?: {
    en_US?: HelpDocContent;
    zh_CN?: HelpDocContent;
  };
}
export interface HelpDocContent {
  title?: string;
  content?: string;
}

export interface AppUsage {
  id?: number;
  app_name?: string;
  icon?: string;
  duration?: number;
  categorization?: AppCategory;
  feature?: EnterpriseAppFeature;
  productivity?: AppUsageProductivityType;
  old_duration?: number;
}

export interface AppTimelineItem {
  hour?: number;
  apps?: AppUsage[];
}

export interface AppUsageProductivityData {
  totalDuration?: number;
  productivity?: AppUsageProductivityType;
  groupName?: string;
  apps?: AppUsage[];
  color?: string;
  old_duration?: number;
}

export interface AppUsageCategorizationData {
  categorizationId?: number;
  totalDuration?: number;
  productivity?: AppUsageProductivityType;
  groupName?: string;
  apps?: AppUsage[];
  color?: string;
  old_duration?: number;
}

export interface CalDAVCalendar {
  id?: number;
  name?: string;
  url?: string;
  color?: string;
  enabled?: boolean;
  description?: string;
}

export interface CalDAVUser {
  id?: number;
  enterprise_id?: number;
  user_id?: number;
  alias?: string;
  protocol?: string;
  domain?: string;
  url?: string;
  username?: string;
  password?: string;
  calendars?: CalDAVCalendar[];
}

export enum ThirdPartyIntegrationSceneEnum {
  SIGN_IN = "sign_in",
  CALENDAR = "calendar",
  TASK = "task"
}

export enum ThirdPartyIntegrationPlatformEnum {
  FEISHU = "feishu",
  GOOGLE = "google",
  MICROSOFT = "microsoft",
  JIRA = "jira",
  GITLAB = "gitlab",
  CALDAV = "caldav"
}

export interface MicrosoftCalendar {
  id?: number;
  calendar_id?: string;
  summary?: string;
  description?: string;
  timezone?: string;
  color?: string;
  enabled?: boolean;
}

export interface MicrosoftUser {
  id?: number;
  profile?: any;
  email?: string;
  scene?: ThirdPartyIntegrationSceneEnum;
  calendars?: MicrosoftCalendar[];
  name?: string;
}

export interface GoogleCalendar {
  id?: number;
  calendar_id?: string;
  summary?: string;
  description?: string;
  timezone?: string;
  color?: string;
  enabled?: boolean;
}

export interface GoogleUser {
  id?: number;
  profile?: any;
  email?: string;
  scene?: ThirdPartyIntegrationSceneEnum;
  calendars?: GoogleCalendar[];
  name?: string;
}

export interface DailyAppStat {
  community_category_duration?: number;
  core_work_duration?: number;
  date?: string;
  productivity_duration?: number;
}

export interface DailyAppUsage {
  stat?: DailyAppStat[];
  user?: GUser;
}

export interface VirtualOfficeStat {
  date: string;
  meeting_duration: number;
  online_duration: number;
  rest_duration: number;
  offline_duration: number;
}

export interface VirtualOfficeUsage {
  user: GUser;
  stat: VirtualOfficeStat[];
}

export interface TaskLoggedStat {
  id: number;
  date: string;
  time_spent: number;
  is_tpm: 0 | 1;
  daily_work_status: 0 | 1 | 2;
  work_duration: number;
  number_of_tasks: number;
  estimate_work_duration: number;
}

export interface TaskLoggedUsage {
  user: User & { is_internal: boolean; employment_mode: 0 | 1 | 2 };
  statistic_data: TaskLoggedStat[];
}

export enum WorkingStatusEmum {
  OVERLOAD = "overload",
  UNDERLOAD = "underload",
  LOW_CONCENTRATION = "low_concentration",
  LOW_CORE_WORK = "low_core"
}

export interface ProjectComparison {
  project_id: number;
  project_name: string;
  duration: number;
  old_duration: number;
  duration_percent: number;
  date_duration_list: { date: string; duration: number }[];
  task_list: { id: number; name: string; duration: number; sprint_id: number; sprint_name: string }[];
}

export interface WorkComparisonData {
  is_workday: boolean;
  active_duration: number;
  productive_work_duration: number;
  core_work_duration: number;
  communication_duration: number;
  daily_report_duration: number;
}

export interface WorkComparison {
  data?: {
    [key: string]: WorkComparisonData;
  };
  number_of_members: number;
  old_active_duration: number;
  old_productive_work_duration: number;
  old_core_work_duration: number;
  old_communication_duration: number;
  old_daily_report_duration: number;
}

export enum PlanType {
  STARTER_PLAN = "starter",
  TRIAL_PLAN = "trial",
  GROWTH_PLAN = "growth",
  PRO_PLAN = "pro",
  ENTERPRISE_PLAN = "enterprise"
}

export const PlanTypeStyle: { [key: number | string]: { label: string; color: string; bgColor: string; className?: string } } = {
  [PlanType.STARTER_PLAN]: {
    label: "STARTER_PLAN",
    color: "#15AF87",
    bgColor: "rgba(21, 175, 135,0.1)"
  },
  [PlanType.TRIAL_PLAN]: {
    label: "TRIAL_PLAN",
    color: "#FFA767",
    bgColor: "rgba(255, 167, 103,0.1)"
  },
  [PlanType.GROWTH_PLAN]: {
    label: "GROWTH_PLAN",
    color: "#A772FF",
    bgColor: "rgba(164, 114, 255,0.1)"
  },
  [PlanType.PRO_PLAN]: {
    label: "PRO_PLAN",
    color: "#5065EE",
    bgColor: "rgba(80, 101, 238,0.1)"
  },
  [PlanType.ENTERPRISE_PLAN]: {
    label: "ENTERPRISE_PLAN",
    color: "#C0A07B",
    bgColor: "rgba(192, 160, 123,0.1)"
  },
  Expired: {
    label: "Expired",
    color: "#ffffff",
    bgColor: "#FF9573"
  }
};

export interface VotingApp {
  id?: number;
  name?: string;
  icon?: string;
  count?: number;
  voting_user_id?: number;
}

export interface ClickUpConnectInfo {
  client_id?: string;
  client_secret?: string;
  has_access_token?: boolean;
  third_party_id?: string;
  third_party_username?: string;
  third_party_email?: string;
}
