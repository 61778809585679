import { useMyWorkStation } from "./useMyWorkStation";
import { WorkStationStatus } from "@gear/business";
import { useEffect, useRef } from "react";
import moment from "moment-timezone";

/**
 * 会议倒计时检测，恢复在线状态
 */
export function useMeetingDetected() {
  const logTag = "useMeetingDetected";
  const { myWorkStation, myWorkStationStatus, updateMyWorkStationStatus } = useMyWorkStation();

  const meetingCheckTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    cleanTimeout();
    if (myWorkStationStatus === WorkStationStatus.meeting && myWorkStation?.status_end_time) {
      const duration = moment.duration(moment(myWorkStation?.status_end_time).diff(moment())).asMilliseconds();
      console.log(logTag, myWorkStation?.status_end_time, "meeting duration", duration);
      if (duration > 0) {
        // 日程倒计时
        meetingCheckTimeoutRef.current = setTimeout(() => {
          console.log(logTag, myWorkStation?.status_end_time, "change to online by timeout");
          updateMyWorkStationStatus({ status: WorkStationStatus.online });
          cleanTimeout();
        }, duration);
      } else {
        console.log(logTag, myWorkStation?.status_end_time, "change to online");
        updateMyWorkStationStatus({ status: WorkStationStatus.online });
      }
    }
    return () => {
      cleanTimeout();
    };
  }, [myWorkStation?.status_end_time, myWorkStationStatus]);

  const cleanTimeout = () => {
    if (meetingCheckTimeoutRef.current) {
      clearTimeout(meetingCheckTimeoutRef.current);
      meetingCheckTimeoutRef.current = undefined;
    }
  };
}
