/**
 * 获取用户权限
 */

import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useGAuth, useGStores } from "@gear/business";
import { GPermissions as Permissions } from "@gear/business";
import { allCanNextRouter, routerRolesObject } from "@/router/routes";

export const useGetRoles = () => {
  const { userStore } = useGStores();
  const { checkPermission } = useGAuth();

  return useMemo(() => {
    const chageDate = +new Date();

    const result = {
      projectManegementRoles: checkPermission?.([Permissions.e_project_management]),
      hiringRoles: checkPermission?.([Permissions.e_hiring]),
      companyInfoRoles: checkPermission?.([Permissions.e_company_info]),
      metaworkSettingRoles: checkPermission?.([Permissions.e_metawork_settings]),
      contractsRoles: checkPermission?.([Permissions.e_hiring]),
      billingRoles: checkPermission?.([Permissions.e_billing]),
      membersRoles: checkPermission?.([Permissions.e_members]),
      rolesRoles: checkPermission?.([Permissions.e_roles]),
      groupsRoles: checkPermission?.([Permissions.e_groups]),
      viewAllProjectRoles: checkPermission?.([Permissions.e_project_view]),
      insightsRoles: checkPermission?.([Permissions.e_workstation_screenshot]),
      chageDate
    };
    return result;
  }, [userStore, userStore?.user, userStore?.user?.permissions]);
};

const findPathRoleName = (pathname: string) => {
  const router = routerRolesObject.find((item) => item.path === pathname);
  return router?.permissions || [];
};

/**
 * 获取用户的校验权限后的第一个地址
 */

export const useGetRolesRouter = () => {
  const { checkPermission } = useGAuth();
  const { projectManegementRoles, hiringRoles, membersRoles, rolesRoles, chageDate, billingRoles } = useGetRoles();
  const location = useLocation();

  const { teamStore } = useGStores();
  return useMemo(() => {
    const { pathname } = location;
    let routerLink: string | undefined = undefined;
    const getFirstLink = () => {
      if (teamStore?.teamsMeta?.length) {
        if (projectManegementRoles) return "/";
        if (hiringRoles) {
          const { uuid } = teamStore.teamsMeta[0];
          return "/team/detail/team-user?tid=" + uuid;
        }
        if (hiringRoles) return "/bill";
        if (membersRoles) return "/members";
        if (rolesRoles) return "/system/role/edit";
        return "/no-roles";
      }
    };
    if (!allCanNextRouter.includes(pathname)) {
      if (chageDate) {
        // 先判断档当前访问的路由，有没有权限
        const roleName = findPathRoleName(pathname);
        const hasRole = checkPermission(roleName as Permissions[]);
        if (!hasRole) {
          routerLink = getFirstLink();
        }
      }
    }
    return {
      routerLink,
      getFirstLink
    };
  }, [chageDate, teamStore?.teamsMeta]);
};
