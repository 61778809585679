import { GTask, GUser, GGanttDataModel, GGanttTreeRecord, preHandleTreeRecords, handleGanttData, GTeam, GSprint, WorkStationStatus } from "@gear/business";
import { TaskGanttResponse, UNASSIGNEE_ID } from "../common/task_gantt_view_types";
import { AppUsageProductivityType } from "./const";

export function preHandleTaskGanttTreeRecord(data: TaskGanttResponse) {
  const records: GGanttTreeRecord[] = [];
  const children: GGanttTreeRecord[] = [];
  data.data?.map((item, index) => {
    let _children: GGanttTreeRecord[] = [];
    if (item.tasks) {
      _children = handleGanttData(item.tasks);
    }
    const _record: GGanttTreeRecord & { team?: GTeam; sprint?: GSprint } = {
      team: item.team,
      sprint: item.sprint,
      model: GGanttDataModel.Path,
      key: `${GGanttDataModel.Path}_${index}`,
      childCount: _children.length,
      children: _children
    };
    children.push(_record);
  });
  // 任务没有负责人的话，统一归类N/A组
  const record: GGanttTreeRecord = {
    source: data.user || { id: UNASSIGNEE_ID },
    model: GGanttDataModel.User,
    key: `${GGanttDataModel.User}_${data.user?.id || 0}`,
    title: data.user?.full_name || "N/A",
    childCount: children.length,
    children
  };
  records.push(record);
  return records;
}

/**
 * 同步source的数据到甘特图中
 * @param records
 */
export function syncSource(records: GGanttTreeRecord[]) {
  records.map((item) => {
    item.title = item.model === GGanttDataModel.User ? (item.source as GUser)?.full_name || "N/A" : (item.source as GTask)?.name;
    item.childCount = item.children.length;
    if (item.children?.length) {
      syncSource(item.children);
    }
  });
}

const langMapShortKey = new Map([
  ["en", "en-us"],
  ["zh", "zh-cn"]
]);

const langMapLongKey = new Map([
  ["en-us", "en"],
  ["es", "es"],
  ["zh-cn", "zh"],
  ["zh-tw", "zh-tw"]
]);

// 语言设置太乱了，在重构好之前，先做一个折中方案，转换一下语言的简写和全称
export function getFullLangName(shortLangName: string) {
  return langMapShortKey.get(shortLangName) || shortLangName;
}

export function getShortLangName(fullLangName: string) {
  return langMapLongKey.get(fullLangName.toLocaleLowerCase()) || fullLangName;
}

const supportedLangs = ["zh", "es", "en", "zh-tw"];

export function isLangSupported(lang: string) {
  return supportedLangs.indexOf(lang) >= 0;
}

export function setupLanguage(language: string | null) {
  if (!language || ["en", "zh", "zh-tw", "es"].indexOf(language) < 0) {
    // 链接里且i18n没有配置过语言，使用用户的语言
    language = "en";
    if (navigator.language) {
      const shortName = getShortLangName(navigator.language);
      if (isLangSupported(shortName)) {
        language = shortName;
      }
    }
    window.localStorage.setItem("language", language);
  }
  return language;
}

export function isOnlineStatus(status: WorkStationStatus | undefined) {
  return status === WorkStationStatus.online || status === WorkStationStatus.meeting;
}

export const STATUS_TEXT = new Map([
  [WorkStationStatus.online, "Active"],
  [WorkStationStatus.meeting, "Meeting"],
  [WorkStationStatus.rest, "Rest"],
  [WorkStationStatus.away, "Away"],
  [WorkStationStatus.offline, "Offline"]
]);

export function getProductivityName(type: AppUsageProductivityType) {
  if (type === AppUsageProductivityType.core_work) {
    return "Core work";
  } else if (type === AppUsageProductivityType.non_core_work) {
    return "Non-core work";
  } else if (type === AppUsageProductivityType.non_work) {
    return "Non-work";
  } else {
    return "Unknown";
  }
}

export function getProductivityColor(type: AppUsageProductivityType) {
  if (type === AppUsageProductivityType.core_work) {
    return "#5066EE";
  } else if (type === AppUsageProductivityType.non_core_work) {
    return "#93A1F3";
  } else if (type === AppUsageProductivityType.non_work) {
    return "#FFB663";
  } else {
    return "";
  }
}
