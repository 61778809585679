// 浅色在上深色在下
export enum Color {
  blue200 = "#E4EBFF",
  blue250 = "#7091FF",
  blue260 = "#5c9eff",
  blue300 = "#4B84FF",
  blue500 = "#2154FF",
  blue700 = "#1242D6",

  red500 = "#FF2D2D",
  red522 = "#F5222D",

  orange200 = "#FFE9DE",
  orange300 = "#FFC757",
  orange500 = "#FD9F42",
  orange700 = "#FF5F00",

  green200 = "#e9f7dc",
  green300 = "#7ED321",
  green500 = "#80CD2B",

  purple500 = "#967ADC",

  white = "#ffffff",

  gray100 = "#FAFAFB",
  gray200 = "#F6F7F9",
  gray250 = "#F6F8FA",
  gray300 = "#F1F1F1",
  gray400 = "#ECECEC",
  gray450 = "#D8D8D8",
  gray500 = "#D2D2D5",
  gray600 = "#B4BAC6",
  gray700 = "#9B9B9B",
  gray800 = "#6F6F6F",

  drakBlack = "#191C31",
  black = "#37383B",

  transparent = "transparent",
  current = "currentColor"
}

export enum Rounded {
  none = "0",
  lg = "0.5rem",
  md = "0.375rem",
  sm = "0.25rem"
}

export enum Pad {
  none = "0px",
  sm = "8px",
  xs = "4px",
  md = "12px",
  lg = "16px",
  lg8 = "8px",
  xl = "20px",
  xxl = "24px",
  xl3 = "28px",
  xl4 = "32px"
}

export enum TextSize {
  xl = "1.25rem",
  lg = "1.125rem",
  md = "1rem",
  sm = "0.875rem",
  xs = "0.75rem"
}

export enum TextWeight {
  hairline = 100,
  thin = 200,
  light = 300,
  normal = 400,
  medium = 500,
  semibold = 600,
  bold = 700,
  extrabold = 800,
  black = 900
}

export enum PageSize {
  p10 = 10,
  p15 = 15,
  p20 = 20,
  p30 = 30,
  p50 = 50,
  Infinity = 10000
}

export const timePerSecond = 1000;
export const timePerMinite = timePerSecond * 60;
export const timePerHour = timePerMinite * 60;
export const timePerDay = timePerHour * 24;

export const UIFormat = "YYYY.MM.DD";
export const dateUIFormat = "YYYY.MM.DD";
export const timeUIFormat = "YYYY.MM.DD HH:mm";
export const dateFormat = "YYYY-MM-DD";
export const dateUIFormatEn = "ll";
export const momentTypeHM = "HH:mm";
export const momentTypeHMEn = "H:mm";

export const momentTypeMMMDDEn = "MMM D"; // May 18
export const momentTypeMMMDDHHMMEn = "MMM D, HH:mm"; // May 18, 12:00
export const momentTypeMMMDDHHMM = "MM/DD HH:mm"; // 05/18 12:00
export const momentTypeLLEn = "LL"; //May 18, 2022
export const momentTypeYMDHMEn = "LL, HH:mm"; //May 18, 2022, 12:00
export const momentTypeYMDHM = "YYYY/MM/DD HH:mm"; // 2022/05/08 12:00

/**
 * monent.isoWeekday 返回的结果为 1-7 下面数组 [1]~[7]对应翻译
 * [0]的用处 如果你获得某一天的 isoWeekday, 要计算出之后30天的每天的星期, 则要使用以下方法
 * weekdayLetterMap[(start.isoWeekday() + n) % 7] 此时的周日为 0
 */
export const weekdayLetterMap = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
