import React from 'react';
import { AccountAPI, GCity, GUser, SettingAPI } from "@gear/business";
import { translate, useGConfirm } from "@gear/ui";
import { message } from "antd";
import { axiosWrapper } from "@/utils";

/**
 *  一天检测一次时区是否有变化
 */
export function useTimezone() {
  const { confirm } = useGConfirm();

  const _getCityAndAShowConfirm = (user_id: number) => {
    // localStorage只保存了中文的城市信息，重新获取一次
    SettingAPI.getCurrentCity("").then((res) => {
      const city = res.data;
      if (city && city.id) {
        _showConfirm(user_id, city);
      }
    })
  }

  const _showConfirm = (user_id: number, city?: GCity) => {
    if (!city?.id) {
      return;
    }
    const standardTime = city.timezone?.standard_time || "--";
    const offset = city.timezone?.offset;
    const cityLabel = `${city.name} - ${city.country?.name}`;
    confirm({
      title: translate("Please confirm your city and time zone"),
      className: "g-confirm timezone-message-confirm",
      okText: translate("Confirm"),
      cancelText: translate("Change"),
      onOk: () => {
        updateUserCity(user_id, city.id);
      },
      onCancel: (...args: any[]) => {
        // 按 ESC 不弹出时区抽屉
        if (args.length === 0) {
          window.rootStore.configStore?.updateTimeZoneDialogVisible(true);
        }
      },
      content: (
        <div className={"timezone-message-container"}>
          <div className={"city-info"}>
            <span className={"label"}>{translate("Closest City:")}</span>
            <span className={"value"}>{cityLabel}</span>
          </div>
          <div className={"city-info"}>
            <span className={"label"}>{translate("Timezone")}</span>
            <span className={"value"}>{standardTime} {offset !== undefined ? `(UTC ${offset > 0 ? "+" : ""}${offset})` : "--"}</span>
          </div>
        </div>
      )
    });
    SettingAPI.postUserCity({ city_id: city.id});
  };

  const updateUserCity = (user_id: number, city_id: number) => {
    axiosWrapper(AccountAPI.patchUser(user_id, {city: city_id})).then(({ isSuccess, result: { data } }) => {
      if (isSuccess) {
        SettingAPI.postUserCity({ city_id: city_id}).then(() => {
          SettingAPI.getCurrentCity()
            .then((res) => {
              const country = res.data?.country?.name;
              if (country) {
                res.data.t = +new Date();
                localStorage.setItem("city", JSON.stringify(res.data));
              }
            })
            .finally(() => {
              window.location.reload();
            });
        });
      }
    });
  }

  const checkTimezone = (user: GUser, forced: boolean = false) => {
    // forced 表示从 app 跳转过来，强制弹出时区抽屉
    setTimeout(() => {
      if (forced) {
        _getCityAndAShowConfirm(user.id!);
      } else if (user.city_id) {
        SettingAPI.getUserCity().then((res: any) => {
          if (!!res?.data?.city_id) {
            const now = +new Date();
            const lastPromptTime = Number(res?.data?.time || 0) * 1000;
            const lastCityId = res?.data?.city_id || 0;
            if (lastCityId !== user.city_id && now - lastPromptTime > 1000 * 3600 * 24) {
              _getCityAndAShowConfirm(user.id!);
            }
          } else {
            SettingAPI.postUserCity({ city_id: user.city_id});
          }
        })
      } else {
        // 如果还没保存过城市信息，直接保存
        const currentCityId = JSON.parse(localStorage.getItem("city") || "{}")?.id;
        if (!!currentCityId) {
          updateUserCity(user.id!, currentCityId);
        } else {
          SettingAPI.getCurrentCity().then((res) => {
            if (res?.data?.id) {
              updateUserCity(user.id!, res?.data?.id);
              localStorage.setItem("city", JSON.stringify(res.data));
            }
          })
        }
      }
    }, forced ? 1000 : 3000)
  };

  return {
    checkTimezone
  }
}
