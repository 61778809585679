import React, { useMemo, useState } from "react";
import { GButton, GSelect, translate } from "@gear/ui";
import { ReactComponent as IconExpried } from "./assets/expried_icon.svg";
import { GCollection, WorkStationStatus } from "@gear/business";
import { SelectSubscritionModel } from "@/modals/SelectSubscriptionModel";
import { CompareAllFeaturesModal } from "@/modals/CompareAllFeaturesModal";
import { useProduct } from "@/hooks/useProduct";
import SubscriptionApi from "@/apis/subscriptionApi";
import { HeaderAvatar } from "@/views/VirtualOffice/Header/HeaderAvatar";
import { Header } from "@/components/Header";
import GlobalQuickOperation from "@/templates/GCardPage/GlobalQuickOperation";
import { LanguageSwitchEnter } from "@/templates/HomepageTemplate/components/LanguageSwitchEnter";
import { VoiceMessagesTooltip } from "@/templates/HomepageTemplate/components/VoiceMessagesTooltip";
import { ReactComponent as OnlineDot } from "@/templates/HomepageTemplate/assets/online_dot.svg";
import { DownloadEnter } from "@/templates/HomepageTemplate/components/DownloadEnter";
import { Notifications } from "@/templates/HomepageTemplate/components/Notifications";
import { useVirtualOffice } from "@/views/VirtualOffice/hooks/useVirtualOffice";
import { useHistory } from "react-router";
import "./index.scss";

interface SubscriptionExpiredProps {
  title?: string;
  isChangeEnterprise?: boolean;
  isRootRouter?: boolean;
}
export const SubscriptionExpired: React.FC<SubscriptionExpiredProps> = ({ isRootRouter, isChangeEnterprise = false, title, ...props }) => {
  const { metaworkClosedReason, hasSubscriptionOrder } = useProduct();
  const history = useHistory();
  const is_disabled = true;

  const [selectSubscriptionModelVisible, setSelectSubscriptionModelVisible] = useState(false);
  const [compareSubscriptionFeaturesModalVisible, setCompareSubscriptionFeaturesModalVisible] = useState(false);

  const selectPlan = () => {
    if (!hasSubscriptionOrder()) {
      setSelectSubscriptionModelVisible(true);
    } else {
      SubscriptionApi.createBillingPortalLink(window.location.href).then((res) => {
        if (res.data.link) {
          window.location.href = res.data.link;
        }
      });
    }
  };

  const { allWorkStations } = useVirtualOffice();
  const onlineCount = useMemo(() => {
    return (
      allWorkStations?.filter((workStation) => workStation?.status === WorkStationStatus.online || workStation?.status === WorkStationStatus.meeting)?.length ||
      0
    );
  }, [allWorkStations]);
  const customRightContent = () => {
    return (
      <div className="custom-right-content" data-disabled={is_disabled}>
        <div style={{ marginRight: "8px", display: "flex", alignItems: "center" }}>
          <GCollection name="e_global_create_task" type={"event"} propagation={false}>
            <GlobalQuickOperation disabled={is_disabled} handleTaskCheck={() => {}} />
          </GCollection>
        </div>

        <LanguageSwitchEnter style={{ marginRight: "12px" }} disabled={is_disabled} />
        <div className="pointer custom-right-content-online-layout" style={{ marginRight: "12px", cursor: is_disabled ? "no-drop" : "inherit" }}>
          <VoiceMessagesTooltip style={{ marginRight: "8px" }} disabled={is_disabled} />
          <div className="interval" style={{ marginRight: "12px" }} />
          <OnlineDot className="online-dot" style={{ marginRight: "4px" }} />
          <span className="online-number" style={{ marginRight: "4px", marginLeft: "4px" }}>
            {onlineCount}/{allWorkStations.length || 0}
          </span>
        </div>
        <DownloadEnter style={{ marginRight: "12px" }} disabled={is_disabled} />
        <Notifications disabled={is_disabled} />
      </div>
    );
  };

  const getTitle = () => {
    if (metaworkClosedReason === "trial_expired") {
      return translate("Your free trial period has ended");
    } else if (metaworkClosedReason === "plan_expired") {
      return translate("Your plan has expired");
    } else if (metaworkClosedReason === "member_limit") {
      return translate("Please update your plan");
    }
    return metaworkClosedReason;
  };

  const getDesc = () => {
    if (metaworkClosedReason === "trial_expired") {
      return translate("Thank you for trying Metawork, please select your plan or contact a sales representative.");
    } else if (metaworkClosedReason === "plan_expired") {
      return translate(
        "To continue using Metawork, please click the button below to select a plan. For any questions, contact us via the online customer service in the bottom right corner."
      );
    } else if (metaworkClosedReason === "member_limit") {
      return translate(
        "The current team size has exceeded the maximum limit of the plan, please upgrade your plan. For any questions, contact us via the online customer service in the bottom right corner."
      );
    }
  };

  const getButtonText = () => {
    if (metaworkClosedReason === "trial_expired" || metaworkClosedReason === "plan_expired") {
      return translate("Select your plan");
    } else if (metaworkClosedReason === "member_limit") {
      return translate("Update plan");
    }
  };

  return (
    <div className="subscription-expired-page">
      {isRootRouter && (
        <Header
          logoutNextUrl="/"
          customRight={customRightContent}
          customAvatar={<HeaderAvatar />}
          onClickLogo={() => {
            history.push("/");
          }}
        />
      )}
      <div className="subscription-expired-center">
        <div className="plan-expired-page-title">{translate(title!)}</div>
        <IconExpried />
        <div className="plan-expired-title">{getTitle()}</div>
        <div className="plan-expired-dec">{getDesc()}</div>
        <GButton
          type={"primary"}
          style={{
            height: "40px",
            marginTop: "30px"
          }}
          onClick={() => {
            console.log("expiredPage");
            selectPlan();
          }}
        >
          {getButtonText()}
        </GButton>
      </div>
      {selectSubscriptionModelVisible && (
        <SelectSubscritionModel
          visible={selectSubscriptionModelVisible}
          onClose={() => setSelectSubscriptionModelVisible(false)}
          onDisplayCompareFeaturesModal={() => {
            setSelectSubscriptionModelVisible(false);
            setCompareSubscriptionFeaturesModalVisible(true);
          }}
        />
      )}
      {compareSubscriptionFeaturesModalVisible && (
        <CompareAllFeaturesModal visible={compareSubscriptionFeaturesModalVisible} onCancel={() => setCompareSubscriptionFeaturesModalVisible(false)} />
      )}
    </div>
  );
};
