import { GButton, GTooltip } from "@gear/ui";
import { useEffect, useState } from "react";
import EditOperationModal from "./EditOperationModal";

import "./index.scss";
import { ASSIGNEE_NAME, useGStores } from "@gear/business";
import { UseStores } from "@/stores";
import { ReactComponent as AddIcon } from "./assets/add_icon.svg";
import { ReactComponent as AddTaskIcon } from "./assets/add_task_icon.svg";
import { Badge } from "antd";
import { inject } from "mobx-react";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { ROUTE_CONSTANTS } from "@/router/const";
import storage from "storejs";
import { useTranslation } from "react-i18next";
import moment from "moment";
/**
 * 全局快捷操作
 */
type GlobalQuickOperationType = {
  handleTaskCheck: () => void;
  disabled?: boolean;
};
const Index: React.FC<GlobalQuickOperationType> = ({ handleTaskCheck, disabled }) => {
  const [editOperationVisible, setEditOperationVisible] = useState(false);
  const { configStore, userStore } = useGStores<UseStores>();
  const history = useHistory();

  const toggleEditOperationVisible = (visible: boolean) => {
    setEditOperationVisible(visible);
  };
  useEffect(() => {
    if (userStore?.user?.id) {
      configStore.updatePersonalQuickOperationInfo(userStore?.user?.id);
    }
  }, [userStore?.user?.id]);

  const { t } = useTranslation();
  const {
    DAILY_REPORT = false,
    WRITE_DAILY_REPORT = false,
    MY_TASKS = false,
    ADD_TASK = false,
    ORGANIZATION_WEEKLY_REPORT = false
  } = configStore.globalQuickEntry;

  const dailyReportCallback = () => {
    history.push(`${ROUTE_CONSTANTS.DAILY_REPORTS}?date=${moment().format("YYYY-MM-DD")}`);
  };
  const writeDailyReportCallback = () => {
    history.push(ROUTE_CONSTANTS.MY_DAILY_REPORTS);
  };
  const myTaskCallback = () => {
    userStore?.user?.id && storage.set(ASSIGNEE_NAME, [userStore?.user?.id]);
    history.push(ROUTE_CONSTANTS.TASKS);
  };
  const organizationWeeklyReportCallback = () => {
    history.push(ROUTE_CONSTANTS.ORGANIZATION_WEEKLY_REPORT);
  };

  return (
    <div className={"global-quick-operation"}>
      {disabled ? (
        <div className="operation-button add-operation" data-disabled={true} />
      ) : (
        <GTooltip title={t("Set quick entry")} placement={"bottom"}>
          <div className="operation-button add-operation" onClick={() => toggleEditOperationVisible(true)} />
        </GTooltip>
      )}

      {DAILY_REPORT &&
        (disabled ? (
          <div className="operation-button daily-report" data-disabled={true} />
        ) : (
          <GTooltip title={t("Daily report")} placement={"bottom"}>
            <div className="operation-button daily-report" onClick={dailyReportCallback} />
          </GTooltip>
        ))}

      {WRITE_DAILY_REPORT &&
        (disabled ? (
          <Badge count={<AddIcon />} offset={[-8, 0]}>
            <div className="operation-button write-daily-report" data-disabled={true} />
          </Badge>
        ) : (
          <GTooltip title={t("Write daily report")} placement={"bottom"}>
            <Badge count={<AddIcon />} offset={[-8, 0]}>
              <div className="operation-button write-daily-report" onClick={writeDailyReportCallback} />
            </Badge>
          </GTooltip>
        ))}
      {MY_TASKS &&
        (disabled ? (
          <div className="operation-button my-tasks" data-disabled={true} />
        ) : (
          <GTooltip title={t("My tasks")} placement={"bottom"}>
            <div className="operation-button my-tasks" onClick={myTaskCallback} />
          </GTooltip>
        ))}
      {ADD_TASK &&
        (disabled ? (
          <Badge count={<AddTaskIcon />} offset={[-8, 0]}>
            <div className="operation-button my-tasks" data-disabled={true} />
          </Badge>
        ) : (
          <GTooltip title={t("Add task")} placement={"bottom"}>
            <Badge count={<AddTaskIcon />} offset={[-8, 0]}>
              <div className="operation-button my-tasks" onClick={handleTaskCheck} />
            </Badge>
          </GTooltip>
        ))}
      {ORGANIZATION_WEEKLY_REPORT &&
        (disabled ? (
          <div className="operation-button organization-weekly-report" data-disabled={true} />
        ) : (
          <GTooltip title={t("Organization Weekly report")} placement={"bottom"}>
            <div className="operation-button organization-weekly-report" onClick={organizationWeeklyReportCallback} />
          </GTooltip>
        ))}
      <EditOperationModal visible={editOperationVisible} setVisible={setEditOperationVisible} />
    </div>
  );
};

const GlobalQuickOperation = inject("configStore")(observer(Index));
export default GlobalQuickOperation;
