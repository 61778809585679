import { TeamSuspend } from "./../apis/types";
import { makeAutoObservable } from "mobx";
import { TeamApi } from "@gear/business";
import { Team } from "@gear/business";

class TeamStore {
  team?: Team;

  showCreateProject: boolean = false;
  updateCurrentEnterprise: boolean = false;
  draftProjectId: number | undefined | string = undefined;

  teamsMeta?: Team[] = [];
  constructor() {
    makeAutoObservable(this);
  }
  updateCurrentTeam(team: Team) {
    this.team = team;
  }

  // 修改团队名时,要更新
  fetchTeamsMeta() {
    TeamApi.getFavoriteProjects()
      .then((response) => {
        const teamsMeta = response.data.results || [];
        const transferMeta = teamsMeta?.sort((item: Team) => +TeamSuspend.includes(item.status!));
        this.teamsMeta = transferMeta;
      })
      .catch(() => {
        this.teamsMeta = [];
      });
  }

  setShowCreateProject(showCreateProject: boolean, draftProjectId?: number | string, isUpdate?: boolean) {
    if (isUpdate) {
      this.setUpdateCurrentEnterprise(true);
    }
    this.showCreateProject = showCreateProject;
    if (showCreateProject) {
      this.draftProjectId = draftProjectId;
    } else {
      this.draftProjectId = undefined;
      this.setUpdateCurrentEnterprise();
    }
  }
  setUpdateCurrentEnterprise(flag: boolean = false) {
    this.updateCurrentEnterprise = flag;
  }

  findTeamMeta(teamId: number) {
    return this.teamsMeta?.find((team) => team.id === teamId);
  }
}

export default TeamStore;
