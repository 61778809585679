import { AppArch, AppPlatformType } from "@/apis/types";
import { message } from "antd";
import { LanguageAbridgeType } from "@gear/ui";
import { getLanguage } from "./language";
import pinyin from "pinyin";
import { BaseSyntheticEvent } from "react";
import { DEFAULT_PAGE_SIZE, TYPES_OF_FETCHING } from "./const";

/**
 * 返回数据的类型
 * @param value 需要判断的值
 * @return string
 */
export const isTypeOf = <T>(value: T): string => {
  const resultType = Object.prototype.toString.call(value);
  const reslutOject: { [propName: string]: string } = {
    "[object Null]": "null",
    "[object Undefined]": "undefined",
    "[object String]": "string",
    "[object Number]": "number",
    "[object Boolean]": "boolean",
    "[object Function]": "function",
    "[object Date]": "date",
    "[object Array]": "array",
    "[object RegExp]": "regExp",
    "[object Object]": "object",
    "[object JSON]": "json",
    "[object Set]": "set",
    "[object Map]": "map",
    "[object Symbol]": "symbol",
    "[object Error]": "error"
  };
  return reslutOject[resultType];
};

/* 返回任意数据的原始类型 */
export const rawType = <T extends any>(value: T): any => {
  if (["date", "math", "error", "null", "undefined", "Proxy"].includes(isTypeOf(value))) {
    return value;
  } else {
    const Ctor = (value as any).constructor;
    return new Ctor();
  }
};

/**
 * 深度复制
 * @param value 需要深度赋值得值
 */
export const deepClone = <T extends any>(value: T): T => {
  let result = rawType(value);
  const type = isTypeOf(value);
  if (type === "array") {
    result = (value as Array<any>).map((element: any) => {
      element = deepClone(element);
      return element;
    });
  } else if (type === "object") {
    Object.keys(value as Record<string, any>).forEach((element) => {
      Reflect.set(result, element, deepClone((value as Record<string, any>)[element]));
    });
  } else if (type === "set") {
    (value as Set<any>).forEach((element: any) => {
      result.add(deepClone(element));
    });
  } else if (type === "map") {
    (value as Map<any, any>).forEach((value: any, key: any) => {
      result.set(key, deepClone(value));
    });
  } else if (["date", "math", "error", "null", "undefined"].includes(type)) {
    result = value;
  } else {
    result = (value as any).valueOf();
  }
  return result;
};

/**
 * 抖动处理
 * @param predicate 执行的函数 参数类型和返回的函数类型一致
 * @param wait 等待的时间 默认300ms
 * @param immediate 是否立即执行， 默认false
 * @returns (...arg: T) => void extends Function
 */
export const debounce = <T extends any[]>(predicate: (...arg: T) => void, wait = 300, immediate = false): ((...arg: T) => void) => {
  if (typeof predicate !== "function") throw new TypeError("Predicate must be a function");

  let time: number | null = null;
  let timestamp = 0;
  let args: T;

  const later = () => {
    const last = Date.now() - timestamp;
    if (last < wait && last > 0) {
      time = window.setTimeout(later, wait - last);
    } else {
      predicate.apply(this, args);
      window.clearTimeout(time as number);
      time = null;
    }
  };

  return (...arg: T) => {
    args = arg;
    if (immediate) {
      predicate.apply(this, args);
      return false;
    }
    timestamp = Date.now();
    if (!time) {
      time = window.setTimeout(later, wait);
    }
  };
};

/**
 * 对指定数据增加特殊符号，如%
 * @param value 需要处理的数据 array | object | string | number
 * @param predicate  需要执行判断的函数，如对value * 100 或增加千位符 默认为增加%号
 * @param prop 当处理的类型为数组或者对象时，需要处理的key，对象才生效 Array<string>
 * @retrun T
 */
export const addSymbol = <T extends any>(value: T, predicate = markValue, prop?: Array<string>): any => {
  if (isTypeOf(predicate) !== "function") {
    throw new TypeError("predicate must be a function");
  }

  const type = isTypeOf(value);
  let result: any;
  if (type === "string" || type === "number") {
    result = predicate(value as string | number) as string | number;
  } else if (["null", "undefined"].includes(type)) {
    result = 0;
  } else {
    if (type === "object") {
      result = deepClone(value);
      const keysArray = Object.keys(value as Record<string, any>);
      keysArray.forEach((key) => {
        if (prop?.includes(key)) Reflect.set(result as Record<string, any>, key, addSymbol((result as Record<string, any>)[key], predicate, prop));
      });
    } else {
      result = (value as Array<any>).map((ele) => {
        return addSymbol(ele, predicate, prop);
      });
    }
  }

  return result;
};

/**
 * 对指定内容增加特殊符号
 * @param value 需要处理的数据 number | string 可选为addSymbol的predicate参数
 * @param mark 需要增加的符号，默认为%
 * @retrun string
 */
export const markValue = (value: number | string, mark = "%"): string => {
  return value + mark;
};

/**
 * 对指定内容增加特殊符号
 * @param value null | undefined | ''， 进行处理
 * @param toValue 默认为 O
 */
export const nullToZero = (value: string | number | null | undefined, toValue: number | string = 0): string | number => {
  return value || toValue;
};

/**
 * 对数字或字符串增加千分符
 * @param value 需要处理的数据 number | string 可选为addSymbol的predicate参数
 * @param mark  千分符的样式默认为，
 * @retrun string
 */
export const micrometer = (value: number | string, mark = ","): string => {
  const result = value.toString().replace(/\d+/, (n) => {
    return n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
      return $1 + mark;
    });
  });

  return result;
};

/**
 * 对多个异步的Promise进行统一处理
 * 当一个页面有多个Ajax时，我们建议你通过该方法统一获取来控制loadding
 * @param promise 为当前需要执行的函数组
 *  请注意promise为函数的执行结果，promiseAll内部不做调用
 * @retrun Promise<PromiseAll>
 */

export const promiseAll = async <T>(
  promise: Array<Promise<T>>
): Promise<
  | {
      isSuccess: true;
      result: Array<T>;
    }
  | {
      isSuccess: false;
      result: any;
    }
> => {
  try {
    const result = await Promise.all(promise);
    return {
      isSuccess: true,
      result: result
    };
  } catch (err) {
    return {
      isSuccess: false,
      result: err
    };
  }
};

/**
 * 获取指定样式的数字值
 * @param dom html HTMLElement
 * @param propertyname 属性名
 * @param company 单位 默认为px
 * @retrun number
 */
export const getStyleAsNumber = (dom: HTMLElement, propertyname: string, company = "px"): number => {
  const main = getComputedStyle(dom);
  return Number(main.getPropertyValue(propertyname).split(company)[0]);
};

/**
 * 首字母大写
 * @param value 需要转换的单词
 * @retrun string
 */
export const initialsCase = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * 延迟执行
 * @param time 需要延迟的时间 ms 默认为300
 * @retrun Promise<'success'>
 */
export const sleep = (time = 300): Promise<"success"> => {
  return new Promise((suc) => {
    setTimeout(() => {
      suc("success");
    }, time);
  });
};

/**
 * 斐波那函数
 * @param length 生成的长度 number
 * @retrun Array<number>
 */

export const fibonacci = (length = 2, result: Array<number> = [1, 1], index = 2): Array<number> => {
  if (length <= 2) return result;
  if (index < length) {
    result.push(result[index - 1] + result[index - 2]);
    fibonacci(length, result, index + 1);
  }
  return result;
};

/**
 * 对图片进行Promise下载
 * @param src 需要下载的图片地址
 */
export const imageLoad = (src: string): Promise<HTMLImageElement> => {
  return new Promise((suc, err) => {
    const img = new Image();
    img.onload = () => suc(img);
    img.onerror = (error) => err(error);
    img.src = src;
  });
};

/**
 * canvas To blob
 * @param value canvas 对象
 * @param type 图片类型 'png' | 'jpg' | 'webp'
 * @param quality 图片质量 number
 * @requires Promise<Blob>
 */
export const promiseBlob = (value: HTMLCanvasElement, type: "png" | "jpeg" | "webp" = "jpeg", quality?: number): Promise<Blob> => {
  return new Promise((suc, err) => {
    value.toBlob(
      (blob) => {
        blob ? suc(blob) : err(null);
      },
      "image/" + type,
      quality
    );
  });
};

/**
 * Blob 转 url
 * @param value BLob 对象
 * @returns string
 */
export const blobToUrl = (value: Blob): string => {
  return URL.createObjectURL(value);
};

/**
 * blob 转 File
 * @param value Blob 对象
 * @param fileName 文件名
 * @param options 其他配置参数，默认为blob的size和type  参考 FilePropertyBag
 * @returns File
 */
export const blobToFile = (value: Blob, fileName: string, options?: FilePropertyBag): File => {
  const file = new File([value], fileName, Object.assign({ type: value.type, size: value.size }, options));
  return file;
};

/**
 * file 转 Bolb
 * @param value File 文件
 * @returns Promise<Blob | string>
 */
export const fileToBlob = (value: File): Promise<Blob> => {
  return new Promise((suc, err) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(value);
    reader.onload = (e) => {
      e.target && e.target.result && typeof e.target.result === "object" ? suc(new Blob([e.target.result])) : err(null);
    };
  });
};

/**
 * 前端对文件进行下载
 * @param fileName 下载后的文件名称
 * @param file 下载的地址，string 可以文二进制文件，也可也为可预览的文件
 */
export const savaFile = (file: string, fileName = "file", target = "_blank"): void => {
  const event = new MouseEvent("click");
  const save_link = document.createElement("a");
  save_link.href = file;
  save_link.download = fileName;
  save_link.target = target;
  save_link.dispatchEvent(event);
};

/**
 * 对字符串进行截取
 * @param str 需要处理的字符串
 * @param length 长度返回的长度
 * @param ellipsis 是否返回的结果显示省略号。当传入的字符串长度大于参数length且为true时生效
 * @return string
 */
export const cutStr = (str: string, length: number, ellipsis = false): string => {
  let result = "";
  const strLength = str.length;
  if (strLength <= length) return str;
  for (let i = 0; i < length; i++) {
    result += str.charAt(i);
  }
  return ellipsis ? result + "..." : result;
};

/**
 * 对axios 请求进行拦截并请求，并同意进行错误处理
 * @param axios 需要执行的axios请求
 * @param showMessage 当出现错误时是否弹出错误信息
 * @param err 当出现错误是执行的回调函数
 * @returns Promise<T>
 */
export const axiosWrapper = async <T>(
  axios: Promise<T>,
  showMessage = false,
  err?: (...arg: Array<any>) => void
): Promise<
  | {
      isSuccess: true;
      result: T;
      isShowError: boolean;
    }
  | {
      isSuccess: false;
      result: any;
      isShowError: boolean;
      errorMsg: string | string[] | null;
    }
> => {
  try {
    const result = await axios;
    return {
      isSuccess: true,
      result: result,
      isShowError: false
    };
  } catch (error: any) {
    let isShowError = false;
    let errorMsg = null;
    if (err) err(error.response);
    if (error.response && error.response.status) {
      if (error.response.status !== 401 && error.response.status < 500) {
        isShowError = true;
        errorMsg = mapRequestError(error.response.data);
        if (showMessage) message.error(errorMsg);
      }
    }
    console.log(showMessage);
    return {
      isSuccess: false,
      result: error,
      isShowError,
      errorMsg
    };
  }
};

/**
 * 遍历生成是否存在错误信息
 * @param props 需要遍历的key组合
 * @param error 需要生成的错误信息
 * @returns any
 */
export const mapRequestError = (error: Record<string, any>): string | string[] | null => {
  const props = Object.keys(error);
  for (let i = 0; i < props.length; i++) {
    const prop = props[i];
    if (error[prop]) return error[prop];
  }
  return null;
};

/**
 * 判断DOM中元素是否被包裹
 * @param parent 需要判断的父级元素
 * @param node 需要判断的元素
 * @returns Boolean
 */

export const contains = (parent: HTMLElement, node: HTMLElement): boolean => {
  return parent !== node && parent.contains(node);
};

/**
 * 获取文件的后缀格式
 * @param link 需要判断的url地址
 * @retruns string | null
 */
export const fileSuffix = (link: string): string | null => {
  const lintArray = link.split(".");
  const len = lintArray.length;
  return len > 0 ? lintArray[len - 1] : null;
};

/**
 * 判断一个地址是否为图片
 * @param link 需要判断的地址
 * @returns boolean
 */
export const fileIsImage = (link: string): boolean => {
  const imgTypeArray = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
  const type = fileSuffix(link);
  return type ? imgTypeArray.includes(type) : false;
};

/**
 * 验证一个字符串是否为邮箱
 * @param value 需要验证的字符串
 * @requires boolean
 */
export const stringIsEmail = (value: string): boolean => {
  const myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  return myreg.test(value);
};

/**
 * @param phone 需要验证的手机号码
 * @param code 国家前置区号
 * @param rule 需要自定义的正则
 * @requires boolean
 */
export const PhoneValidationRule = {
  ZH_CN: /^(\+?0?86\-?)?1[345789]\d{9}$/, // 中国大陆
  EN_US: /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/ // 美国
};
type CodeTpe = "86" | "1";
export const validatePhone = (phone: string, code: CodeTpe = "86", rule?: RegExp): boolean => {
  let _rule = rule;
  if (!_rule) {
    switch (code) {
      case "86":
        _rule = PhoneValidationRule.ZH_CN;
        break;
      case "1":
        _rule = PhoneValidationRule.EN_US;
        break;
    }
  }
  return _rule?.test(phone) || false;
};

/**
 * 获取url中的完整参数
 * @param interval 链接中用来拼接的符号，默认为 &
 * @returns 'Record<string, string>'
 */
export const getSearchQuery = (interval = "&"): Record<string, string | undefined> => {
  const { search } = window.location;
  const queryArray = search
    ? search
        .substring(1)
        .split(interval)
        .map((item) => item.split("="))
    : [];
  return queryArray.reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {} as Record<string, string | undefined>);
};

/**
 * 获取url中的制定参数
 * @param prop 需要获取的key
 * @param interval 链接中用来拼接的符号，默认为 &
 */
export const getSearchQueryAsProp = (prop: string, interval = "&"): string | undefined => {
  return getSearchQuery(interval)[prop];
};

/* 获取sso地址
 * @returns
 */
export const getSSOHost = () => {
  return process.env.REACT_APP_SSO_HOST;
};

/**
 * 获取websocket地址
 * @returns
 */
export const getWebsocketHost = () => {
  return process.env.REACT_APP_WEB_SOCKET_HOST;
};

/**
 * 判断用户是否登陆
 * @returns boolean
 */
export const getUserIsLogin = (): boolean => {
  return Boolean(localStorage.getItem("token"));
};

/**
 * 去除字符串首尾指定字符
 * @param value 需要执行的字符串
 * @param char 需要去除的头尾标识
 * @param type 单独去除左右
 * @returns string
 */
export const stringTrim = (value: string = "", char?: string, type?: "left" | "right") => {
  if (char) {
    if (type === "left") {
      return value.replace(new RegExp("^\\" + char + "+", "g"), "");
    } else if (type === "right") {
      return value.replace(new RegExp("\\" + char + "+$", "g"), "");
    }
    return value.replace(new RegExp("^\\" + char + "+|\\" + char + "+$", "g"), "");
  }
  return value.replace(/^\s+|\s+$/g, "");
};

interface AVATAR_BG_TYPE {
  [key: string]: string;
}

const AVATAR_BG: AVATAR_BG_TYPE = {
  a: "rgb(122,142,54)",
  b: "rgb(188,202,68)",
  c: "rgb(73,141,195)",
  d: "rgb(22,54,119)",
  e: "rgb(20,78,215)",
  f: "rgb(177,5,247)",
  g: "rgb(39,141,53)",
  h: "rgb(23,143,20)",
  i: "rgb(234,83,201)",
  j: "rgb(126, 60, 245)",
  k: "rgb(205,187,160)",
  l: "rgb(244,121,225)",
  m: "rgb(155,184,186)",
  n: "rgb(72,248,225)",
  o: "rgb(191,172,156)",
  p: "rgb(51,88,254)",
  q: "rgb(180,66,12)",
  r: "rgb(211,234,119)",
  s: "rgb(69,13,41)",
  t: "rgb(54,2,204)",
  u: "rgb(67,36,206)",
  v: "rgb(98,39,127)",
  w: "rgb(125,144,222)",
  x: "rgb(186,213,78)",
  y: "rgb(79,130,58)",
  z: "rgb(85,213,39)"
};
/**
 * 用户头像转为canvas
 * @param item 用户信息
 * @param size 半径大小
 */
export const getCanvas = async (
  item: {
    full_name?: string;
    avatar?: string;
  },
  size = 24
) => {
  const drawName = (name: string, nameFirstLetter: string) => {
    const c = document.createElement("canvas");
    c.width = size * 2;
    c.height = size * 2;
    const ctx: any = c.getContext("2d");
    ctx.arc(size, size, size, 0, 2 * Math.PI);
    ctx.fillStyle = AVATAR_BG[nameFirstLetter] || "grey";
    ctx.fill();
    ctx.font = size - 2 + "px Arial";
    ctx.fillStyle = "#fff";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    const text = name;
    ctx.fillText(text, size, size + 2);
    ctx.save();
    return c;
  };

  return new Promise(async (resolve, reject) => {
    let name = "";
    if (item.full_name) {
      const re = /[A-Za-z]+/;
      const nameArr = item.full_name.split(" ");
      let nameStr: string = "";
      for (let i = 0; i < 2; i++) {
        let currentStr: string = nameArr[i]?.substring(0, 1)?.toUpperCase() || "";
        if (i === 0 && currentStr.search(re) !== 0) {
          nameStr = currentStr;
          break;
        } else if (currentStr.search(re) === 0) {
          nameStr += currentStr;
        }
      }
      name = nameStr;
    } else {
      name = item.full_name || "";
    }
    let nameFirstLetter = "";
    if (name) {
      nameFirstLetter = pinyin(name).join("").substring(0, 1)?.toLowerCase();
    } else {
      nameFirstLetter = "";
    }
    const userAvatar = item.avatar || "";
    if (userAvatar) {
      const c = document.createElement("canvas");
      c.width = size * 2;
      c.height = size * 2;
      const ctx: any = c.getContext("2d");
      const imgDom = document.createElement("img");
      imgDom.src = userAvatar;
      const callFunction = () => {
        return new Promise((resolve, reject) => {
          imgDom.onload = () => {
            ctx.arc(size, size, size, 0, 2 * Math.PI);
            ctx.clip();
            ctx.drawImage(imgDom, 0, 0, size * 2, size * 2);
            ctx.save();
            resolve(c);
          };
          imgDom.onerror = () => {
            const c = drawName(name, nameFirstLetter);
            resolve(c);
          };
        });
      };
      callFunction()
        .then((c) => {
          resolve(c);
        })
        .catch((error) => {
          reject();
        });
    } else {
      const c = drawName(name, nameFirstLetter);
      resolve(c);
    }
  });
};

export type DebounceFn = (...args: any[]) => any;

export type DefaultObject = {
  [key: string]: any;
};

export type LoadMoreByScrollingParams = {
  dispatch: DebounceFn;
  page: number;
  params?: DefaultObject;
  page_size?: number;
};

/**
 * load more data when scroll to the select dropdown bottom
 * @param e
 * @param dispatch
 * @param currentPage
 * @param params
 */
export const loadMoreCallBackByScrolling = (
  e: BaseSyntheticEvent,
  { dispatch, page, params, page_size = DEFAULT_PAGE_SIZE }: LoadMoreByScrollingParams
): void => {
  e.persist();
  // checkout if the scrollbar go to the bottom
  const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLElement;
  if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
    dispatch({
      page_size,
      page: page + 1,
      fetchType: TYPES_OF_FETCHING.MORE,
      ...params
    });
  }
};

export const getPlatform = () => {
  const platform = window.navigator.platform
  if (platform.indexOf("Win") >= 0) {
    return AppPlatformType.win
  }
  if (platform.indexOf("Mac") >= 0) {
    return AppPlatformType.mac
  }
  if (platform.indexOf("Linux") >= 0) {
    return AppPlatformType.linux
  }
  return null
}

let _architecture: AppArch|null|undefined = undefined
export const getArchitectureForMacOS = () => {
  if (_architecture !== undefined) {
    return _architecture
  }

  _architecture = null
  if (window.navigator.platform.indexOf("Mac") >= 0) {
    let canvas:HTMLCanvasElement|undefined = document.createElement('canvas')
    let gl = canvas.getContext('webgl') as any
    if (!gl) {
      gl = canvas.getContext('webgl2')
    }
    if (!gl) {
      gl = canvas.getContext('experimental-webgl2')
    }
    if (!gl) {
      gl = canvas.getContext('experimental-webgl')
    }
    if (gl && gl.getExtension) {
      const info = gl.getExtension('WEBGL_debug_renderer_info')
      if (info) {
        const renderer = gl.getParameter(info.UNMASKED_RENDERER_WEBGL)
        if (renderer) {
          console.log('UNMASKED_RENDERER_WEBGL', renderer)
          if (renderer.indexOf('Intel') >= 0) {
            _architecture = AppArch.x64
          }
          if (renderer.indexOf('Apple M1') >= 0 || renderer.indexOf('Apple M2') >= 0  || renderer.indexOf('Apple GPU') >= 0) {
            _architecture = AppArch.arm64
          }
        }
      }
    }
    canvas = undefined
  }
  return _architecture
}

// 企业名称、用户姓名正则
export const namePattern = /^[^@#$%^&*(){}\[\]\\\/|<>?]+$/;
