import { GButton, GModal, GModalProps, translate } from "@gear/ui";
import { FC, useState } from "react";
import { ReactComponent as IconAppSitesTracking } from "./assets/icon_app_sites_tracking.svg";
import { ReactComponent as IconProductivityInsights } from "./assets/icon_productivity_insights.svg";
import { ReactComponent as IconScreenShare } from "./assets/icon_screen_share.svg";
import { ReactComponent as IconVoiceChannel } from "./assets/icon_voice_channel.svg";
import { ReactComponent as IconDownload } from "./assets/icon_download.svg";
import { ReactComponent as IconBg1 } from "./assets/icon_bg_1.svg";
import { ReactComponent as IconBg2 } from "./assets/icon_bg_2.svg";
import { ReactComponent as IconBg3 } from "./assets/icon_bg_3.svg";
import { ReactComponent as IconBg4 } from "./assets/icon_bg_4.svg";
import IconMore from "./assets/icon_more.png";
import IconContacts from "./assets/icon_contacts.png";
import IconAvatar from "./assets/icon_avatar.png";
import IconMessage from "./assets/icon_message.png";
import { AppDownloadModal } from "../AppDownloadModal";
import "./index.scss";

interface AppDownloadGuideModalProps extends GModalProps {
  onDownloaded?: () => void;
}

export const AppDownloadGuideModal: FC<AppDownloadGuideModalProps> = ({ onDownloaded, ...modalProps }) => {
  const [appDownloadModalVisible, setAppDownloadModalVisible] = useState(false);

  const features = [
    {
      icon: <IconVoiceChannel />,
      label: "Voice channel"
    },
    {
      icon: <IconAppSitesTracking />,
      label: "Apps & Sites tracking"
    },
    {
      icon: <IconProductivityInsights />,
      label: "Productivity insights"
    },
    {
      icon: <IconScreenShare />,
      label: "Screen sharel"
    }
  ];

  return (
    <>
      <GModal className="app-download-guide-modal" width={858} title={null} footer={null} closable={false} maskClosable={true} {...modalProps}>
        <div className="row app-download-guide_content">
          <IconBg1 className="bg1" />
          <IconBg2 className="bg2" />
          <IconBg3 className="bg3" />
          <IconBg4 className="bg4" />
          <div className="column left">
            <div className="app-download-guide_title-box">
              <span dangerouslySetInnerHTML={{ __html: translate("APP_DOWNLOAD_GUIDE_TITLE") }}></span>
            </div>
            <div className="row" style={{ minWidth: "329px", flexWrap: "wrap", gap: 12 }}>
              {features.map((item, index) => {
                return (
                  <div key={index} className="row-ver-center app-download-guide_feature-box">
                    {item.icon}
                    {item.label && <span>{translate(item.label)}</span>}
                  </div>
                );
              })}
              <img style={{ width: "30px", height: "30px" }} src={IconMore} />
            </div>

            <GButton
              style={{
                width: "193px",
                height: "54px",
                fontWeight: 600,
                marginTop: "58px",
                border: "none",
                borderRadius: "12px",
                background: "linear-gradient(287deg, #5066EE 53.36%, #B5BFFF 158.69%)"
              }}
              onClick={() => {
                setAppDownloadModalVisible(true);
              }}
            >
              <div className="row-center" style={{ gap: 5 }}>
                <IconDownload />
                {translate("Download")}
              </div>
            </GButton>
          </div>

          <img style={{ width: "216px", height: "490px", marginTop: "46px" }} src={IconContacts} />

          <div className="column" style={{ marginTop: "50px" }}>
            <img style={{ width: "133px", height: "133px", marginLeft: "68px", marginBottom: "25px" }} src={IconAvatar} />

            <img style={{ width: "226px", height: "269px" }} src={IconMessage} />
          </div>
        </div>
      </GModal>
      <AppDownloadModal
        visible={appDownloadModalVisible}
        onCancel={() => {
          setAppDownloadModalVisible(false);
        }}
        onDownloaded={() => {
          setAppDownloadModalVisible(false);
          onDownloaded?.();
        }}
      />
    </>
  );
};
