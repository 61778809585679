import { ProjectApi } from "@gear/business";

import { makeAutoObservable } from "mobx";
import moment from "moment";
import { ConnectionSchedule, GTask } from "@gear/business";
import { Task } from "@/apis/types";
import { WorkItem } from "@gear/business";
import { uniq } from "lodash";
const dateFormat = "YYYY-MM-DD";
class DailyReportsStore {
  currentdate: string = moment().format(dateFormat);
  autoShare = false;
  autoShareEmails: string[] = [];
  backLogTasks: GTask[] = []; //编辑日报时用到
  filterIds: number[] = [];
  toTasksThirdpartyIds: number[] = [];
  removeWorkItem: WorkItem = {};
  fetchSelectTasksLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  updateCurrentdate(date: string) {
    console.log(date);
    this.currentdate = date;
  }

  updateAutoShare(open: boolean) {
    this.autoShare = open;
  }

  updateAutoShareEmails(emails: string[]) {
    this.autoShareEmails = emails;
  }

  updateBackLogTasks(tasks?: Task[]) {
    this.backLogTasks = tasks || [];
  }
  updateFilterIds(ids: number[]) {
    this.filterIds = ids;
  }
  updateToTasksIds(ids: number[]) {
    this.toTasksThirdpartyIds = uniq([...ids, ...this.toTasksThirdpartyIds]);
  }

  updateRemoveWorkItem(data: WorkItem) {
    this.removeWorkItem = data;
  }
  updateFetchSelectTasksLoading(loading: boolean) {
    this.fetchSelectTasksLoading = loading;
  }

  /**
   * 获取个人日报自动分享开关状态
   */
  fetchDailyReportAutoShareStatus() {
    ProjectApi.getDailyReportAutoShareSwitch().then((res) => {
      this.updateAutoShare(!!res.data.open);
    });
  }

  /**
   * 获取个人日报自动分享邮箱列表
   */
  fetchDailyReportAutoShareEmails() {
    ProjectApi.getDailyReportAutoShareEmailList().then((res) => {
      this.updateAutoShareEmails(res.data.results);
    });
  }

  /**
   * 更改自动分享开关状态
   * @param open
   * @returns
   */
  changeDailyReportAutoShareStatus(open: boolean) {
    return ProjectApi.patchDailyReportAutoShareSwitch(open).then((res) => {
      this.updateAutoShare(open);
    });
  }

  /**
   * 更改自动分享邮箱列表
   * @param emails
   * @returns
   */
  changeDailyReportAutoShareEmails(emails: string[]) {
    return ProjectApi.setDailyReportAutoShareEmailList(emails).then((res) => {
      this.updateAutoShareEmails(emails);
    });
  }
}

export default DailyReportsStore;
