import { get, patch } from "@gear/business";
import { GPartialAsSome } from "@gear/ui";
// import storage from "storejs";
import { NotificationsConfigures } from "./types";

export class NotificationApi {
  /**
   * 获取消息通知列表
   * @param status number 0 | 1 0：未读 1：已读
   */
  static getList(parameters: { ordering: string; page?: number; page_size?: number }) {
    const url = "/api/message/user_notices";
    return get(url, parameters);
  }

  // /**
  //  * 更新通知状态
  //  */
  // static upDateNotification(status: 0 | 1, id: number) {
  //   const enterprise = Number(storage.get("current-enterprise-id"));
  //   const url = "/api/notification/notice_users/" + id;
  //   return patch(url, { status, enterprise });
  // }

  /**
   * 阅读消息
   */
  static ReadNotification(id: number) {
    const url = "/api/message/user_notices/read/" + id;
    return patch(url);
  }

  /**
   * 全部已读
   */
  static ReadAllNotification() {
    const url = "/api/message/user_notices/read/all";
    return patch(url);
  }

  /**
   * 获取用户配置
   */

  static getConfig() {
    const url = "/api/message/configures";
    return get(url);
  }

  /**
   * 修改用户配置
   */
  static patchConfig(parameters: GPartialAsSome<NotificationsConfigures, Exclude<keyof NotificationsConfigures, "id">>) {
    const url = "/api/message/configures/" + parameters.id;
    return patch(url, parameters);
  }
}
