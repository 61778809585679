import React, { FC } from "react";
import { GButton, GModal, translate } from "@gear/ui";
import { ModalProps } from "antd";
import imgUrl from "@/assets/img/device-img@2x.png";
import "./index.scss";
interface DeviceTipsModalProps extends ModalProps {
  visible?: boolean;
  closeModal?: Function;
}
export const DeviceTipsModal: FC<DeviceTipsModalProps> = ({ closeModal, visible }) => {
  return (
    <GModal visible={visible} wrapClassName={"DeviceTipsModal"} footer={null} closable={false}>
      <div>
        <div className={"device-tips-title"}>{translate("Use Metawork on PC")}</div>
        <div className={"device-tips-dec"}>{translate("to get a better user experience")}</div>
        <div className="img-box">
          <img src={imgUrl} alt="" />
        </div>
        <div
          style={{
            padding: "0 24px"
          }}
        >
          <GButton
            type="primary"
            style={{
              marginTop: "16px",
              width: "100%",
              borderRadius: "12px",
              height: "50px",
              background: "linearGradient(287deg, #5066EE 53.36%, #B5BFFF 158.69%)",
              marginBottom: "24px"
            }}
            onClick={() => {
              closeModal?.();
            }}
          >
            {translate("OK")}
          </GButton>
        </div>
      </div>
    </GModal>
  );
};
