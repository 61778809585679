import { GPopover, GTooltip, translate } from "@gear/ui";
import { CSSProperties, FC, useMemo, useState } from "react";
import { ReactComponent as IconDownload } from "./assets/icon_download.svg";
import { AppArch, AppInfo, AppPlatformType } from "@/apis/types";
import { getArchitectureForMacOS, getPlatform } from "@/utils";
import { AccountAPI, SettingAPI, useGStores } from "@gear/business";
import { UseStores } from "@/stores";
import { Badge } from "antd";
import "./index.scss";

interface DownloadEnterProps {
  style?: CSSProperties;
  disabled?: boolean;
}

/**
 * 虚拟工位桌面端下载入口组件
 */
export const DownloadEnter: FC<DownloadEnterProps> = ({ disabled, style }) => {
  const [downloadPopoverVisible, setDownloadPopoverVisible] = useState(false);
  const { configStore } = useGStores<UseStores>();
  const os: any = getPlatform();

  let architecture = useMemo(() => {
    if (os === AppPlatformType.mac) {
      const validOSs = [AppPlatformType.win, AppPlatformType.mac, AppPlatformType.linux];
      return os && validOSs.indexOf(os) >= 0 ? getArchitectureForMacOS() : null;
    }
    return null;
  }, [os]);

  const startDownload = (platform: AppPlatformType, arch: AppArch) => {
    AccountAPI.reportDownloadedApp("metawork");
    SettingAPI.getDownloadAppVersion()
      .then((response) => {
        const appInfos: AppInfo[] = response.data || [];
        const url = appInfos?.find((appInfo) => {
          return appInfo.platform === platform && appInfo.arch === arch;
        })?.url;
        if (url) {
          console.log(url);
          configStore.updateAppDownloaded(true);
          window.location.href = url;
        }
      })
      .finally(() => {});
    setDownloadPopoverVisible(false);
  };
  if (disabled) {
    return (
      <div
        className="icon-enter"
        style={{
          ...style,
          cursor: "no-drop"
        }}
      >
        <IconDownload />
      </div>
    );
  }
  return (
    <GPopover
      placement={"topRight"}
      overlayClassName="header-download-popover-container"
      content={() => (
        <div className="header-download-popover">
          <div className="content-container" onClick={() => startDownload(AppPlatformType.win, AppArch.x64)}>
            Windows{os === AppPlatformType.win && <span className="recommend-badge">{translate("Matched")}</span>}
          </div>
          <div className="content-container" onClick={() => startDownload(AppPlatformType.mac, AppArch.universal)}>
            macOS {translate("(Universal)")}
            {os === AppPlatformType.mac && !architecture && <span className="recommend-badge">{translate("Matched")}</span>}
          </div>
          <div className="content-container" onClick={() => startDownload(AppPlatformType.mac, AppArch.x64)}>
            <p dangerouslySetInnerHTML={{ __html: `macOS ${translate("(Intel chip)")}` }}></p>
            {architecture === AppArch.x64 && <span className="recommend-badge">{translate("Matched")}</span>}
          </div>
          <div className="content-container" onClick={() => startDownload(AppPlatformType.mac, AppArch.arm64)}>
            <p dangerouslySetInnerHTML={{ __html: `macOS ${translate("(Apple silicon)")}` }}></p>
            {architecture === AppArch.arm64 && <span className="recommend-badge">{translate("Matched")}</span>}
          </div>
        </div>
      )}
      trigger="click"
      visible={downloadPopoverVisible}
      onVisibleChange={(visible: boolean) => {
        setDownloadPopoverVisible(visible);
      }}
    >
      <GTooltip getPopupContainer={() => document.body} title={translate("Client download")}>
        <div className="icon-enter" style={style}>
          <Badge dot count={configStore.appDownloaded ? 0 : 1} size="small" offset={[-7, 7]}>
            <IconDownload />
          </Badge>
        </div>
      </GTooltip>
    </GPopover>
  );
};
