export const ROUTE_CONSTANTS = {
  ALL_PROJECTS: "/all-projects",
  PROJECT_DETAIL_DASHBOARD: "/team/detail/dashboard",
  TEAM_USER: "/team/detail/team-user",
  TEAM_DETAIL_GANTT: "/team/detail/gantt",
  TEAM_DETAIL_TIMESHEET: "/team/detail/timesheet",
  VIRTUAL_OFFICE: "/virtual_office",
  DASHBOARD: "/dashboard",
  HOME_PAGE: "/home",
  TASKS: "/tasks",
  DAILY_REPORTS: "/daily-reports/all",
  MY_DAILY_REPORTS: "/daily-reports/my",
  ORGANIZATION_WEEKLY_REPORT: "/weekly-reports",
  EMAIL_SHARE_DAILY_REPORT: "/email/share-daily-report",
  MEMBER_SHARE_DAILY_REPORT: "/member-daily-report-share",
  INSIGHTS: "/insights",
  ORGANIZATION_INFO_SETTING: "/organization-info-setting",
  METAWORK_SETTING: "/metawork-setting",
  PROFILE: "/profile",
  SETTINGS_SCREENSHOTS: "/metawork-setting/screenshots",
  SETTINGS_APP_AND_SITES: "/metawork-setting/app-and-sites",
  SETTINGS_DAILY_CHECKINS: "/metawork-setting/daily-checkins",
  SETTINGS_WORKLOAD: "/metawork-setting/workload",
  SETTINGS_WORKLOAD_ALERT: "/metawork-setting/workload/alert",
  SETTINGS_WORKLOAD_APPLICABLE_MEMBERS: "/metawork-setting/workload/applicable_members",
  SETTINGS_APP_AND_SITES_ENABLE_STATUS: "/metawork-setting/app-and-sites/enable-status",
  SETTINGS_APP_AND_SITES_CLASSIFICATION: "/metawork-setting/app-and-sites/classification",
  SETTINGS_APP_AND_SITES_ALERT: "/metawork-setting/app-and-sites/alert",
  FEATURES_VIRTUAL_OFFICE: "/features/virtual_office",
  FEATURES_COMMUNICATION_TOOL: "/features/communication_tool",
  FEATURES_PRODUCTIVITY_INSIGHTS: "/features/productivity_insights",
  REPORTS: "/reports",
  ACTIVITY_STATS: "/reports/activity_stats",
  TASK_STATS: "/reports/task_stats",
  DAILY_STATS: "/reports/daily_stats"
};
