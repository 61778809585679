import { ProductMode } from "@/apis/types";
import { otherRoutes, rootRoutes } from "@/router/routes";
import { Enterprise, SubscriptionType, useGStores } from "@gear/business";
import { useMemo, useState } from "react";
import { UseStores } from "@/stores";
import moment from "moment-timezone";

export function useProduct() {
  const { enterpriseStore } = useGStores<UseStores>();

  const openedMarketplace = useMemo(() => {
    return enterpriseStore.enterprise?.marketplace_opened;
  }, [enterpriseStore.enterprise]);

  const openedMetawork = useMemo(() => {
    const enterprise: Enterprise | null = enterpriseStore.enterprise;
    if (enterprise?.is_metawork_opened === false) {
      return false;
    }

    if (enterprise && enterprise.is_metawork_opened) {
      if (enterprise?.max_subscription_member_count) {
        const servingMemberCount = enterprise?.active_member_count || 0;
        return servingMemberCount <= enterprise?.max_subscription_member_count;
      }
    }
    return true;
  }, [enterpriseStore.enterprise]);

  const remainingFreeDays = useMemo(() => {
    return Math.ceil((moment(enterpriseStore.enterprise?.subscription_expired_at).diff(moment(), "millisecond") * 1.0) / (1000 * 60 * 60 * 24));
  }, [enterpriseStore.enterprise]);

  /**
   * 已开通产品
   */
  const openedProduct = useMemo(() => {
    const product: ProductMode[] = [];
    if (openedMetawork) {
      product.push(ProductMode.metawork);
    }
    if (enterpriseStore.enterprise?.marketplace_opened) {
      product.push(ProductMode.marketplace);
    }
    return product;
  }, [enterpriseStore.enterprise?.id]);

  const hasSubscriptionOrder = () => {
    const enterprise: Enterprise | null = enterpriseStore.enterprise;
    if (enterprise) {
      return !!enterprise.subscription_id;
    }
    return false;
  };

  const metaworkClosedReason = useMemo(() => {
    if (!openedMetawork) {
      const enterprise: Enterprise | null = enterpriseStore.enterprise;
      if (enterprise) {
        if (enterprise.subscription_type === SubscriptionType.trial) {
          return "trial_expired";
        }
        if (enterprise.max_subscription_member_count) {
          const servingMemberCount = enterprise.active_member_count || 0;
          if (servingMemberCount > enterprise.max_subscription_member_count) {
            return "member_limit";
          }
        }
      }
      return "plan_expired";
    }
    return "";
  }, [enterpriseStore.enterprise]);

  const isFreeTrialType = useMemo(() => {
    const enterprise = enterpriseStore.enterprise;
    console.log("isFreeTrialType enterprise ", enterprise);
    return !enterprise?.subscription_type || enterprise?.subscription_type === SubscriptionType.trial;
  }, [enterpriseStore.enterprise]);

  const _filterRootRoutesWithProduct = useMemo(() => {
    // if (openedProduct.length) {
    //   // 过滤出已开通产品支持的路由
    //   return rootRoutes.filter((route) => {
    //     return route.mode?.length ? intersection(openedProduct, route.mode).length > 0 : true;
    //   });
    // }
    // return rootRoutes.filter((route) => {
    //   return !route.mode?.length;
    // });
    return rootRoutes;
  }, [openedProduct]);

  const _filterOtherRoutesWithProduct = useMemo(() => {
    // if (openedProduct.length) {
    //   // 过滤出已开通产品支持的路由
    //   return otherRoutes.filter((route) => {
    //     if (route.path === "/") {
    //       return route.mode?.length ? route.mode?.includes(ProductMode.metawork) : true;
    //     }
    //     return route.mode?.length ? intersection(openedProduct, route.mode).length > 0 : true;
    //   });
    // }
    // return otherRoutes.filter((route) => {
    //   return !route.mode?.length;
    // });
    return otherRoutes;
  }, [openedProduct]);

  const filterRootRoutesWithProduct = useMemo(() => {
    return _filterRootRoutesWithProduct.map((router) => {
      return {
        ...router,
        plan_expired: (router as any).isFree ? false : !openedMetawork
      };
    });
  }, [_filterRootRoutesWithProduct, openedMetawork]);

  const filterOtherRoutesWithProduct = useMemo(() => {
    return _filterOtherRoutesWithProduct.map((router) => {
      return {
        ...router,
        plan_expired: (router as any).isFree ? false : !openedMetawork
      };
    });
  }, [_filterOtherRoutesWithProduct, openedMetawork]);

  return {
    remainingFreeDays,
    openedMarketplace,
    openedMetawork,
    openedProduct,
    hasSubscriptionOrder,
    isFreeTrialType,
    metaworkClosedReason,
    filterRootRoutesWithProduct,
    filterOtherRoutesWithProduct
  };
}
