import { Connection, ConnectionApi, ConnectionCalendar, ConnectionConfig } from "@gear/business";
import { cloneDeep } from "lodash";
import { makeAutoObservable } from "mobx";
import { CalDAVUser, GoogleUser, MicrosoftUser, ThirdPartyIntegrationPlatformEnum, ThirdPartyIntegrationSceneEnum } from "@/apis/types";
import ThirdPartiesApi from "@/apis/thirdPartiesApi";

export interface ConnectData {
  platform: ThirdPartyIntegrationPlatformEnum;
  state: string;
  code?: string;
  error?: string;
}

/**
 * 第三方平台store
 */
class ConnectionStore {
  feishuConnections: Connection[] = [];
  calDAVs: CalDAVUser[] = [];
  googleCalendarConnections: GoogleUser[] = [];
  microsoftCalendarConnections: MicrosoftUser[] = [];
  isConnectedAnyCalendars: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 更新第三方平台列表
   * @param connections
   */
  updateFeishuConnections(feishuConnections: Connection[]) {
    this.feishuConnections = feishuConnections;
    this.checkIsConnectedAnyCalendars();
  }

  updateCalDAVs(calDAVs: CalDAVUser[]) {
    this.calDAVs = calDAVs;
    this.checkIsConnectedAnyCalendars();
  }

  updateGoogleCalendarConnections(googleCalendarConnections: GoogleUser[]) {
    this.googleCalendarConnections = googleCalendarConnections;
    this.checkIsConnectedAnyCalendars();
  }

  updateMicrosoftCalendarConnections(microsoftCalendarConnections: MicrosoftUser[]) {
    this.microsoftCalendarConnections = microsoftCalendarConnections;
    this.checkIsConnectedAnyCalendars();
  }

  /**
   * 更新第三方数据
   * @param target
   */
  updateFeishuConnection(target: Connection) {
    const _connections = cloneDeep(this.feishuConnections);
    const index = _connections.findIndex((connection) => connection.source === target.source);
    if (index > -1) {
      _connections.splice(index, 1, target);
      this.updateFeishuConnections(_connections);
    }
  }

  /**
   * 更新第三方配置
   * @param source
   * @param config
   */
  updateLarkConfig(source: string, config: ConnectionConfig) {
    const target = this.feishuConnections.find((connection) => connection.source === source);
    if (target) {
      const _target = cloneDeep(target);
      _target.config = config;
      this.updateFeishuConnection(_target);
    }
  }

  /**
   * 更新第三方日历配置
   * @param source
   * @param calendar
   * @param merge
   */
  updateLarkCalendar(source: string, calendar: ConnectionCalendar, merge = true) {
    const target = this.feishuConnections.find((connection) => connection.source === source);
    if (target) {
      const _target = cloneDeep(target);
      const index = _target.config?.calendars?.findIndex((__calendar) => __calendar.id === calendar.id);
      if (index !== undefined && index > -1) {
        if (merge) {
          Object.assign(_target.config!.calendars[index], calendar);
        } else {
          _target.config?.calendars.splice(index, 1, calendar);
        }
        this.updateFeishuConnection(_target);
      }
    }
  }

  fetchAll(cleanCache?: boolean) {
    return Promise.all([
      this.fetchFeishuConnections(cleanCache),
      this.fetchCalDAVs(cleanCache),
      this.fetchGoogleCalendarConnections(cleanCache),
      this.fetchMicrosoftCalendarConnections(cleanCache)
    ]);
  }

  /**
   * 拉取第三方平台列表
   * @param cleanCache 是否清除缓存
   * @returns
   */
  fetchFeishuConnections(cleanCache?: boolean) {
    if (cleanCache) {
      this.updateFeishuConnections([]);
    }
    return ConnectionApi.getConnections().then((response) => {
      this.updateFeishuConnections(response.data);
    });
  }

  /**
   * 拉取CalDAV列表
   * @param cleanCache
   */
  fetchCalDAVs(cleanCache?: boolean) {
    if (cleanCache) {
      this.updateCalDAVs([]);
    }
    return ThirdPartiesApi.getCalDAV({ page_size: 1000 }).then((response) => {
      this.updateCalDAVs(response.data.results);
    });
  }

  fetchGoogleCalendarConnections(cleanCache?: boolean) {
    if (cleanCache) {
      this.updateGoogleCalendarConnections([]);
    }
    return ThirdPartiesApi.getGoogleConnections({ scene: ThirdPartyIntegrationSceneEnum.CALENDAR }).then((response) => {
      this.updateGoogleCalendarConnections(response.data);
    });
  }

  fetchMicrosoftCalendarConnections(cleanCache?: boolean) {
    if (cleanCache) {
      this.updateMicrosoftCalendarConnections([]);
    }
    return ThirdPartiesApi.getMicrosoftConnections({ scene: ThirdPartyIntegrationSceneEnum.CALENDAR }).then((response) => {
      this.updateMicrosoftCalendarConnections(response.data);
    });
  }

  /**
   * 修改配置
   * @param payload
   * @returns
   */
  patchConnectionConfig(payload: {
    source: string;
    parameters: { requestConfig: ConnectionConfig; newViewConfig?: ConnectionConfig; oldViewConfig?: ConnectionConfig };
  }) {
    // 更新视图，发送请求
    if (payload.parameters.newViewConfig) {
      this.updateLarkConfig(payload.source, payload.parameters.newViewConfig);
    }
    return ConnectionApi.patchConnectionConfig(payload.source, payload.parameters.requestConfig).catch(() => {
      // 更新失败，恢复视图
      payload.parameters.oldViewConfig && this.updateLarkConfig(payload.source, payload.parameters.oldViewConfig);
    });
  }

  /**
   * 绑定
   * @param payload
   * @returns
   */
  connectFeishu(payload: { source: string; parameters: { code: string } }) {
    return ConnectionApi.connect(payload.source, payload.parameters).then((response) => {
      this.updateFeishuConnection(response.data);
    });
  }

  disconnectFeishu(payload: { source: string }) {
    return ConnectionApi.disconnect(payload.source).then((response) => {
      const target = this.feishuConnections.find((connection) => connection.source === payload.source);
      if (target) {
        const _target = cloneDeep(target);
        _target.is_connect = false;
        this.updateFeishuConnection(_target);
      }
    });
  }

  checkIsConnectedAnyCalendars() {
    const isFeishuConnected = this.feishuConnections.some((connection) => connection.is_connect);
    this.isConnectedAnyCalendars =
      isFeishuConnected || this.calDAVs.length > 0 || this.googleCalendarConnections.length > 0 || this.microsoftCalendarConnections.length > 0;
  }
}
export default ConnectionStore;
