import { FC, useMemo, useState } from "react";
import { useIsAutoTranslation } from "@gear/business";
import { observer } from "mobx-react";
import { Space } from "antd";
import { GDivider, HTMLElementProps, translate } from "@gear/ui";
import { LanguageSwitchDrawer } from "./components/LanguageSwitchDrawer";

import "./index.scss";

interface LanguageSwitchEnterProps extends HTMLElementProps<HTMLDivElement> {
  disabled?: boolean;
}

export const LanguageSwitchEnter: FC<LanguageSwitchEnterProps> = observer(({ style, ...props }) => {
  const [languageSwitchDrawer, setLanguageSwitchDrawerVisible] = useState(false);
  const [loadDrawer, setLoadDrawer] = useState(true);
  const { isAutoTranslation } = useIsAutoTranslation();

  return (
    <>
      <span
        style={{
          ...style,
          cursor: props.disabled ? "no-drop" : "inherit"
        }}
        onClick={() => {
          if (!props.disabled) {
            setLanguageSwitchDrawerVisible(true);
            setLoadDrawer(true);
          }
        }}
      >
        <Space className="language-switch-enter-container" split={<GDivider type="vertical" color="#E9E8E8" margin={0} />}>
          <span style={{ cursor: props.disabled ? "no-drop" : "inherit" }}>{translate("HOMEPAGE_LANG")}</span>
          <span data-checked={isAutoTranslation} style={{ cursor: props.disabled ? "no-drop" : "inherit" }}>
            {translate("HOMEPAGE_ICON_TRANSLATE")}
          </span>
        </Space>
      </span>
      {loadDrawer && (
        <LanguageSwitchDrawer
          visible={languageSwitchDrawer}
          onClose={() => {
            setLanguageSwitchDrawerVisible(false);
          }}
          afterVisibleChange={(visible) => {
            setLoadDrawer(visible);
          }}
        />
      )}
    </>
  );
});
