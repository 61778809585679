import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tag, TagLevel, } from "@/apis/types";
import { TeamUser, useGStores } from "@gear/business";

export * from "./roleHooks";

/* for i18n */
export function useTransOptions(options: any[], fields: string[] = ["label"]) {
  const { t, i18n } = useTranslation();
  return useMemo(() => {
    return options.map((opt) => ({
      ...opt,
      ...fields.reduce((o: any, f) => {
        o[f] = t(opt[f]);
        return o;
      }, {})
    }));
  }, [t, i18n.language, options]);
}

export function useTransOptionsCallback() {
  const { t, i18n } = useTranslation();
  return useCallback(
    (options: any[], fields: string[] = ["label"]) => {
      return options.map((opt) => ({
        ...opt,
        ...fields.reduce((o: any, f) => {
          o[f] = t(opt[f]);
          return o;
        }, {})
      }));
    },
    [t, i18n.language]
  );
}

export function useTransMap(map: any) {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    return Object.freeze(
      Object.keys(map).reduce((o: any, k) => {
        o[k] = t(map[k]);
        return o;
      }, {})
    );
  }, [map, t, i18n.language]);
}

export function useTransMapCallback() {
  const { t, i18n } = useTranslation();

  return useCallback(
    (map: any) => {
      return Object.freeze(
        Object.keys(map).reduce((o: any, k) => {
          o[k] = t(map[k]);
          return o;
        }, {})
      );
    },
    [t, i18n.language]
  );
}

export function usePageTitle(title = "") {
  const { t } = useTranslation();
  let suffix = "Metawork";
  document.title = document.title.endsWith(suffix) ? title : `${title} - ${suffix}`;
}

// 监听全局添加 task，添加后更新列表
export function useUpdateTaskDataListener() {
  const { configStore } = useGStores();

  useEffect(() => {
    if (configStore.needUpdateTaskDataFlag) {
      configStore.updateNeedUpdateTaskDataFlag(false);
    }
  }, [configStore?.needUpdateTaskDataFlag]);
  return {
    needUpdateFlag: configStore.needUpdateTaskDataFlag
  };
}
