import React from "react";
import { GDrawerProps } from "@gear/ui";
import { useTranslation } from "react-i18next";
import ManualSetting from "@/templates/HomepageTemplate/components/TimeZoneDialog/ManualSetting";
import { observer } from "mobx-react";
import ConfigStore from "@/stores/ConfigStore";
import { GCity } from "@gear/business";
import "./index.scss";

interface TimeZoneDialogProps extends GDrawerProps {
  onCancel?: () => void;
  configStore?: ConfigStore;
  currentCity?: GCity | undefined;
  setCurrentCity?: (val?: GCity) => void;
}

// const tips = "Click the map near your location and choose a city from the Closest City menu. You can also have the time zone change automatically, if possible, based on your system timezone, closest city is based on your ip address";
const tips = "Select your city and time zone to ensure that your daily work statistics are accurate.";

export const TimeZoneDialog: React.FC<TimeZoneDialogProps> = ({ setCurrentCity, currentCity }) => {
  const { t } = useTranslation();

  const manualSettingProps = {
    setCurrentCity,
    currentCity
  };

  return (
    <div className="timezone-dialog-container">
      {
        <div className={`create-project-modal-center`}>
          <div className="create-center-box">
            <div className="left">
              <div className={"tips"}>{t(tips)}</div>
              <ManualSetting {...manualSettingProps} />
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default observer(TimeZoneDialog);
