import { UseStores } from "@/stores";
import { useGStores } from "@gear/business";
import { useEffect } from "react";
import { useAfterOnBoard } from "./useAfterOnboard";
import moment from "moment-timezone";

/**
 * app下载引导hook
 */
export function useAppDownloadGuide() {
  const LOG_TAG = "useAppDownloadGuide";
  const { userStore, configStore, appDownloadStore } = useGStores<UseStores>();
  const { startDetectTargetNode, stopDetectTargetNode } = useAfterOnBoard();

  const { appDownloadGuideModalVisible } = appDownloadStore;

  useEffect(() => {
    console.log(
      LOG_TAG,
      "appDownloaded=",
      configStore.appDownloaded,
      "first_onboard_at=",
      userStore?.user?.first_onboard_at,
      "userFinishAppDownloadGuideCount=",
      appDownloadStore.userFinishAppDownloadGuideCount,
      "userFinishAppDownloadGuideDate=",
      appDownloadStore.userFinishAppDownloadGuideDate
    );
    if (userStore.user?.id && !configStore.appDownloaded) {
      if (appDownloadStore.userFinishAppDownloadGuideCount === 0) {
        // 还没进行过app下载引导
        if (!userStore?.user?.first_onboard_at) {
          // 还没进行onboarding
          startDetectTargetNode();
        } else {
          // 已经onboarding，但是可能中途退出了，需要弹出下载移到
          appDownloadStore.updateAppDownloadGuideModalVisible(true);
        }
      } else if (appDownloadStore.userFinishAppDownloadGuideCount === 1) {
        const diff = moment().diff(appDownloadStore.userFinishAppDownloadGuideDate || moment(), "weeks");
        if (diff >= 1) {
          // 1周前结束的下载引导，需要再次弹出下载引导
          appDownloadStore.updateAppDownloadGuideModalVisible(true);
        }
      }
    } else {
      stopDetectTargetNode();
    }
  }, [userStore.user?.id, configStore.appDownloaded]);

  const cancelGuide = () => {
    appDownloadStore.updateUserFinishAppDownloadGuideCount(appDownloadStore.userFinishAppDownloadGuideCount + 1);
    appDownloadStore.updateUserFinishAppDownloadGuideCountDate(moment().format("YYYY-MM-DD"));
    appDownloadStore.updateAppDownloadGuideModalVisible(false);
  };

  const downloaded = () => {
    appDownloadStore.updateAppDownloadGuideModalVisible(false);
  };

  return {
    appDownloadGuideModalVisible,
    cancelGuide,
    downloaded
  };
}
